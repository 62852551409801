import React from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Row>
        <div className="spacing">
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>Privacy Policy</h2>
          <span className="important">Last Updated: February 21, 2024</span>
          <br />
          <br />
          <p>
            Welcome to Podium, provided by Delta Zero, LLC {`(`}"we", "our", or
            "us"{`)`}. Your privacy is paramount to us. This Privacy Policy
            outlines how we collect, use, protect, and share information about
            you through PodiumTheory.com and our Podium desktop application.
          </p>
          <h6>1. Information Collection</h6>
          <ul>
            <li>
              <b>Personal Data:</b> Such as your name, email address, and game
              telemetry data when you use our app.
            </li>
            <li>
              <b>Automatic Data Collection:</b> Device information, including IP
              address, device ID, and usage statistics.
            </li>
            <li>
              <b>Payment Information:</b> Handled by Stripe, Inc. for premium
              services without storing details on our end.
            </li>
          </ul>
          <h6>2. Use of Information</h6>
          <ul>
            <li>
              For service provision, communication (if opted in), and service
              improvement.
            </li>
          </ul>
          <h6>3. Data Processing</h6>
          <ul>
            <li>
              Secure storage of profile information and use of other data for
              functional purposes.
            </li>
          </ul>
          <h6>4. Sharing of Information</h6>
          <ul>
            <li>No sharing of personal data with third parties.</li>
          </ul>
          <h6>5. User Consent and Opt-Out</h6>
          <ul>
            <li>
              Use of our services signifies consent; opt-out available via{" "}
              <a
                className={gStyle.cyan}
                style={{ textDecoration: "none" }}
                href="mailto:support@podiumtheory.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@podiumtheory.com
              </a>
              .
            </li>
          </ul>
          <h6>6. Data Security</h6>
          <ul>
            <li>
              Implementation of robust security measures for data protection.
            </li>
          </ul>
          <h6>7. User Rights</h6>
          <ul>
            <li>
              Access, correction, and deletion rights regarding personal data.
            </li>
          </ul>
          <h6>8. International Data Transfer</h6>
          <ul>
            <li>
              Data may be transferred in compliance with global regulations.
            </li>
          </ul>
          <h6>9. Data Breach Response</h6>
          <ul>
            <li>Prompt notification and action in case of a data breach.</li>
          </ul>
          <h6>10. Policy Updates</h6>
          <ul>
            <li>
              Rights reserved to modify the privacy policy, with notices posted
              online.
            </li>
          </ul>
          <h6>11. Contact Information</h6>
          <ul>
            <li>
              Reach out to{" "}
              <a
                className={gStyle.cyan}
                style={{ textDecoration: "none" }}
                href="mailto:support@podiumtheory.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@podiumtheory.com
              </a>{" "}
              for any privacy-related queries.
            </li>
          </ul>
          <br />
          <p>
            By using Podium, you acknowledge and agree to the terms outlined in
            this Privacy Policy and the accompanying{" "}
            <NavLink to="/about/terms-of-service">Terms of Service</NavLink>.
          </p>
        </div>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
