import React, { useEffect, useState } from "react";
import MainScreen from "../../Components/MainScreen";
import { Button, Col, Form, Row } from "react-bootstrap";
import ErrorMessage from "../../Components/ErrorMessage";
import Loading from "../../Components/Loading";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";

import "./RegisterScreen.scss";
import gStyle from "../../SCSS/global.module.scss";

function RegisterScreen({ history }) {
  const [email, setEmail] = useState("");
  const [username, setName] = useState("");
  const defaultPic =
    "https://res.cloudinary.com/dbzyqh4jg/image/upload/v1672368328/DefaultProfile256x256_ttsu43.png";
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [picMessage, setPicMessage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const postDetails = (pics) => {
    if (pics) {
      const file = pics;
      setPicMessage(null);

      // Create an Image object and set its src to the file
      const img = new Image();
      img.src = URL.createObjectURL(file);

      // When the image has finished loading, check its size
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        if (file.type === "image/jpeg" || file.type === "image/png") {
          if (width !== 256 || height !== 256) {
            return setPicMessage(
              "Please choose a Profile picture with a size of 256 x 256 pixels"
            );
          } else {
            setSelectedFile(file);
          }
        } else {
          return setPicMessage("Please select a valid PNG or JPEG image file");
        }
      };
    }
  };

  const uploadPic = async () => {
    const data = new FormData();
    if (selectedFile) {
      data.append("file", selectedFile);
      data.append("upload_preset", "dzp-leagues");
      data.append("cloud_name", "dbzyqh4jg");
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dbzyqh4jg/image/upload",
        {
          method: "post",
          body: data,
        }
      );
      const dataRes = await res.json();
      return dataRes.url.toString();
    }
    return defaultPic; // return the default pic if no file selected
  };

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      setMessage(null); // Clear the message if passwords match
      const finalPic = await uploadPic();
      dispatch(register(username, email, password, finalPic));
    }
  };

  return (
    <MainScreen title="Register">
      <div className="registerContainer">
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {message && <ErrorMessage variant="danger">{message}</ErrorMessage>}
        {loading && <Loading />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="username" className="py-2">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="name"
              value={username}
              placeholder="Enter Username"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail" className="py-2">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              value={email}
              placeholder="Enter Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword" className="py-2">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="confirmPassword" className="py-2">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={confirmPassword}
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Group>

          {picMessage && (
            <ErrorMessage variant="danger">{picMessage}</ErrorMessage>
          )}
          <Form.Group controlId="pic" className="py-2">
            <Form.Label>
              Profile Picture {"("}not required, only accepts 256x256 px{")"}
            </Form.Label>
            <Form.Control
              onChange={(e) => postDetails(e.target.files[0])}
              type="file"
              label="Upload Profile Picture 256x256"
              accept="image/*"
            />
          </Form.Group>
          <Button variant="success" type="submit" size="md" className="my-2">
            Register
          </Button>
          <Row className="py-3">
            <Col>
              <p className={`${gStyle.wh}`}>
                Already have an Account?{" "}
                <Link to="/login" className={`register ${gStyle.cyan}`}>
                  Login Here
                </Link>
              </p>
            </Col>
          </Row>
        </Form>
      </div>
    </MainScreen>
  );
}

export default RegisterScreen;
