import React from "react";
import { Container, Row } from "react-bootstrap";
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";

const PremiumSubs = () => {
  return (
    <Container>
      <Row>
        <div className="spacing">
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>
            Premium Subscriptions
          </h2>
          <span className="important">Knowledge Base Coming Soon!</span>
        </div>
      </Row>
    </Container>
  );
};

export default PremiumSubs;
