import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  generateTwoFAQrCode,
  checkTwoFAStatus,
  confirmTwoFA,
  enableTwoFA,
  disableTwoFA,
} from "../../../actions/userActions";
import { applyForBeta, getUserBetaStatus } from "../../../actions/betaActions";
import { createSubscription } from "../../../actions/subscriptionActions";
import { loadStripe } from "@stripe/stripe-js";
import useStripeCustomerPortal from "../../../hooks/useStripeCustomerPortal";
import Loading from "../../../Components/Loading";
import DownloadButton from "../../../Components/DownloadButton";
import ErrorMessage from "../../../Components/ErrorMessage";
import MainScreen from "../../../Components/MainScreen";
import "./ProfileScreen.scss";
import gStyle from "../../../SCSS/global.module.scss";
import ProfileNav from "../../../Components/ProfileNav";
import {
  FaCircleCheck,
  FaLock,
  FaCircleExclamation,
  FaUser,
} from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ProfileScreen = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [twoFAButton, setTwoFAButton] = useState({
    text: "Generate 2FA QR Code", // Initial button text before status is checked
    variant: "danger", // Initial button variant before status is checked
  });
  const [twoFASwitch, setTwoFASwitch] = useState({
    text: "Scan QR Code for optional 2FA", // Initial button text before status is checked
    disabled: true, // Initial button variant before status is checked
    checked: false,
  });
  const [token, setToken] = useState(""); // State for the token input field
  const [verificationResult, setVerificationResult] = useState(null); // State to store the verification result
  const [codeError, setCodeError] = useState(false);

  // Download Button
  const showDownloadButton = userInfo?.isAdmin || userInfo?.access !== "none";

  // Stripe create subscription
  const subscriptionCreate = useSelector((state) => state.subscriptionCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success,
    sessionId,
  } = subscriptionCreate;

  const {
    redirectToCustomerPortal,
    loading: loadingPortal,
    error: errorPortal,
  } = useStripeCustomerPortal();

  // Select the part of state updated by the confirmTwoFA action
  const userTwoFAConfirm = useSelector((state) => state.userTwoFAConfirm);
  const {
    loading: twoFAConfirmLoading,
    error: twoFAConfirmError,
    confirmToken,
  } = userTwoFAConfirm || {};

  const twoFAStatus = useSelector((state) => state.twoFAStatus);
  const {
    loading: twoFAStatusLoading,
    error: twoFAStatusError,
    statusInfo,
  } = twoFAStatus;

  const userTwoFAQrCode = useSelector((state) => state.userTwoFAQrCode);
  const { loading, error, qrCodeImageUrl } = userTwoFAQrCode;

  // Combined loading state
  const isLoading = loading || twoFAStatusLoading || twoFAConfirmLoading;

  const betaStatus = useSelector((state) => state.betaStatus);
  const { status: betaInfo } = betaStatus;

  // Handling beta apply button click
  const handleApplyForBeta = async () => {
    try {
      await dispatch(applyForBeta());
      dispatch(getUserBetaStatus()); // Then check status
    } catch (error) {
      console.error("Error applying for beta:", error);
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(checkTwoFAStatus());
      dispatch(getUserBetaStatus());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    // Only proceed if the status is loaded and there's no error
    if (!twoFAStatusLoading && !twoFAStatusError) {
      if (statusInfo?.hasTwoFASecret) {
        // Optional chaining is used here
        setTwoFAButton({ text: "Edit 2FA Settings", variant: "success" });
        if (statusInfo?.twoFAEnabled) {
          setTwoFASwitch({
            text: "2FA Enabled",
            disabled: false,
            checked: true,
          });
        } else {
          setTwoFASwitch({
            text: "2FA Disabled",
            disabled: false,
            checked: false,
          });
        }
      } else {
        setTwoFAButton({ text: "Generate 2FA QR Code", variant: "danger" });
        setTwoFASwitch({
          text: "Scan QR code for optional 2FA",
          disabled: true,
          checked: false,
        });
      }
    }
  }, [twoFAStatusLoading, twoFAStatusError, statusInfo]);

  useEffect(() => {
    if (qrCodeImageUrl) {
      setQrCodeUrl(qrCodeImageUrl);
    }
  }, [qrCodeImageUrl]);

  useEffect(() => {
    if (confirmToken) {
      // The state has now been updated with the response from your confirmTwoFA action
      if (confirmToken.tokenIsValid) {
        setVerificationResult("2FA token is valid!");
      } else {
        setVerificationResult("2FA token is invalid. Try again.");
      }
    }

    if (twoFAConfirmError) {
      setVerificationResult("2FA token is invalid. Try again.");
    }
  }, [confirmToken, twoFAConfirmError]);

  useEffect(() => {
    // Cleanup function when the component unmounts or before a new verify request
    return () => {
      setVerificationResult(null);
    };
  }, []);

  const handleShowQRCode = () => {
    if (!qrCodeUrl) {
      // if the QR code URL has not been fetched yet
      dispatch(generateTwoFAQrCode());
    }
    setShowModal(true); // show modal in all cases
  };

  // handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setToken("");
    setVerificationResult(null);
    setTwoFAButton({ text: "Edit 2FA Settings", variant: "success" });
  };

  const handleVerifyToken = (e) => {
    e.preventDefault();

    if (!token || token.length !== 6 || isNaN(token)) {
      setCodeError(true);
      setVerificationResult("Not a valid 6-digit 2FA code");
      return;
    }

    setCodeError(false);
    dispatch(confirmTwoFA(token, userInfo?._id));
  };

  const handleTwoFAToggle = (event) => {
    // event.target.checked will be true or false, indicating the new state of the switch
    if (event.target.checked) {
      dispatch(enableTwoFA());
      setTwoFASwitch({
        text: "2FA Enabled",
        disabled: false,
        checked: true,
      });
    } else {
      dispatch(disableTwoFA());
      setTwoFASwitch({
        text: "2FA Disabled",
        disabled: false,
        checked: false,
      });
    }
  };

  // Stripe checkout
  useEffect(() => {
    if (success && sessionId) {
      const redirectToCheckout = async () => {
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId });
      };
      redirectToCheckout();
    }
  }, [success, sessionId]);

  const handleSubscription = () => {
    dispatch(createSubscription());
  };

  return (
    <MainScreen title="User Profile">
      <Container>
        {isLoading && <Loading />}
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {errorPortal && (
          <ErrorMessage variant="danger">{errorPortal}</ErrorMessage>
        )}
        {twoFAStatusError && (
          <ErrorMessage variant="danger">{twoFAStatusError}</ErrorMessage>
        )}
        <Row className="justify-content-center">
          <Col
            style={{
              top: "0",
              left: "0",
            }}
          >
            <ProfileNav />
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col
            className="mt-1 mb-3"
            style={{
              minWidth: "375px",
              top: "0",
              left: "0",
            }}
          >
            <Card>
              <Card.Body>
                <p>
                  <FaUser /> |{" "}
                  <span className={`detail_label ${gStyle.smdText}`}>
                    {userInfo?.username}
                  </span>
                </p>
                <p>
                  <MdEmail /> |{" "}
                  <span className={`detail_label ${gStyle.smdText}`}>
                    {userInfo?.email}
                  </span>
                </p>
                <p>
                  <FaLock /> |{"   "}
                  <span className={`detail_label ${gStyle.smdText}`}>
                    {twoFASwitch.text}
                  </span>
                </p>
                <Button
                  className={`twoFA_qr ${gStyle.magentaBG}`}
                  variant={twoFAButton.variant}
                  style={{ width: "250px" }}
                  onClick={handleShowQRCode}
                  disabled={twoFAStatusLoading}
                >
                  {twoFAButton.text}
                </Button>
              </Card.Body>
            </Card>

            <Modal
              dialogClassName="qr-modal"
              show={showModal}
              onHide={handleCloseModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Scan 2FA QR Code</Modal.Title>
              </Modal.Header>
              <Modal.Body className="d-flex justify-content-center align-items-center">
                {error ? (
                  <ErrorMessage variant="danger">{error}</ErrorMessage>
                ) : (
                  <div style={{ width: "100%" }}>
                    <img
                      src={qrCodeUrl}
                      alt="2FA QR Code"
                      style={{
                        marginBottom: "20px",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <div>
                    Scan the QR code with your favorite Authenticator app. You
                    must enter the code it provides to verify setup before
                    enabling or disabling 2FA:
                    <Form onSubmit={handleVerifyToken}>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          value={token}
                          onChange={(e) => setToken(e.target.value)}
                          placeholder="Enter 2FA Code"
                          style={{ width: "250px", marginTop: "10px" }}
                        />
                      </Form.Group>
                      <Button
                        type="submit"
                        disabled={twoFAConfirmLoading}
                        variant="info"
                        style={{ width: "250px", marginTop: "10px" }}
                      >
                        Verify 2FA Token
                      </Button>
                    </Form>
                    {verificationResult && (
                      <div style={{ marginTop: "10px" }}>
                        {!codeError &&
                        confirmToken &&
                        confirmToken.tokenIsValid ? (
                          <div style={{ color: "green", fontWeight: "bold" }}>
                            <FaCircleCheck /> {verificationResult}
                          </div>
                        ) : (
                          <div style={{ color: "red", fontWeight: "bold" }}>
                            <FaCircleExclamation /> {verificationResult}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {!codeError && confirmToken && confirmToken.tokenIsValid && (
                    <div style={{ marginTop: "10px" }}>
                      <FormControlLabel
                        style={{
                          marginLeft: "0px",
                        }}
                        className={`${gStyle.smdText}`}
                        control={
                          <Switch
                            checked={twoFASwitch.checked}
                            onChange={handleTwoFAToggle}
                            size="small"
                          />
                        }
                        label={
                          <span style={{ marginLeft: "5px", color: "white" }}>
                            {twoFASwitch.text}
                          </span>
                        }
                      />
                    </div>
                  )}
                </div>
              </Modal.Footer>
            </Modal>
            {/* Access Level and BETA application */}
            <Card className="my-3">
              <Card.Header
                className={`${gStyle.cyan} ${gStyle.cyanTs} ${gStyle.smdText}`}
              >
                Access Level
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  {userInfo?.isAdmin ? (
                    <>
                      <span
                        className="badge rounded-pill bg-secondary"
                        style={{ fontSize: "14px" }}
                      >
                        ADMIN
                      </span>
                      <div className="mt-3">
                        <LinkContainer
                          style={{ width: "100%" }}
                          to="/admin/info"
                        >
                          <Button style={{ width: "100%" }}>
                            Visit Admin Panel
                          </Button>
                        </LinkContainer>
                      </div>
                    </>
                  ) : userInfo?.access !== "none" ? (
                    <span
                      className="badge rounded-pill bg-info"
                      style={{ fontSize: "14px", textTransform: "uppercase" }}
                    >
                      {userInfo?.access}
                    </span>
                  ) : betaInfo?.status === "denied" ? (
                    <span
                      className="badge rounded-pill bg-danger"
                      style={{ fontSize: "14px" }}
                    >
                      BETA access denied. Please check back later.
                    </span>
                  ) : betaInfo?.status === "pending" ? (
                    <span
                      className="badge rounded-pill bg-warning"
                      style={{ fontSize: "14px" }}
                    >
                      BETA application pending
                    </span>
                  ) : (
                    <div className="mt-3">
                      <Button
                        onClick={handleApplyForBeta}
                        disabled={betaInfo?.status === "pending"}
                        style={{ width: "100%" }}
                        variant="success"
                      >
                        Apply to BETA
                      </Button>
                    </div>
                  )}
                  {showDownloadButton ? (
                    <div className="mt-3">
                      <DownloadButton fileId="podiumInstaller" />
                    </div>
                  ) : (
                    <div className="mt-3">
                      Please check back later. The Download will be made
                      available to you here after you have been granted access.
                    </div>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
            {/* Premium Subscription */}
            <Card>
              <Card.Header
                className={`${gStyle.magenta} ${gStyle.magentaTs} ${gStyle.smdText}`}
              >
                {userInfo?.access === "premium"
                  ? "Premium Status"
                  : "Podium Premium"}
              </Card.Header>
              <Card.Body>
                {userInfo?.access !== "premium" ? (
                  <>
                    <Card.Text>
                      Immediately unlock full access to Podium with our Premium
                      Subscription. Enjoy exclusive modules, advanced features,
                      and priority support for only $5/month.
                    </Card.Text>
                    <Button
                      style={{ width: "100%" }}
                      variant="info"
                      onClick={handleSubscription}
                    >
                      Subscribe to Premium
                    </Button>
                  </>
                ) : userInfo?.access === "premium" &&
                  !userInfo?.subscriptionActive ? (
                  <>
                    <Card.Text>
                      Thank you for being a Premium supporter. Your subscription
                      is set to expire on the following date:
                    </Card.Text>
                    <span
                      className="badge rounded-pill bg-danger"
                      style={{ fontSize: "14px" }}
                    >
                      {new Date(
                        userInfo?.subscriptionExpires
                      ).toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </span>
                  </>
                ) : (
                  <>
                    <Card.Text>
                      Thank you for being a Premium supporter. Your subscription
                      is set to automatically renew on the following date:
                    </Card.Text>
                    <span
                      className="badge rounded-pill bg-success"
                      style={{ fontSize: "14px" }}
                    >
                      {new Date(
                        userInfo?.subscriptionExpires
                      ).toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </span>
                  </>
                )}
                {userInfo?.stripeCustomerId && (
                  <div className="mt-3">
                    <Button
                      style={{ width: "100%" }}
                      variant="primary"
                      onClick={redirectToCustomerPortal}
                      disabled={loadingPortal}
                    >
                      {loadingPortal ? "Loading..." : "Manage Subscription"}
                    </Button>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col className="right justify-content-center my-2">
            <div
              style={{
                minWidth: "300px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={userInfo?.pic}
                alt={userInfo?.username}
                style={{ borderRadius: "15px", maxWidth: "256px" }}
              />
              <LinkContainer
                to="/profile/edit"
                style={{ width: "100%", maxWidth: "256px", marginTop: "10px" }}
              >
                <Button style={{ width: "100%" }} variant="secondary">
                  Edit User Profile
                </Button>
              </LinkContainer>
            </div>
          </Col>
        </Row>
      </Container>
    </MainScreen>
  );
};

export default ProfileScreen;
