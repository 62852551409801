import {
  ERROR_LOG_CREATE_REQUEST,
  ERROR_LOG_CREATE_SUCCESS,
  ERROR_LOG_CREATE_FAIL,
  ERROR_LOG_DETAILS_REQUEST,
  ERROR_LOG_DETAILS_SUCCESS,
  ERROR_LOG_DETAILS_FAIL,
  ERROR_LOG_UPDATE_REQUEST,
  ERROR_LOG_UPDATE_SUCCESS,
  ERROR_LOG_UPDATE_FAIL,
  ERROR_LOG_DELETE_REQUEST,
  ERROR_LOG_DELETE_SUCCESS,
  ERROR_LOG_DELETE_FAIL,
  ERROR_LOG_LIST_REQUEST,
  ERROR_LOG_LIST_SUCCESS,
  ERROR_LOG_LIST_FAIL,
  ERROR_LOG_LIST_USER_REQUEST,
  ERROR_LOG_LIST_USER_SUCCESS,
  ERROR_LOG_LIST_USER_FAIL,
} from "../constants/errorLogConstants";

export const errorLogCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ERROR_LOG_CREATE_REQUEST:
      return { loading: true };
    case ERROR_LOG_CREATE_SUCCESS:
      return { loading: false, success: true, errorLog: action.payload };
    case ERROR_LOG_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const errorLogDetailsReducer = (state = { errorLog: {} }, action) => {
  switch (action.type) {
    case ERROR_LOG_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ERROR_LOG_DETAILS_SUCCESS:
      return { loading: false, errorLog: action.payload };
    case ERROR_LOG_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const errorLogUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ERROR_LOG_UPDATE_REQUEST:
      return { loading: true };
    case ERROR_LOG_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ERROR_LOG_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const errorLogDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ERROR_LOG_DELETE_REQUEST:
      return { loading: true };
    case ERROR_LOG_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ERROR_LOG_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const errorLogListReducer = (state = { errorLogs: [] }, action) => {
  switch (action.type) {
    case ERROR_LOG_LIST_REQUEST:
      return { loading: true };
    case ERROR_LOG_LIST_SUCCESS:
      return { loading: false, errorLogs: action.payload };
    case ERROR_LOG_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const errorLogListUserReducer = (state = { errorLogs: [] }, action) => {
  switch (action.type) {
    case ERROR_LOG_LIST_USER_REQUEST:
      return { loading: true };
    case ERROR_LOG_LIST_USER_SUCCESS:
      return { loading: false, errorLogs: action.payload };
    case ERROR_LOG_LIST_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
