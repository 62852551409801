import {
  BETA_APPLY_REQUEST,
  BETA_APPLY_SUCCESS,
  BETA_APPLY_FAIL,
  BETA_STATUS_REQUEST,
  BETA_STATUS_SUCCESS,
  BETA_STATUS_FAIL,
  BETA_REVIEW_REQUEST,
  BETA_REVIEW_SUCCESS,
  BETA_REVIEW_FAIL,
  BETA_LIST_REQUEST,
  BETA_LIST_SUCCESS,
  BETA_LIST_FAIL,
  BETA_DELETE_REQUEST,
  BETA_DELETE_SUCCESS,
  BETA_DELETE_FAIL,
} from "../constants/betaConstants";
import axios from "axios";
import { API_BASE_URL } from "../config";

export const applyForBeta = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BETA_APPLY_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Replace 'your-api-endpoint/beta/apply' with your actual endpoint
    const { data } = await axios.post(`${API_BASE_URL}/beta/apply`, {}, config);

    dispatch({
      type: BETA_APPLY_SUCCESS,
      payload: data,
    });

    // This return is important for the promise chaining in your component
    return data;
  } catch (error) {
    dispatch({
      type: BETA_APPLY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    // Throw the error to catch it in the component
    throw error;
  }
};

export const getUserBetaStatus = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BETA_STATUS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${API_BASE_URL}/beta/status`, config);

    dispatch({
      type: BETA_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BETA_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const reviewBetaApplication =
  (applicationId, status) => async (dispatch, getState) => {
    try {
      dispatch({ type: BETA_REVIEW_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${API_BASE_URL}/beta/review`,
        { applicationId, status },
        config
      );

      dispatch({
        type: BETA_REVIEW_SUCCESS,
        payload: data,
      });

      return data; // Returning data for potential chaining in components
    } catch (error) {
      dispatch({
        type: BETA_REVIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      throw error; // Throwing error for catching it in the component if needed
    }
  };

export const listAllBetaApplications = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BETA_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${API_BASE_URL}/beta/list`, config);

    dispatch({
      type: BETA_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BETA_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteBetaApplication =
  (applicationId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BETA_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(`${API_BASE_URL}/beta/${applicationId}`, config);

      dispatch({
        type: BETA_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: BETA_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
