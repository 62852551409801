let API_BASE_URL;

if (process.env.NODE_ENV === "development") {
  API_BASE_URL = "http://localhost:5000/api";
} else {
  API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "https://dzp-api.onrender.com/api";
}

export { API_BASE_URL };
