import React, { useEffect } from "react";
import MainScreen from "../MainScreen";
import { Button, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { refreshUserInfo } from "../../actions/userActions";
import { LinkContainer } from "react-router-bootstrap";

// navigates here after completing a subscription transaction
const SubscriptionSuccess = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      dispatch(refreshUserInfo());
    }
  }, [dispatch, userInfo]);

  return (
    <MainScreen title="Subscription Successful!">
      <Container>
        <p>
          {userInfo?.username}, thank you for subscribing. Your subscription has
          been successfully processed.
        </p>
        <LinkContainer to={"/profile"}>
          <Button variant="info">Return to Profile</Button>
        </LinkContainer>
      </Container>
    </MainScreen>
  );
};

// navigates here after a transaction has been abondoned
const SubscriptionCancel = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      dispatch(refreshUserInfo());
    }
  }, [dispatch, userInfo]);

  return (
    <MainScreen title="Subscription Canceled">
      <Container>
        <p>
          {userInfo?.username}, your transaction has been canceled. You were not
          charged.
        </p>
        <LinkContainer to={"/profile"}>
          <Button variant="info">Return to Profile</Button>
        </LinkContainer>
      </Container>
    </MainScreen>
  );
};

const SubscriptionPortalReturn = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      dispatch(refreshUserInfo());
    }
  }, [dispatch, userInfo]);

  return (
    <MainScreen title="Subscription Updates">
      <Container>
        <p>
          {userInfo?.username}, any requested subscription updates have been
          successfully processed and will be reflected on your profile.
        </p>
        <LinkContainer to={"/profile"}>
          <Button variant="info">Return to Profile</Button>
        </LinkContainer>
      </Container>
    </MainScreen>
  );
};

export { SubscriptionSuccess, SubscriptionCancel, SubscriptionPortalReturn };
