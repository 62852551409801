import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Profile from "../Images/account_logos/account-white.svg";
import Driver from "../Images/account_logos/racing_helmet.png";
import Friendship from "../Images/account_logos/friends-white.svg";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import "./ProfileNav.scss";

const ProfileNav = () => {
  const [activeLogo, setActiveLogo] = useState(null);

  const handleMouseEnter = (logo) => {
    setActiveLogo(logo);
  };

  const handleMouseLeave = () => {
    setActiveLogo(null);
  };

  return (
    <Col
      className="my-2 d-flex justify-content-center gradient-background"
      style={{
        borderRadius: "10px",
        padding: "10px",
      }}
    >
      <Tooltip title="User Profile" position="top" arrow={false} distance={12}>
        <LinkContainer
          to="/profile"
          className={`logo-link mx-4 ${
            activeLogo === "profile" ? "active" : ""
          }`}
          onMouseEnter={() => handleMouseEnter("profile")}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={Profile}
            alt="Profile"
            height="40"
            className={`logo-img ${activeLogo === "profile" ? "active" : ""}`}
          />
        </LinkContainer>
      </Tooltip>

      <Tooltip
        title="Driver Profile"
        position="top"
        arrow={false}
        distance={12}
      >
        <LinkContainer
          to="/profile/driver"
          className={`logo-link mx-4 ${
            activeLogo === "driver" ? "active" : ""
          }`}
          onMouseEnter={() => handleMouseEnter("driver")}
          onMouseLeave={handleMouseLeave}
        >
          <img
            style={{
              transition: "all 0.2s linear",
              transform:
                activeLogo === "driver"
                  ? "translateY(-2px)"
                  : "translateY(0px)",
            }}
            src={Driver}
            alt="Driver"
            height="40"
            className={`logo-img ${activeLogo === "driver" ? "active" : ""}`}
          />
        </LinkContainer>
      </Tooltip>

      <Tooltip title="Friends List" position="top" arrow={false} distance={12}>
        <LinkContainer
          to="/profile/friends"
          className={`logo-link mx-4 ${
            activeLogo === "friendship" ? "active" : ""
          }`}
          onMouseEnter={() => handleMouseEnter("friendship")}
          onMouseLeave={handleMouseLeave}
        >
          <img
            style={{
              transition: "all 0.2s linear",
              transform:
                activeLogo === "friendship"
                  ? "translateY(-2px)"
                  : "translateY(0px)",
            }}
            src={Friendship}
            alt="Friendship"
            height="40"
            className={`logo-img ${
              activeLogo === "friendship" ? "active" : ""
            }`}
          />
        </LinkContainer>
      </Tooltip>
    </Col>
  );
};

export default ProfileNav;
