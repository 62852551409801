import React from "react";
import { Container, Row } from "react-bootstrap";

import "./MainScreen.scss";
import gStyle from "../SCSS/global.module.scss";

const MainScreen = ({ title, children, className }) => {
  return (
    <div className={`mainback ${className}`}>
      <Container>
        <Row>
          <div className="page">
            {title && (
              <>
                <h1 className={`${gStyle.wh} ${gStyle.whTs}`}>{title}</h1>
                <hr />
              </>
            )}
            {children}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default MainScreen;
