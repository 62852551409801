import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_BASE_URL } from "../config";

const useStripeCustomerPortal = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { userInfo } = useSelector((state) => state.userLogin);

  const redirectToCustomerPortal = useCallback(async () => {
    if (!userInfo?.token) return;

    setLoading(true);
    setError(null);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${API_BASE_URL}/stripe/customer-portal`,
        config
      );

      // Redirect the user to the Stripe Customer Portal
      window.location.href = data.url;
    } catch (error) {
      console.error("Failed to redirect to Stripe Customer Portal", error);
      setError(error.response?.data?.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  }, [userInfo?.token]);

  return { redirectToCustomerPortal, loading, error };
};

export default useStripeCustomerPortal;
