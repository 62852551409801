import {
  SUBSCRIPTION_CREATE_REQUEST,
  SUBSCRIPTION_CREATE_SUCCESS,
  SUBSCRIPTION_CREATE_FAIL,
  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_STATUS_SUCCESS,
  SUBSCRIPTION_STATUS_FAIL,
} from "../constants/subscriptionConstants";

export const subscriptionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_CREATE_REQUEST:
      return { loading: true };
    case SUBSCRIPTION_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        sessionId: action.payload.sessionId,
      };
    case SUBSCRIPTION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subscriptionStatusReducer = (
  state = { subscription: {} },
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_STATUS_REQUEST:
      return { ...state, loading: true };
    case SUBSCRIPTION_STATUS_SUCCESS:
      return { loading: false, subscription: action.payload };
    case SUBSCRIPTION_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
