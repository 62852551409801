import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createErrorLog } from "../../actions/errorLogActions";
import { Form, Button, Alert, Container } from "react-bootstrap";
import gStyle from "../../SCSS/global.module.scss";

const FeedbackForm = () => {
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState({
    module: "",
    category: "",
    description: "",
  });
  const [formError, setFormError] = useState({
    active: false,
    message: "",
  });
  const [formSuccess, setFormSuccess] = useState(false);

  const handleFeedbackChange = (key, value) => {
    setFeedback({ ...feedback, [key]: value });
  };

  const clearForm = () => {
    setFeedback({
      module: "",
      category: "",
      description: "",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!feedback.module || !feedback.category || !feedback.description) {
        setFormError({ active: true, message: "Please fill out all fields." });
        setFormSuccess(false);
        return;
      }

      let appVersion;
      appVersion = "web-form";

      const errorLog = {
        module: feedback.module,
        type: "user feedback",
        error_log: feedback.description,
        app_version: appVersion,
        relevant_error_details: feedback.category,
        device_info: window.navigator.userAgent,
      };

      await dispatch(createErrorLog(errorLog));
      setFormError({ active: false, message: "" });
      setFormSuccess(true);
      clearForm();
    } catch (error) {
      console.error("Error submitting feedback: ", error);
      setFormError({
        active: true,
        message: "Error submitting feedback. Please try again.",
      });
    }
  };

  return (
    <Container>
      {formError.active && <Alert variant="danger">{formError.message}</Alert>}
      {formSuccess && (
        <Alert variant="success">
          Submission successful! Thanks for providing feedback.
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label className={`${gStyle.green} ${gStyle.greenTs}`}>
            Podium Module
          </Form.Label>
          <Form.Control
            as="select"
            value={feedback.module}
            onChange={(e) => handleFeedbackChange("module", e.target.value)}
          >
            <option value="">Select Module</option>
            <option value="Broadcast Overlay">Broadcast Overlay</option>
            <option value="Driver Coach">Driver Coach</option>
            <option value="League Manager">League Manager</option>
            <option value="Live Engineering">Live Engineering</option>
            <option value="MoTeC Logger">MoTeC Logger</option>
            <option value="Performance Advisor">Performance Advisor</option>
            <option value="Race Director">Race Director</option>
            <option value="Setup Archiver">Setup Archiver</option>
            <option value="Other/General">Other/General</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className={`${gStyle.green} ${gStyle.greenTs}`}>
            Category
          </Form.Label>
          <Form.Control
            as="select"
            value={feedback.category}
            onChange={(e) => handleFeedbackChange("category", e.target.value)}
          >
            <option value="">Select Category</option>
            <option value="Bug Report">Bug Report</option>
            <option value="Design">Design Issues</option>
            <option value="Feature suggestion">Feature Suggestion</option>
            <option value="Other">Other Feedback</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className={`${gStyle.green} ${gStyle.greenTs}`}>
            Description
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={feedback.description}
            onChange={(e) =>
              handleFeedbackChange("description", e.target.value)
            }
          />
        </Form.Group>

        <Button variant="success" type="submit">
          Submit Feedback
        </Button>
      </Form>
    </Container>
  );
};

export default FeedbackForm;
