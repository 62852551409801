import { Fragment } from "react";
//import style from "./App.module.scss";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Home from "./Screens/HomePage/Home";
import AboutPodiumScreen from "./Screens/AboutScreens/AboutContent/PodiumForF1";
import AboutSidebarLayout from "./Screens/AboutScreens/SidebarLayout";
import AboutFAQScreen from "./Screens/AboutScreens/AboutContent/FAQ";
import AboutKnownIssuesScreen from "./Screens/AboutScreens/AboutContent/KnownIssues";
//import AboutLatestNewsScreen from "./Screens/AboutScreens/AboutContent/LatestNews";
import AboutTermsScreen from "./Screens/AboutScreens/AboutContent/Terms";
import AboutGuidesScreen from "./Screens/AboutScreens/AboutContent/HowToGuides";
import AboutTutorialsScreen from "./Screens/AboutScreens/AboutContent/VideoTutorials";
import AboutPremiumScreen from "./Screens/AboutScreens/AboutContent/PremiumSubs";
import AboutReleaseNotesScreen from "./Screens/AboutScreens/AboutContent/ReleaseNotes";
import AboutPrivacyScreen from "./Screens/AboutScreens/AboutContent/PrivacyPolicy";
import AdminWrapper from "./Screens/AdminScreens/AdminWrapper";
import AdminInfo from "./Screens/AdminScreens/AdminContent/AdminInfo";
import AdminBeta from "./Screens/AdminScreens/AdminContent/AdminBeta";
import AdminBugReports from "./Screens/AdminScreens/AdminContent/AdminBugReports";
import AdminSetups from "./Screens/AdminScreens/AdminContent/AdminSetups";
import AdminSubscribers from "./Screens/AdminScreens/AdminContent/AdminSubscribers";
import AdminUserInfo from "./Screens/AdminScreens/AdminContent/AdminUserInfo";
import PodiumSupportScreen from "./Screens/PodiumScreens/PodiumSupportScreen";
import LoginScreen from "./Screens/LoginScreen/LoginScreen";
import RequestResetScreen from "./Screens/ResetPasswordScreen/RequestResetScreen";
import ResetPasswordScreen from "./Screens/ResetPasswordScreen/ResetPasswordScreen";
import RegisterScreen from "./Screens/RegisterScreen/RegisterScreen";
import ProfileScreen from "./Screens/ProfileScreens/ProfileScreen/ProfileScreen";
import EditProfile from "./Screens/ProfileScreens/EditProfile/EditProfile";
import CreateDriver from "./Screens/ProfileScreens/DriverScreen/DriverCreateScreen";
import DriverScreen from "./Screens/ProfileScreens/DriverScreen/DriverScreen";
import EditDriver from "./Screens/ProfileScreens/DriverScreen/EditDriver";
import FriendshipScreen from "./Screens/ProfileScreens/FriendshipScreen/FriendshipScreen";
import UserScreen from "./Screens/UserScreens/UserScreen";
import LeagueManagerScreen from "./Screens/PodiumScreens/LeaugeManagerScreens/LeagueManagerHome";
import SetupArchiverScreen from "./Screens/PodiumScreens/SetupArchiverScreens/SetupArchiverHome";
import {
  SubscriptionSuccess,
  SubscriptionCancel,
  SubscriptionPortalReturn,
} from "./Components/Subscription/StripeSubscription";
import { Route, Routes, useLocation } from "react-router-dom";
import requireAuth from "./HOC/requireAuth"; // This is the HOC that will be used to protect routes that require authentication.

import gStyle from "./SCSS/global.module.scss";

function App() {
  let { pathname } = useLocation();

  if (pathname === "/") {
    pathname = "/home";
  }

  pathname = pathname.split("/");

  const AuthenticatedProfileScreen = requireAuth(ProfileScreen);
  const AuthenticatedEditProfile = requireAuth(EditProfile);
  const AuthenticatedCreateDriver = requireAuth(CreateDriver);
  const AuthenticatedDriverScreen = requireAuth(DriverScreen);
  const AuthenticatedEditDriver = requireAuth(EditDriver);
  const AuthenticatedFriendshipScreen = requireAuth(FriendshipScreen);
  const AuthenticatedAdminWrapper = requireAuth(AdminWrapper);

  return (
    <Fragment>
      <Header />
      <main
        id={pathname[1]}
        className={`App ${pathname[1] !== "home" ? gStyle.inner : ""}`}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Paths to add */}
          <Route path="/about" element={<AboutSidebarLayout />}>
            <Route
              path="/about/podium-for-f1"
              element={<AboutPodiumScreen />}
            />
            <Route path="/about/faq" element={<AboutFAQScreen />} />
            <Route
              path="/about/known-issues"
              element={<AboutKnownIssuesScreen />}
            />
            {/* 
            <Route
              path="/about/latest-news"
              element={<AboutLatestNewsScreen />}
            />
            */}
            <Route
              path="/about/how-to-guides"
              element={<AboutGuidesScreen />}
            />
            <Route
              path="/about/video-tutorials"
              element={<AboutTutorialsScreen />}
            />
            <Route
              path="/about/premium-subs"
              element={<AboutPremiumScreen />}
            />
            <Route
              path="/about/release-notes"
              element={<AboutReleaseNotesScreen />}
            />
            <Route
              path="/about/privacy-policy"
              element={<AboutPrivacyScreen />}
            />
            <Route
              path="/about/terms-of-service"
              element={<AboutTermsScreen />}
            />
          </Route>
          {/* authenticated paths */}
          <Route path="/admin" element={<AuthenticatedAdminWrapper />}>
            <Route path="/admin/info" element={<AdminInfo />} />
            <Route path="/admin/beta" element={<AdminBeta />} />
            <Route path="/admin/bug-reports" element={<AdminBugReports />} />
            <Route path="/admin/setups" element={<AdminSetups />} />
            <Route path="/admin/subs" element={<AdminSubscribers />} />
            <Route path="/admin/users" element={<AdminUserInfo />} />
          </Route>
          <Route path="/login" element={<LoginScreen />} />
          <Route
            path="/request-password-reset"
            element={<RequestResetScreen />}
          />
          <Route path="/reset-password" element={<ResetPasswordScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/profile" element={<AuthenticatedProfileScreen />} />
          <Route path="/profile/edit" element={<AuthenticatedEditProfile />} />
          <Route
            path="/profile/driver/create"
            element={<AuthenticatedCreateDriver />}
          />
          <Route
            path="/profile/driver"
            element={<AuthenticatedDriverScreen />}
          />
          <Route
            path="/profile/driver/edit"
            element={<AuthenticatedEditDriver />}
          />
          <Route
            path="/profile/friends"
            element={<AuthenticatedFriendshipScreen />}
          />
          <Route path="/user/:id" element={<UserScreen />} />
          {/* podium paths */}
          <Route path="/league-manager" element={<LeagueManagerScreen />} />
          <Route path="/setup-archiver" element={<SetupArchiverScreen />} />
          <Route path="/support" element={<PodiumSupportScreen />} />
          {/* stripe subscription paths */}
          <Route
            path="/subscription/success"
            element={<SubscriptionSuccess />}
          />
          <Route path="/subscription/cancel" element={<SubscriptionCancel />} />
          <Route
            path="/subscription/updated"
            element={<SubscriptionPortalReturn />}
          />
          {/* Catch-all route */}
          <Route path="*" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </Fragment>
  );
}

export default App;
