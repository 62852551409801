import {
  ADMIN_STATS_REQUEST,
  ADMIN_STATS_SUCCESS,
  ADMIN_STATS_FAIL,
  ADMIN_USERS_LIST_REQUEST,
  ADMIN_USERS_LIST_SUCCESS,
  ADMIN_USERS_LIST_FAIL,
  ADMIN_USER_DELETE_REQUEST,
  ADMIN_USER_DELETE_SUCCESS,
  ADMIN_USER_DELETE_FAIL,
  ADMIN_USER_UPDATE_ACCESS_REQUEST,
  ADMIN_USER_UPDATE_ACCESS_SUCCESS,
  ADMIN_USER_UPDATE_ACCESS_FAIL,
} from "../constants/adminConstants";
import axios from "axios";
import { API_BASE_URL } from "../config";

// Fetch statistics for admin dashboard
export const getAdminStatistics = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMIN_STATS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };
    const { data } = await axios.get(
      `${API_BASE_URL}/admin/statistics`,
      config
    );
    dispatch({ type: ADMIN_STATS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADMIN_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get all users
export const listAllUsers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMIN_USERS_LIST_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };
    const { data } = await axios.get(`${API_BASE_URL}/admin/users`, config);
    dispatch({ type: ADMIN_USERS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADMIN_USERS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Delete a user
export const deleteUser = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMIN_USER_DELETE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    };
    await axios.delete(`${API_BASE_URL}/admin/users/${userId}`, config);
    dispatch({ type: ADMIN_USER_DELETE_SUCCESS, userId }); // You might want to pass userId to update the state appropriately.
  } catch (error) {
    dispatch({
      type: ADMIN_USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Update a user's access level
export const updateUserAccess =
  (userId, access) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADMIN_USER_UPDATE_ACCESS_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${API_BASE_URL}/admin/users/${userId}/access`,
        { access },
        config
      );
      dispatch({ type: ADMIN_USER_UPDATE_ACCESS_SUCCESS, payload: data }); // Consider refreshing the user list or updating local state
    } catch (error) {
      dispatch({
        type: ADMIN_USER_UPDATE_ACCESS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
