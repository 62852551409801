import React, { useState, useEffect } from "react";
import MainScreen from "../../../Components/MainScreen";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProfile,
  verifyPassword,
  resetUpdateProfile,
} from "../../../actions/userActions";
import Loading from "../../../Components/Loading";
import ErrorMessage from "../../../Components/ErrorMessage";
import { useNavigate } from "react-router-dom";
import ProfileNav from "../../../Components/ProfileNav";
import "./EditProfile.scss";

const EditProfile = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [picMessage, setPicMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { loading, error, success } = userUpdate;

  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      setUsername(userInfo?.username);
      setEmail(userInfo?.email);
    }
  }, [navigate, userInfo]);

  const postDetails = (pics) => {
    if (pics) {
      const file = pics;
      setPicMessage(null);

      // Create an Image object and set its src to the file
      const img = new Image();
      img.src = URL.createObjectURL(file);

      // When the image has finished loading, check its size
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        if (file.type === "image/jpeg" || file.type === "image/png") {
          if (width !== 256 || height !== 256) {
            return setPicMessage(
              "Please choose a Profile picture with a size of 256 x 256 pixels"
            );
          } else {
            setSelectedFile(file);
          }
        } else {
          return setPicMessage("Please select a valid PNG or JPEG image file");
        }
      };
    }
  };

  const uploadPic = async () => {
    const data = new FormData();
    if (selectedFile) {
      data.append("file", selectedFile);
      data.append("upload_preset", "dzp-leagues");
      data.append("cloud_name", "dbzyqh4jg");
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dbzyqh4jg/image/upload",
        {
          method: "post",
          body: data,
        }
      );
      const dataRes = await res.json();
      return dataRes.url.toString();
    }
    return null;
  };

  const verifyAndUpdateProfile = async () => {
    const picUrl = await uploadPic();
    const updatedUserInfo = {
      ...userInfo,
      username,
      email,
      password,
      pic: picUrl || userInfo?.pic, // Use the new pic or the old one if not updated
      token: userInfo?.token,
    };

    dispatch(updateProfile(updatedUserInfo));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!oldPassword) {
      setMessage("You must enter your old password to update your profile");
      return;
    }

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    setMessage(null); // Clear messages

    // Begin the verification process
    dispatch(verifyPassword(oldPassword))
      .then((verifyAction) => {
        // If password verification is successful
        if (verifyAction) {
          verifyAndUpdateProfile(); // Proceed to update the profile
          setTimeout(() => {
            dispatch(resetUpdateProfile());
            navigate("/profile");
          }, 3000); // Redirect to profile after 3 seconds
        } else {
          setMessage("The old password you entered is incorrect");
        }
      })
      .catch((error) => {
        // Handle any errors that occur during verification
        setMessage("Error verifying your old password.");
        console.error("Password verification error:", error);
      });
  };

  return (
    <MainScreen title="Edit User Profile">
      <Container fluid>
        <Row className="justify-content-center">
          <Col
            style={{
              top: "0",
              left: "0",
            }}
          >
            <ProfileNav />
          </Col>
        </Row>
        <Row className="editProfileContainer">
          <Col>
            <Form onSubmit={submitHandler}>
              {loading && <Loading />}
              {message && (
                <ErrorMessage variant="danger">{message}</ErrorMessage>
              )}
              {success && (
                <ErrorMessage variant="success">
                  Updated Successful
                </ErrorMessage>
              )}
              {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
              <Form.Group controlId="username" className="py-2">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter a Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="email" className="py-2">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password" className="py-2">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password" className="py-2">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="oldPassword" className="py-2">
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>{" "}
              {picMessage && (
                <ErrorMessage variant="danger">{picMessage}</ErrorMessage>
              )}
              <Form.Group controlId="pic" className="py-2">
                <Form.Label>
                  Update Profile Picture {"("}256x256 px ONLY{")"}
                </Form.Label>
                <Form.Control
                  onChange={(e) => postDetails(e.target.files[0])}
                  type="file"
                  accept="image/*"
                  label="Upload Profile Picture 256x256"
                ></Form.Control>
              </Form.Group>
              <ButtonGroup>
                <Button
                  type="submit"
                  variant="success"
                  disabled={Boolean(picMessage)}
                >
                  Update Profile
                </Button>
                <Button variant="danger" onClick={() => navigate("/profile")}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Form>
          </Col>
          <Col>
            <img src={userInfo?.pic} alt={username} className="profilePic" />
          </Col>
        </Row>
      </Container>
    </MainScreen>
  );
};

export default EditProfile;
