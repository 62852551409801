import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
// import your user reducers
import {
  userLoginReducer,
  verifyPasswordReducer,
  clearLoginAttemptReducer,
  userRegisterReducer,
  userUpdateReducer,
  userSearchReducer,
  userDetailsReducer,
  userDriverDetailsReducer,
  userPasswordResetReducer,
  userResetPasswordReducer,
  userTwoFAQrCodeReducer,
  twoFAStatusReducer,
  userTwoFAConfirmReducer,
  userTwoFAToggleReducer,
} from "./reducers/userReducers";
// import your league reducers
import {
  leagueCreateReducer,
  leagueListReducer,
  leagueUpdateReducer,
  leagueDeleteReducer,
} from "./reducers/leaguesReducer";
// import your friend request reducers
import {
  friendRequestSendReducer,
  friendRequestAcceptReducer,
  friendRequestDenyReducer,
  outgoingFriendRequestsReducer,
  receivedFriendRequestsReducer,
  friendsReducer,
  loggedInUserFriendsReducer,
  profileUserFriendsReducer,
  getFriendNotesReducer,
  updateFriendNotesReducer,
  checkFriendRequestSentReducer,
  checkFriendRequestReceivedReducer,
  friendshipIdReducer,
} from "./reducers/friendshipReducers";
// imppoert your driver reducers
import {
  driverCreateReducer,
  driverUpdateReducer,
  driverDetailsReducer,
  driverSearchReducer,
} from "./reducers/driverReducers";
// import your error log reducers
import {
  errorLogCreateReducer,
  errorLogDetailsReducer,
  errorLogUpdateReducer,
  errorLogDeleteReducer,
  errorLogListReducer,
  errorLogListUserReducer,
} from "./reducers/errorLogReducers";
import {
  setupCreateReducer,
  setupGetReducer,
  setupGetAllReducer,
  setupGetUserAllReducer,
  setupUpdateReducer,
  setupDeleteReducer,
} from "./reducers/setupReducers";
import {
  betaApplyReducer,
  betaStatusReducer,
  betaListReducer,
  betaReviewReducer,
  betaDeleteReducer,
} from "./reducers/betaReducers";
import {
  adminStatsReducer,
  adminUsersListReducer,
  adminUserDeleteReducer,
  adminUserUpdateAccessReducer,
} from "./reducers/adminReducers";
import {
  subscriptionCreateReducer,
  subscriptionStatusReducer,
} from "./reducers/subscriptionReducers";

const reducer = combineReducers({
  // user reducers
  userLogin: userLoginReducer,
  verifyPassword: verifyPasswordReducer,
  clearLoginAttempt: clearLoginAttemptReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  userSearch: userSearchReducer,
  userDetails: userDetailsReducer,
  userDriverDetails: userDriverDetailsReducer,
  userPasswordReset: userPasswordResetReducer,
  userResetPassword: userResetPasswordReducer,
  userTwoFAQrCode: userTwoFAQrCodeReducer,
  twoFAStatus: twoFAStatusReducer,
  userTwoFAConfirm: userTwoFAConfirmReducer,
  userTwoFAToggle: userTwoFAToggleReducer,
  // league reducers
  leagueList: leagueListReducer,
  leagueCreate: leagueCreateReducer,
  leagueUpdate: leagueUpdateReducer,
  leagueDelete: leagueDeleteReducer,
  // friend request reducers
  friendRequestSend: friendRequestSendReducer,
  friendRequestAccept: friendRequestAcceptReducer,
  friendRequestDeny: friendRequestDenyReducer,
  outgoingFriendRequests: outgoingFriendRequestsReducer,
  receivedFriendRequests: receivedFriendRequestsReducer,
  friendsList: friendsReducer,
  loggedInUserFriends: loggedInUserFriendsReducer,
  profileUserFriends: profileUserFriendsReducer,
  friendNotes: getFriendNotesReducer,
  updateFriendNotes: updateFriendNotesReducer,
  checkFriendRequestSent: checkFriendRequestSentReducer,
  checkFriendRequestReceived: checkFriendRequestReceivedReducer,
  friendshipId: friendshipIdReducer,
  // driver reducers
  driverCreate: driverCreateReducer,
  driverUpdate: driverUpdateReducer,
  driverDetails: driverDetailsReducer,
  driverSearch: driverSearchReducer,
  // error log reducers
  errorLogCreate: errorLogCreateReducer,
  errorLogDetails: errorLogDetailsReducer,
  errorLogUpdate: errorLogUpdateReducer,
  errorLogDelete: errorLogDeleteReducer,
  errorLogList: errorLogListReducer,
  errorLogUserList: errorLogListUserReducer,
  // setup reducers
  setupCreate: setupCreateReducer,
  setupGet: setupGetReducer,
  setupGetAll: setupGetAllReducer,
  setupGetUserAll: setupGetUserAllReducer,
  setupUpdate: setupUpdateReducer,
  setupDelete: setupDeleteReducer,
  // beta reducers
  betaApply: betaApplyReducer,
  betaStatus: betaStatusReducer,
  betaList: betaListReducer,
  betaReview: betaReviewReducer,
  betaDelete: betaDeleteReducer,
  // admin reducers
  adminStats: adminStatsReducer,
  adminUsersList: adminUsersListReducer,
  adminUserDelete: adminUserDeleteReducer,
  adminUserUpdateAccess: adminUserUpdateAccessReducer,
  // subscription reducers
  subscriptionCreate: subscriptionCreateReducer,
  subscriptionStatus: subscriptionStatusReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const twoFAStatusFromStorage = localStorage.getItem("twoFAStatus")
  ? JSON.parse(localStorage.getItem("twoFAStatus"))
  : { twoFAEnabled: false, hasTwoFASecret: false };

const initialState = {
  // intitalize your user states
  userLogin: { userInfo: userInfoFromStorage },
  verifyPassword: { loading: false, success: false, isValid: false },
  userTwoFAQrCode: { loading: false, success: false, qrCodeImageUrl: "" },
  twoFAStatus: twoFAStatusFromStorage,
  userSearch: { users: [] },
  userDetails: { loading: false, user: {} },
  userDriverDetails: { loading: false, userDriver: {} },
  userTwoFAToggle: {},
  // initialize your friend request states
  friendRequestSend: { loading: false, success: false },
  friendRequestAccept: { loading: false, success: false },
  friendRequestDeny: { loading: false, success: false },
  outgoingFriendRequests: { loading: false, friendRequests: [] },
  receivedFriendRequests: { loading: false, friendRequests: [] },
  friendsList: { loading: false, friends: [] },
  loggedInUserFriends: { loading: false, friends: [] },
  profileUserFriends: { loading: false, friends: [] },
  checkFriendRequestSent: {
    loading: false,
    error: null,
    hasSentRequest: false,
  },
  checkFriendRequestReceived: {
    loading: false,
    error: null,
    hasReceivedRequest: false,
  },
  friendshipId: {},
  // initialize your driver states
  driverSearch: { drivers: [] },
  driverDetails: { loading: false, driver: {} },
  // initialize your league states
  leagueList: { loading: false, leagues: [] },
  leagueCreate: { loading: false, success: false, league: {} },
  leagueUpdate: { loading: false, success: false },
  leagueDelete: { loading: false, success: false },
  // initialize your error log states
  errorLogCreate: { loading: false, success: false, errorLog: {} },
  errorLogDetails: { loading: false, errorLog: {} },
  errorLogUpdate: { loading: false, success: false },
  errorLogDelete: { loading: false, success: false },
  errorLogList: { loading: false, errorLogs: [] },
  errorLogUserList: { loading: false, errorLogs: [] },
  // initialize your setup states
  setupCreate: { setup: {} },
  setupGet: { loading: true, setup: {} },
  setupGetAll: { loading: true, setups: [] },
  setupGetUserAll: { loading: true, setups: [] },
  setupUpdate: { loading: true, setup: {} },
  setupDelete: { loading: true },
  // initialize your beta states
  betaApply: {
    loading: false,
    success: false,
    betaInfo: null,
    error: null,
  },
  betaStatus: {
    loading: false,
    statusInfo: { status: "no_application" }, // Default status before applying
    error: null,
  },
  betaList: {
    loading: false,
    applications: [],
    error: null,
  },
  betaReview: {
    loading: false,
    success: false,
    error: null,
  },
  betaDelete: {
    loading: false,
    success: false,
    error: null,
  },
  // initialize your admin states
  adminStats: {
    loading: false,
    stats: {}, // Includes totalUsers, noneAccessUsers, etc.
    error: null,
  },
  adminUsersList: {
    loading: false,
    users: [],
    error: null,
  },
  adminUserDelete: {
    loading: false,
    success: false,
    error: null,
  },
  adminUserUpdateAccess: {
    loading: false,
    success: false,
    error: null,
  },
  // initialize your subscription states
  subscriptionCreate: {
    loading: false,
    success: false,
    sessionId: null,
    error: null,
  },
  subscriptionStatus: {
    loading: false,
    subscription: {},
    error: null,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
