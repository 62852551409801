export const ADMIN_STATS_REQUEST = "ADMIN_STATS_REQUEST";
export const ADMIN_STATS_SUCCESS = "ADMIN_STATS_SUCCESS";
export const ADMIN_STATS_FAIL = "ADMIN_STATS_FAIL";

export const ADMIN_USERS_LIST_REQUEST = "ADMIN_USERS_LIST_REQUEST";
export const ADMIN_USERS_LIST_SUCCESS = "ADMIN_USERS_LIST_SUCCESS";
export const ADMIN_USERS_LIST_FAIL = "ADMIN_USERS_LIST_FAIL";

export const ADMIN_USER_DELETE_REQUEST = "ADMIN_USER_DELETE_REQUEST";
export const ADMIN_USER_DELETE_SUCCESS = "ADMIN_USER_DELETE_SUCCESS";
export const ADMIN_USER_DELETE_FAIL = "ADMIN_USER_DELETE_FAIL";

export const ADMIN_USER_UPDATE_ACCESS_REQUEST =
  "ADMIN_USER_UPDATE_ACCESS_REQUEST";
export const ADMIN_USER_UPDATE_ACCESS_SUCCESS =
  "ADMIN_USER_UPDATE_ACCESS_SUCCESS";
export const ADMIN_USER_UPDATE_ACCESS_FAIL = "ADMIN_USER_UPDATE_ACCESS_FAIL";
