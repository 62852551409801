import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Table,
  Button,
  Form,
  ButtonGroup,
  Col,
  Modal,
  Badge,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  updateErrorLog,
  getErrorLogDetails,
  deleteErrorLog,
  listErrorLogs,
} from "../../../actions/errorLogActions";
import Loading from "../../../Components/Loading";
import ErrorMessage from "../../../Components/ErrorMessage";
import Trash from "../../../Images/trash-white.svg";
import "../Admin.scss";
import { BsQuestionDiamondFill } from "react-icons/bs";
import {
  FcLowPriority,
  FcMediumPriority,
  FcHighPriority,
} from "react-icons/fc";
import { FiClipboard } from "react-icons/fi";
//import gStyle from "../../../SCSS/global.module.scss";

const AdminBugReports = () => {
  const dispatch = useDispatch();
  const textRef = useRef(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterPriority, setFilterPriority] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentErrorLog, setCurrentErrorLog] = useState(null);
  const [developerNotes, setDeveloperNotes] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentPriority, setCurrentPriority] = useState("");

  const errorLogList = useSelector((state) => state.errorLogList);
  const { loading, error, errorLogs } = errorLogList;

  const errorLogDetails = useSelector((state) => state.errorLogDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    errorLog,
  } = errorLogDetails;

  const errorLogDelete = useSelector((state) => state.errorLogDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = errorLogDelete;

  const errorLogUpdate = useSelector((state) => state.errorLogUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = errorLogUpdate;

  useEffect(() => {
    dispatch(listErrorLogs());
  }, [dispatch, successDelete, successUpdate]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this error log?")) {
      dispatch(deleteErrorLog(id));
    }
  };

  const handleUpdateError = () => {
    if (currentErrorLog && currentErrorLog._id) {
      // Clone the current error log and update its developer_notes
      const updatedErrorLog = {
        ...currentErrorLog,
        developer_notes: developerNotes,
        status: currentStatus,
        priority_level: currentPriority,
      };
      // Dispatch the update action with the modified error log
      dispatch(updateErrorLog(updatedErrorLog));
      // Close the modal and clear the form
      setShowModal(false);
      setCurrentErrorLog(null);
      setDeveloperNotes(""); // Clear developer notes state
      setCurrentStatus(""); // Clear current status state
      setCurrentPriority(""); // Clear current priority state
    }
  };

  const handleModalShow = (id) => {
    setCurrentErrorLog(id);
    dispatch(getErrorLogDetails(id));
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setCurrentErrorLog(null);
  };

  const sortByField = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  useEffect(() => {
    if (errorLogDetails && errorLogDetails.errorLog && showModal) {
      setCurrentErrorLog(errorLogDetails.errorLog); // Set the current error log
      setDeveloperNotes(errorLogDetails.errorLog.developer_notes || ""); // Initialize the developerNotes state
      setCurrentStatus(errorLogDetails.errorLog.status); // Initialize the currentStatus state
      setCurrentPriority(errorLogDetails.errorLog.priority_level); // Initialize the currentPriority state
    }
  }, [errorLogDetails, showModal]);

  const getPriorityIcon = (priority) => {
    switch (priority) {
      case "undetermined":
        return <BsQuestionDiamondFill />;
      case "trivial":
      case "low":
        return <FcLowPriority />;
      case "medium":
        return <FcMediumPriority />;
      case "high":
      case "critical":
        return <FcHighPriority />;
      default:
        return null; // or some default icon
    }
  };

  const sortedErrorLogs = [...(errorLogs || [])]
    .sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    })
    .filter(
      (errorLog) => filterStatus === "" || errorLog.status === filterStatus
    )
    .filter(
      (errorLog) =>
        filterPriority === "" || errorLog.priority_level === filterPriority
    );

  const prettyPrintErrorLog = (errorLog) => {
    try {
      // Parse the error log if it's a string, otherwise assume it's already an object
      const logObj =
        typeof errorLog === "string" ? JSON.parse(errorLog) : errorLog;
      // Return a pretty-printed string
      return JSON.stringify(logObj, null, 2);
    } catch (e) {
      // Return the original log if parsing fails
      return errorLog;
    }
  };

  const copyToClipboard = () => {
    const text = textRef.current.innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard", err);
      });
  };

  return (
    <Container>
      <Row>
        <h2>Bug Reports</h2>
        {loading || loadingDelete || loadingUpdate ? (
          <Loading />
        ) : error ? (
          <ErrorMessage variant="danger">{error}</ErrorMessage>
        ) : (
          <>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Filter by Status</Form.Label>
                <Form.Select
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <option value="">All Error Logs</option>
                  <option value="new">New</option>
                  <option value="in progress">In Progress</option>
                  <option value="resolved">Resolved</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Filter by Priority</Form.Label>
                <Form.Select
                  value={filterPriority}
                  onChange={(e) => setFilterPriority(e.target.value)}
                >
                  <option value="">All Error Logs</option>
                  <option value="undetermined">Undetermined</option>
                  <option value="trivial">Trivial</option>
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                  <option value="critical">Critical</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
              <Table
                striped
                bordered
                hover
                responsive
                class="table table-fixed"
              >
                <thead className="bg-black">
                  <tr>
                    <th>User</th>
                    <th>Error Location</th>
                    <th>Action Causing Error</th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByField("createdAt")}
                    >
                      Created At{" "}
                      {sortField === "createdAt" &&
                        (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByField("satus")}
                    >
                      Status{" "}
                      {sortField === "status" &&
                        (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedErrorLogs.map((errorLog) => (
                    <tr key={errorLog._id}>
                      <td>
                        <LinkContainer
                          style={{ width: "100%" }}
                          to={`/user/${errorLog.user._id}`}
                        >
                          <Button>{errorLog.user.username}</Button>
                        </LinkContainer>
                      </td>
                      <td>{errorLog.module}</td>
                      <td>{errorLog.relevant_error_details}</td>
                      <td>
                        {new Date(errorLog.createdAt).toLocaleDateString()}
                      </td>
                      <td>
                        {getPriorityIcon(errorLog.priority_level)}{" "}
                        {errorLog.status}
                      </td>
                      <td>
                        <ButtonGroup>
                          <Button
                            variant="info"
                            onClick={() => handleModalShow(errorLog._id)}
                          >
                            View
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDelete(errorLog._id)}
                          >
                            <img
                              src={Trash}
                              alt="Delete"
                              style={{ width: "20px", height: "20px" }}
                            />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Modal
              show={showModal}
              onHide={handleModalClose}
              size="lg"
              dialogClassName="custom-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>Error Log Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {loadingDetails ? (
                  <Loading />
                ) : errorDetails ? (
                  <ErrorMessage variant="danger">{errorDetails}</ErrorMessage>
                ) : (
                  errorLog && (
                    <>
                      <div className="modal-section">
                        <Row className="align-items-center">
                          <Col>
                            <Badge bg="info" style={{ marginRight: "5px" }}>
                              {new Date(
                                errorLogDetails.errorLog.createdAt
                              ).toLocaleDateString("en-US", {
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                            </Badge>
                            <Badge bg="info">
                              {new Date(
                                errorLogDetails.errorLog.createdAt
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </Badge>
                          </Col>
                          <Col className="text-md-end">
                            <Badge bg="dark" style={{ marginRight: "5px" }}>
                              {errorLogDetails.errorLog.type}
                            </Badge>
                            <Badge
                              bg={
                                errorLogDetails.errorLog.status === "new"
                                  ? "danger"
                                  : errorLogDetails.errorLog.status ===
                                    "in progress"
                                  ? "warning"
                                  : "success"
                              }
                              style={{ marginRight: "5px" }}
                            >
                              {errorLogDetails.errorLog.status}
                            </Badge>
                            <Badge
                              bg={
                                errorLogDetails.errorLog.priority_level ===
                                "undetermined"
                                  ? "dark"
                                  : errorLogDetails.errorLog.priority_level ===
                                    "trivial"
                                  ? "secondary"
                                  : errorLogDetails.errorLog.priority_level ===
                                    "low"
                                  ? "info"
                                  : errorLogDetails.errorLog.priority_level ===
                                    "medium"
                                  ? "light"
                                  : errorLogDetails.errorLog.priority_level ===
                                    "high"
                                  ? "warning"
                                  : "danger"
                              }
                            >
                              {errorLogDetails.errorLog.priority_level}
                            </Badge>
                          </Col>
                        </Row>
                      </div>
                      <div className="modal-section">
                        <strong>Module:</strong>{" "}
                        {errorLogDetails.errorLog.module}
                      </div>
                      <div className="modal-section">
                        <strong>Error Details:</strong>{" "}
                        {errorLogDetails.errorLog.relevant_error_details}
                      </div>
                      <div
                        className="modal-section"
                        ref={textRef}
                        style={{ position: "relative" }}
                      >
                        <FiClipboard
                          className="copy-icon"
                          onClick={copyToClipboard}
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            cursor: "pointer",
                            opacity: 0.4,
                            transition: "opacity 0.2s ease-in-out",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.opacity = 1)
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.opacity = 0.4)
                          }
                        />
                        <strong>Error Log:</strong>
                        <pre>
                          <code>
                            {prettyPrintErrorLog(
                              errorLogDetails?.errorLog?.error_log
                            )
                              ?.replace(/\\n/g, "\n")
                              .replace(/\\+"/g, '"')
                              .replace(/\\\\+/g, "\\")}
                          </code>
                        </pre>
                      </div>
                      <div className="modal-section">
                        <Row className="gx-2 align-items-center">
                          <Col className="text-md-start flex-grow-0">
                            <strong>Device:</strong>{" "}
                          </Col>
                          <Col className="flex-grow-1">
                            {errorLogDetails.errorLog.device_info}
                          </Col>
                          <Col className="text-md-end">
                            <div>
                              <strong>Version:</strong>{" "}
                              {errorLogDetails.errorLog.app_version}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <Form.Group className="modal-section">
                        <Form.Label>
                          <strong>Notes from User:</strong>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          value={errorLogDetails.errorLog.additional_user_notes}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group className="modal-section">
                        <Form.Label>
                          <strong>Developer Notes:</strong>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={developerNotes}
                          onChange={(e) => setDeveloperNotes(e.target.value)}
                        />
                      </Form.Group>
                      <div className="modal-section">
                        <Row className="align-items-center">
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                <strong>Status:</strong>
                              </Form.Label>
                              <Form.Select
                                value={currentStatus}
                                onChange={(e) =>
                                  setCurrentStatus(e.target.value)
                                }
                              >
                                <option value="new">New</option>
                                <option value="in progress">In Progress</option>
                                <option value="resolved">Resolved</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                <strong>Priority Level:</strong>
                              </Form.Label>
                              <Form.Select
                                value={currentPriority}
                                onChange={(e) =>
                                  setCurrentPriority(e.target.value)
                                }
                              >
                                <option value="undetermined">
                                  Undetermined
                                </option>
                                <option value="trivial">Trivial</option>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                                <option value="critical">Critical</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )
                )}
              </Modal.Body>
              <Modal.Footer>
                <Col className="text-left">
                  <strong>Last Updated:</strong>{" "}
                  <Badge bg="warning" style={{ marginRight: "5px" }}>
                    {new Date(
                      errorLogDetails.errorLog.updatedAt
                    ).toLocaleDateString("en-US", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </Badge>
                  <Badge bg="warning">
                    {new Date(
                      errorLogDetails.errorLog.updatedAt
                    ).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </Badge>
                </Col>
                <Col className="text-md-end">
                  <ButtonGroup>
                    <Button variant="secondary" onClick={handleModalClose}>
                      Close
                    </Button>
                    <Button variant="info" onClick={handleUpdateError}>
                      Save Changes
                    </Button>
                  </ButtonGroup>
                </Col>
              </Modal.Footer>
            </Modal>
          </>
        )}
        {errorDelete && (
          <ErrorMessage variant="danger">{errorDelete}</ErrorMessage>
        )}
        {errorUpdate && (
          <ErrorMessage variant="danger">{errorUpdate}</ErrorMessage>
        )}
      </Row>
    </Container>
  );
};

export default AdminBugReports;
