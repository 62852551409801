export const DRIVER_CREATE_REQUEST = "DRIVER_CREATE_REQUEST";
export const DRIVER_CREATE_SUCCESS = "DRIVER_CREATE_SUCCESS";
export const DRIVER_CREATE_FAIL = "DRIVER_CREATE_FAIL";
export const DRIVER_CREATE_RESET = "DRIVER_CREATE_RESET";

export const DRIVER_UPDATE_REQUEST = "DRIVER_UPDATE_REQUEST";
export const DRIVER_UPDATE_SUCCESS = "DRIVER_UPDATE_SUCCESS";
export const DRIVER_UPDATE_FAIL = "DRIVER_UPDATE_FAIL";
export const DRIVER_UPDATE_RESET = "DRIVER_UPDATE_RESET";

export const DRIVER_DETAILS_REQUEST = "DRIVER_DETAILS_REQUEST";
export const DRIVER_DETAILS_SUCCESS = "DRIVER_DETAILS_SUCCESS";
export const DRIVER_DETAILS_FAIL = "DRIVER_DETAILS_FAIL";
export const DRIVER_DETAILS_RESET = "DRIVER_DETAILS_RESET";

export const DRIVER_SEARCH_REQUEST = "DRIVER_SEARCH_REQUEST";
export const DRIVER_SEARCH_SUCCESS = "DRIVER_SEARCH_SUCCESS";
export const DRIVER_SEARCH_FAIL = "DRIVER_SEARCH_FAIL";
export const DRIVER_SEARCH_RESET = "DRIVER_SEARCH_RESET";
