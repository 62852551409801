import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import MainScreen from "../../Components/MainScreen";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import { Link } from "react-router-dom";
import ErrorMessage from "../../Components/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { resetUserPassword } from "../../actions/userActions";

import "./ResetPasswordScreen.scss";
import gStyle from "../../SCSS/global.module.scss";

const ResetPasswordScreen = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { loading, error, success } = userResetPassword;

  // Get the token from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match!"); // Set the error message
    } else {
      setPasswordError(""); // Clear any previous error message
      // Dispatch reset password action
      dispatch(resetUserPassword(token, password)); // Pass the token and new password to your action
    }
  };

  if (success) {
    setTimeout(() => {
      navigate("/login"); // Navigate using navigate function
    }, 3000); // Redirect to login after 3 seconds
  }

  return (
    <MainScreen title="Reset Your Password">
      <div className="loginContainer">
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {passwordError && (
          <ErrorMessage variant="danger">{passwordError}</ErrorMessage>
        )}
        {loading && <Loading />}
        {success && (
          <ErrorMessage variant="success">
            Password has been reset successfully. Redirecting to login...
          </ErrorMessage>
        )}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="formBasicPassword" className="py-2">
            <Form.Label className={`${gStyle.wh} ${gStyle.whTs}`}>
              New Password
            </Form.Label>
            <Form.Control
              type="password"
              value={password}
              placeholder="Enter new password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicConfirmPassword" className="py-2">
            <Form.Label className={`${gStyle.wh} ${gStyle.whTs}`}>
              Confirm New Password
            </Form.Label>
            <Form.Control
              type="password"
              value={confirmPassword}
              placeholder="Confirm new password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="success" type="submit" size="md" className="my-2">
            Reset Password
          </Button>
        </Form>
        <Row className="py-3">
          <Col>
            <p className={`${gStyle.wh}`}>
              Remembered your password?{" "}
              <Link to="/login" className={`register ${gStyle.cyan}`}>
                Login
              </Link>
            </p>
          </Col>
        </Row>
      </div>
    </MainScreen>
  );
};

export default ResetPasswordScreen;
