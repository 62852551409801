import {
  FRIEND_REQUEST_SEND_REQUEST,
  FRIEND_REQUEST_SEND_SUCCESS,
  FRIEND_REQUEST_SEND_FAIL,
  FRIEND_REQUEST_ACCEPT_REQUEST,
  FRIEND_REQUEST_ACCEPT_SUCCESS,
  FRIEND_REQUEST_ACCEPT_FAIL,
  FRIEND_REQUEST_DENY_REQUEST,
  FRIEND_REQUEST_DENY_SUCCESS,
  FRIEND_REQUEST_DENY_FAIL,
  FRIEND_REQUEST_RESET,
  FRIEND_REQUESTS_OUTGOING_REQUEST,
  FRIEND_REQUESTS_OUTGOING_SUCCESS,
  FRIEND_REQUESTS_OUTGOING_FAIL,
  FRIEND_REQUEST_RECEIVED_REQUEST,
  FRIEND_REQUEST_RECEIVED_SUCCESS,
  FRIEND_REQUEST_RECEIVED_FAIL,
  FRIENDS_REQUEST,
  FRIENDS_SUCCESS,
  FRIENDS_FAIL,
  LOGGED_IN_USER_FRIENDS_REQUEST,
  LOGGED_IN_USER_FRIENDS_SUCCESS,
  LOGGED_IN_USER_FRIENDS_FAIL,
  PROFILE_USER_FRIENDS_REQUEST,
  PROFILE_USER_FRIENDS_SUCCESS,
  PROFILE_USER_FRIENDS_FAIL,
  FRIEND_NOTES_REQUEST,
  FRIEND_NOTES_SUCCESS,
  FRIEND_NOTES_FAIL,
  FRIEND_NOTES_RESET,
  UPDATE_FRIEND_NOTES_REQUEST,
  UPDATE_FRIEND_NOTES_SUCCESS,
  UPDATE_FRIEND_NOTES_FAIL,
  UPDATE_FRIEND_NOTES_RESET,
  CHECK_FRIEND_REQUEST_SENT_REQUEST,
  CHECK_FRIEND_REQUEST_SENT_SUCCESS,
  CHECK_FRIEND_REQUEST_SENT_FAIL,
  CHECK_FRIEND_REQUEST_RECEIVED_REQUEST,
  CHECK_FRIEND_REQUEST_RECEIVED_SUCCESS,
  CHECK_FRIEND_REQUEST_RECEIVED_FAIL,
  FRIENDSHIP_ID_REQUEST,
  FRIENDSHIP_ID_SUCCESS,
  FRIENDSHIP_ID_FAIL,
} from "../constants/friendshipConstants";

export const friendRequestSendReducer = (state = {}, action) => {
  switch (action.type) {
    case FRIEND_REQUEST_SEND_REQUEST:
      return { loading: true };
    case FRIEND_REQUEST_SEND_SUCCESS:
      return { loading: false, success: true };
    case FRIEND_REQUEST_SEND_FAIL:
      return { loading: false, error: action.payload };
    case FRIEND_REQUEST_RESET:
      return {};
    default:
      return state;
  }
};

export const friendRequestAcceptReducer = (state = {}, action) => {
  switch (action.type) {
    case FRIEND_REQUEST_ACCEPT_REQUEST:
      return { loading: true };
    case FRIEND_REQUEST_ACCEPT_SUCCESS:
      return { loading: false, success: true };
    case FRIEND_REQUEST_ACCEPT_FAIL:
      return { loading: false, error: action.payload };
    case FRIEND_REQUEST_RESET:
      return {};
    default:
      return state;
  }
};

export const friendRequestDenyReducer = (state = {}, action) => {
  switch (action.type) {
    case FRIEND_REQUEST_DENY_REQUEST:
      return { loading: true };
    case FRIEND_REQUEST_DENY_SUCCESS:
      return { loading: false, success: true };
    case FRIEND_REQUEST_DENY_FAIL:
      return { loading: false, error: action.payload };
    case FRIEND_REQUEST_RESET:
      return {};
    default:
      return state;
  }
};

export const outgoingFriendRequestsReducer = (
  state = { requests: [] },
  action
) => {
  switch (action.type) {
    case FRIEND_REQUESTS_OUTGOING_REQUEST:
      return { loading: true, requests: [] };
    case FRIEND_REQUESTS_OUTGOING_SUCCESS:
      return { loading: false, requests: action.payload };
    case FRIEND_REQUESTS_OUTGOING_FAIL:
      return { loading: false, error: action.payload, requests: [] };
    default:
      return state;
  }
};

// Received friend requests reducer
export const receivedFriendRequestsReducer = (
  state = { requests: [] },
  action
) => {
  switch (action.type) {
    case FRIEND_REQUEST_RECEIVED_REQUEST:
      return { loading: true, requests: [] };
    case FRIEND_REQUEST_RECEIVED_SUCCESS:
      return { loading: false, requests: action.payload };
    case FRIEND_REQUEST_RECEIVED_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const friendsReducer = (state = { friends: [] }, action) => {
  switch (action.type) {
    case FRIENDS_REQUEST:
      return { loading: true, friends: [] };
    case FRIENDS_SUCCESS:
      return { loading: false, friends: action.payload };
    case FRIENDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// This will hold the friends of the logged-in user
export const loggedInUserFriendsReducer = (state = { friends: [] }, action) => {
  switch (action.type) {
    case LOGGED_IN_USER_FRIENDS_REQUEST:
      return { loading: true, friends: [] };
    case LOGGED_IN_USER_FRIENDS_SUCCESS:
      return { loading: false, friends: action.payload };
    case LOGGED_IN_USER_FRIENDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// This will hold the friends of the profile user
export const profileUserFriendsReducer = (state = { friends: [] }, action) => {
  switch (action.type) {
    case PROFILE_USER_FRIENDS_REQUEST:
      return { loading: true, friends: [] };
    case PROFILE_USER_FRIENDS_SUCCESS:
      return { loading: false, friends: action.payload };
    case PROFILE_USER_FRIENDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getFriendNotesReducer = (state = { notes: {} }, action) => {
  switch (action.type) {
    case FRIEND_NOTES_REQUEST:
      return { loading: true, notes: {} };
    case FRIEND_NOTES_SUCCESS:
      return { loading: false, notes: action.payload };
    case FRIEND_NOTES_FAIL:
      return { loading: false, error: action.payload };
    case FRIEND_NOTES_RESET:
      return { notes: {} };
    default:
      return state;
  }
};

export const updateFriendNotesReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FRIEND_NOTES_REQUEST:
      return { loading: true };
    case UPDATE_FRIEND_NOTES_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_FRIEND_NOTES_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_FRIEND_NOTES_RESET:
      return {};
    default:
      return state;
  }
};

export const checkFriendRequestSentReducer = (
  state = { loading: false, error: null, hasSentRequest: false },
  action
) => {
  switch (action.type) {
    case CHECK_FRIEND_REQUEST_SENT_REQUEST:
      return { ...state, loading: true, error: null };
    case CHECK_FRIEND_REQUEST_SENT_SUCCESS:
      return { ...state, loading: false, hasSentRequest: action.payload };
    case CHECK_FRIEND_REQUEST_SENT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const checkFriendRequestReceivedReducer = (
  state = { loading: false, error: null, hasReceivedRequest: false },
  action
) => {
  switch (action.type) {
    case CHECK_FRIEND_REQUEST_RECEIVED_REQUEST:
      return { ...state, loading: true, error: null };
    case CHECK_FRIEND_REQUEST_RECEIVED_SUCCESS:
      return { ...state, loading: false, hasReceivedRequest: action.payload };
    case CHECK_FRIEND_REQUEST_RECEIVED_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const friendshipIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FRIENDSHIP_ID_REQUEST:
      return { loading: true };
    case FRIENDSHIP_ID_SUCCESS:
      return { loading: false, friendshipId: action.payload };
    case FRIENDSHIP_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
