import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import MainScreen from "../../Components/MainScreen";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loading";
import ErrorMessage from "../../Components/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { initiatePasswordReset } from "../../actions/userActions";

import "./ResetPasswordScreen.scss";
import gStyle from "../../SCSS/global.module.scss";

const RequestResetScreen = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const userPasswordReset = useSelector((state) => state.userPasswordReset);
  const { loading, error, success } = userPasswordReset;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(initiatePasswordReset(email));
  };

  return (
    <MainScreen title="Request Password Reset">
      <div className="loginContainer">
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {loading && <Loading />}
        {success && (
          <ErrorMessage variant="success">
            Reset link has been sent to your email
          </ErrorMessage>
        )}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="formBasicEmail" className="py-2">
            <Form.Label className={`${gStyle.wh} ${gStyle.whTs}`}>
              Email address
            </Form.Label>
            <Form.Control
              type="email"
              value={email}
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text className="text-muted">
              We'll send you instructions to reset your password.
            </Form.Text>
          </Form.Group>

          <Button variant="success" type="submit" size="md" className="my-2">
            Send Reset Instructions
          </Button>
        </Form>
        <Row className="py-3">
          <Col>
            <p className={`${gStyle.wh}`}>
              Remember your password?{" "}
              <Link to="/login" className={`register ${gStyle.cyan}`}>
                Login
              </Link>
            </p>
          </Col>
        </Row>
      </div>
    </MainScreen>
  );
};

export default RequestResetScreen;
