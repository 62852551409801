import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "./Footer.module.scss";
import gStyle from "../../SCSS/global.module.scss";
import PodiumWebLogo from "../../Images/podium_logos/Podium-the-cat-inverse.svg";
import DZPLogo from "../../Images/WhiteWhite.svg";
import DiscordLogo from "../../Images/discord-white.svg";
import YouTubeLogo from "../../Images/youtube-white.svg";
import TwitterLogo from "../../Images/X_logo.svg";

const Footer = () => {
  return (
    <footer className={gStyle.purpleBG}>
      <Container>
        <Row
          className={`${style.contain} ${gStyle.flex} ${gStyle.flexAlignItemsTop}`}
        >
          <Col md={4} className={style.mainLogoContainer}>
            <Col
              className={`${gStyle.flex} ${gStyle.flexStart} ${gStyle.flexAlignItemsCenter}`}
            >
              <img
                src={PodiumWebLogo}
                className={style.dzp}
                alt="Podium Theory"
                style={{ borderRadius: "4px" }}
              />
              {/* <h2 className={`${gStyle.wh} ${gStyle.whTs} ms-3`}>
                        Delta Zero Production
                    </h2> */}
            </Col>
          </Col>
          <Col
            xs="auto"
            md={8}
            className={`${style.right} ${gStyle.flex} ${gStyle.flexSpaceBetween} ${gStyle.flexAlignItemsTop}`}
          >
            <Col md={6}>
              <Row>
                <h4 className={`${gStyle.wh} ${gStyle.purpleTs} ${gStyle.ct}`}>
                  Visit Us
                </h4>
              </Row>
              <Row
                className={`${gStyle.flex} ${gStyle.flexSpaceBetween} ${gStyle.flexAlignItemsCenter}`}
              >
                <Col className={`${style.logo} ${gStyle.ct} py-3`}>
                  <a
                    href="https://www.deltazeroproduction.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={DZPLogo} alt="Delta Zero" />
                  </a>
                </Col>
                <Col className={`${style.logo} ${gStyle.ct} py-3`}>
                  <a
                    href="https://discord.gg/deltazero"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={DiscordLogo} alt="Discord" />
                  </a>
                </Col>
                <Col className={`${style.logo} ${gStyle.ct} py-3`}>
                  <a
                    href="https://www.youtube.com/@deltazeroproduction"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={YouTubeLogo} alt="YouTube" />
                  </a>
                </Col>
                <Col className={`${style.logo2} ${gStyle.ct} py-3`}>
                  <a
                    href="https://x.com/PodiumTheory"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TwitterLogo} alt="Twitter" />
                  </a>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <p
              className={`${style.defaultText} ${gStyle.wh} ${gStyle.purpleTs}`}
            >
              PodiumTheory.com © 2024 Delta Zero, LLC. All Rights Reserved
            </p>
            <p
              className={`${style.smallScreenText} ${gStyle.wh} ${gStyle.purpleTs}`}
              style={{ textAlign: "center" }}
            >
              © 2024 Delta Zero, LLC.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
