import React from "react";
import { Container, Row, Accordion } from "react-bootstrap";
import faqData from "./Podium_FAQs.json";
import { marked } from "marked";
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";

const FAQs = () => {
  return (
    <Container>
      <Row>
        <div className="spacing">
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>
            Frequently Asked Questions
          </h2>
          <Accordion className="faq-accordion">
            {faqData.faqs.map((faq, index) => (
              <Accordion.Item eventKey={String(index)} key={index}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: marked.parse(faq.answer),
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Row>
    </Container>
  );
};

export default FAQs;
