import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAllBetaApplications } from "../../../actions/betaActions";
import { getAdminStatistics } from "../../../actions/adminActions";
import { listErrorLogs } from "../../../actions/errorLogActions";
import { Container, Row, Col, Badge } from "react-bootstrap";
import Loading from "../../../Components/Loading";
import ErrorMessage from "../../../Components/ErrorMessage";

const AdminInfo = () => {
  const dispatch = useDispatch();

  const betaList = useSelector((state) => state.betaList);
  const { applications, loading: betaLoading, error: betaError } = betaList;

  const adminStats = useSelector((state) => state.adminStats);
  const { stats, loading: adminLoading, error: adminError } = adminStats;

  const errorLogList = useSelector((state) => state.errorLogList);
  const { errorLogs, loading: errorLoading, error: errorError } = errorLogList;

  useEffect(() => {
    dispatch(listAllBetaApplications());
    dispatch(getAdminStatistics());
    dispatch(listErrorLogs());
  }, [dispatch]);

  // Count applications based on their status
  const totalAppsCount = applications?.length;
  const pendingCount = applications?.filter(
    (app) => app.status === "pending"
  ).length;
  const deniedCount = applications?.filter(
    (app) => app.status === "denied"
  ).length;
  const acceptedCount = applications?.filter(
    (app) => app.status === "accepted"
  ).length;

  // Count error logs based on their status
  const totalErrorCount = errorLogs?.length;
  const newErrorCount = errorLogs?.filter((log) => log.status === "new").length;
  const inProgressCount = errorLogs?.filter(
    (log) => log.status === "in progress"
  ).length;
  const resolvedCount = errorLogs?.filter(
    (log) => log.status === "resolved"
  ).length;
  const undeterminedCount = errorLogs?.filter(
    (log) => log.priority_level === "undetermined"
  ).length;
  const trivialCount = errorLogs?.filter(
    (log) => log.priority_level === "trivial"
  ).length;
  const lowCount = errorLogs?.filter(
    (log) => log.priority_level === "low"
  ).length;
  const mediumCount = errorLogs?.filter(
    (log) => log.priority_level === "medium"
  ).length;
  const highCount = errorLogs?.filter(
    (log) => log.priority_level === "high"
  ).length;
  const criticalCount = errorLogs?.filter(
    (log) => log.priority_level === "critical"
  ).length;

  return (
    <Container>
      <Row className="my-3">
        <Col md={3}>
          <h2>User Summary</h2>
          {adminLoading ? (
            <Loading />
          ) : adminError ? (
            <ErrorMessage variant="danger">{adminError}</ErrorMessage>
          ) : (
            <>
              <div>
                <Badge style={{ width: "100%" }} bg="dark">
                  Total Users: {stats?.totalUsers}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="primary">
                  Driver Profiles: {stats?.totalDrivers}
                </Badge>
              </div>
              <h2 style={{ marginTop: "5px" }}>Access Level</h2>
              <div>
                <Badge style={{ width: "100%" }} bg="secondary">
                  Admin: {stats?.adminUsers}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="info">
                  Shadow: {stats?.shadowUsers}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="success">
                  Premium: {stats?.premiumUsers}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="warning">
                  Beta: {stats?.betaAccessUsers}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="danger">
                  None: {stats?.noneAccessUsers}
                </Badge>
              </div>
            </>
          )}
        </Col>
        <Col md={3}>
          <h2>Premium Summary</h2>
          {adminLoading ? (
            <Loading />
          ) : adminError ? (
            <ErrorMessage variant="danger">{adminError}</ErrorMessage>
          ) : (
            <>
              <div>
                <Badge style={{ width: "100%" }} bg="dark">
                  Non-Admin: {stats?.totalUsers - stats?.adminUsers}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="secondary">
                  Premium: {stats?.premiumUsers}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="primary">
                  Premium %:{" "}
                  {parseFloat(
                    (stats?.premiumUsers /
                      (stats?.totalUsers - stats?.adminUsers)) *
                      100
                  ).toFixed(2)}
                  %
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="success">
                  Auto Renew: {stats?.subcriptionsToRenew}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="info">
                  Est. Monthly: ${stats?.subcriptionsToRenew * 5.0}
                </Badge>
              </div>
            </>
          )}
        </Col>
        <Col md={3}>
          <h2>BETA Summary</h2>
          {betaLoading ? (
            <Loading />
          ) : betaError ? (
            <ErrorMessage variant="danger">{betaError}</ErrorMessage>
          ) : (
            <>
              <div>
                <Badge style={{ width: "100%" }} bg="dark">
                  Total Apps: {totalAppsCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="warning">
                  Pending: {pendingCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="success">
                  Accepted: {acceptedCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="danger">
                  Denied: {deniedCount}
                </Badge>
              </div>
            </>
          )}
        </Col>
        <Col md={3}>
          <h2>Bug Summary</h2>
          {errorLoading ? (
            <Loading />
          ) : errorError ? (
            <ErrorMessage variant="danger">{errorError}</ErrorMessage>
          ) : (
            <>
              <div>
                <Badge style={{ width: "100%" }} bg="dark">
                  Total Error Logs: {totalErrorCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="danger">
                  New: {newErrorCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="warning">
                  In Progress: {inProgressCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="success">
                  Resolved: {resolvedCount}
                </Badge>
              </div>
              <h2 style={{ marginTop: "5px" }}>Priority Level</h2>
              <div>
                <Badge style={{ width: "100%" }} bg="primary">
                  Undetermined: {undeterminedCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="secondary">
                  Trivial: {trivialCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="info">
                  Low: {lowCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="light">
                  Medium: {mediumCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="warning">
                  High: {highCount}
                </Badge>
              </div>
              <div>
                <Badge style={{ width: "100%" }} bg="danger">
                  Critical: {criticalCount}
                </Badge>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AdminInfo;
