import React from "react";
import { Container, Row } from "react-bootstrap";
//import { NavLink } from "react-router-dom";
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";

const VideoTutorials = () => {
  return (
    <Container>
      <Row>
        <div className="spacing">
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>Video Tutorials</h2>
          <span className="important">Knowledge Base Coming Soon!</span>
        </div>
      </Row>
    </Container>
  );
};

export default VideoTutorials;
