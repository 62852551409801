export const LEAGUES_LIST_REQUEST = "LEAGUES_LIST_REQUEST";
export const LEAGUES_LIST_SUCCESS = "LEAGUES_LIST_SUCCESS";
export const LEAGUES_LIST_FAIL = "LEAGUES_LIST_FAIL";

export const LEAGUES_CREATE_REQUEST = "LEAGUES_CREATE_REQUEST";
export const LEAGUES_CREATE_SUCCESS = "LEAGUES_CREATE_SUCCESS";
export const LEAGUES_CREATE_FAIL = "LEAGUES_CREATE_FAIL";

export const LEAGUES_UPDATE_REQUEST = "LEAGUES_UPDATE_REQUEST";
export const LEAGUES_UPDATE_SUCCESS = "LEAGUES_UPDATE_SUCCESS";
export const LEAGUES_UPDATE_FAIL = "LEAGUES_UPDATE_FAIL";

export const LEAGUES_DELETE_REQUEST = "LEAGUES_DELETE_REQUEST";
export const LEAGUES_DELETE_SUCCESS = "LEAGUES_DELETE_SUCCESS";
export const LEAGUES_DELETE_FAIL = "LEAGUES_DELETE_FAIL";
