import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Beta from "../Images/admin/beta.svg";
import Bug from "../Images/admin/bug.svg";
import Dollar from "../Images/admin/dollar.svg";
import Info from "../Images/admin/info.svg";
import Settings from "../Images/admin/settings.svg";
import Users from "../Images/admin/users.svg";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import "./AdminNav.scss";

const AdminNav = () => {
  const [activeLogo, setActiveLogo] = useState(null);

  const handleMouseEnter = (logo) => {
    setActiveLogo(logo);
  };

  const handleMouseLeave = () => {
    setActiveLogo(null);
  };

  return (
    <Col
      className="my-2 d-flex justify-content-center gradient-background"
      style={{
        borderRadius: "10px",
        padding: "10px",
      }}
    >
      <Tooltip title="Home / Info" position="top" arrow={false} distance={12}>
        <LinkContainer
          to="/admin/info"
          className={`logo-link mx-4 ${activeLogo === "info" ? "active" : ""}`}
          onMouseEnter={() => handleMouseEnter("info")}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={Info}
            alt="Information"
            height="40"
            className={`logo-img ${activeLogo === "info" ? "active" : ""}`}
          />
        </LinkContainer>
      </Tooltip>

      <Tooltip
        title="Beta Applications"
        position="top"
        arrow={false}
        distance={12}
      >
        <LinkContainer
          to="/admin/beta"
          className={`logo-link mx-4 ${activeLogo === "beta" ? "active" : ""}`}
          onMouseEnter={() => handleMouseEnter("beta")}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={Beta}
            alt="Beta"
            height="40"
            className={`logo-img ${activeLogo === "beta" ? "active" : ""}`}
          />
        </LinkContainer>
      </Tooltip>

      <Tooltip title="Bug Reports" position="top" arrow={false} distance={12}>
        <LinkContainer
          to="/admin/bug-reports"
          className={`logo-link mx-4 ${activeLogo === "bugs" ? "active" : ""}`}
          onMouseEnter={() => handleMouseEnter("bugs")}
          onMouseLeave={handleMouseLeave}
        >
          <img
            style={{
              transition: "all 0.2s linear",
              transform:
                activeLogo === "bugs" ? "translateY(-2px)" : "translateY(0px)",
            }}
            src={Bug}
            alt="bugs"
            height="40"
            className={`logo-img ${activeLogo === "bugs" ? "active" : ""}`}
          />
        </LinkContainer>
      </Tooltip>

      <Tooltip title="Setups" position="top" arrow={false} distance={12}>
        <LinkContainer
          to="/admin/setups"
          className={`logo-link mx-4 ${
            activeLogo === "setups" ? "active" : ""
          }`}
          onMouseEnter={() => handleMouseEnter("setups")}
          onMouseLeave={handleMouseLeave}
        >
          <img
            style={{
              transition: "all 0.2s linear",
              transform:
                activeLogo === "setups"
                  ? "translateY(-2px)"
                  : "translateY(0px)",
            }}
            src={Settings}
            alt="Setups"
            height="40"
            className={`logo-img ${activeLogo === "setups" ? "active" : ""}`}
          />
        </LinkContainer>
      </Tooltip>

      <Tooltip title="Subscribers" position="top" arrow={false} distance={12}>
        <LinkContainer
          to="/admin/subs"
          className={`logo-link mx-4 ${activeLogo === "subs" ? "active" : ""}`}
          onMouseEnter={() => handleMouseEnter("subs")}
          onMouseLeave={handleMouseLeave}
        >
          <img
            style={{
              transition: "all 0.2s linear",
              transform:
                activeLogo === "subs" ? "translateY(-2px)" : "translateY(0px)",
            }}
            src={Dollar}
            alt="Subscribers"
            height="40"
            className={`logo-img ${activeLogo === "subs" ? "active" : ""}`}
          />
        </LinkContainer>
      </Tooltip>

      <Tooltip title="Users" position="top" arrow={false} distance={12}>
        <LinkContainer
          to="/admin/users"
          className={`logo-link mx-4 ${activeLogo === "users" ? "active" : ""}`}
          onMouseEnter={() => handleMouseEnter("users")}
          onMouseLeave={handleMouseLeave}
        >
          <img
            style={{
              transition: "all 0.2s linear",
              transform:
                activeLogo === "users" ? "translateY(-2px)" : "translateY(0px)",
            }}
            src={Users}
            alt="Users"
            height="40"
            className={`logo-img ${activeLogo === "users" ? "active" : ""}`}
          />
        </LinkContainer>
      </Tooltip>
    </Col>
  );
};

export default AdminNav;
