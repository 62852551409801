import React, { Fragment } from "react";
import style from "./LeagueManager.module.scss";
import gStyle from "../../../SCSS/global.module.scss";

const LeagueManagerHome = () => {
  return (
    <Fragment>
      <section className={style.hero}>
        <div className={`${style.heroContent} ${gStyle.roundedBox}`}>
          <h1>League Manager Coming Soon</h1>
        </div>
      </section>
    </Fragment>
  );
};

export default LeagueManagerHome;
