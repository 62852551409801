import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, ButtonGroup } from "react-bootstrap";
import { DateTime } from "luxon";
import ErrorMessage from "../../../Components/ErrorMessage";
import Loading from "../../../Components/Loading";
import MainScreen from "../../../Components/MainScreen";
import {
  updateDriver,
  clearDriverUpdateError,
} from "../../../actions/driverActions";
import {
  getUserDriverDetails,
  verifyPassword,
} from "../../../actions/userActions";
import { NATIONALITIES } from "../../../Components/Lists";
import ProfileNav from "../../../Components/ProfileNav";
import "./DriverScreen.scss";

function EditDriver({ match }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [verifyPasswordState, setVerifyPassword] = useState("");
  const [driverId, setDriverId] = useState("");
  const [driverFirstName, setDriverFirstName] = useState("");
  const [driverLastName, setDriverLastName] = useState("");
  const [driverAbbr, setDriverAbbr] = useState("");
  const [eaID, setEaID] = useState("");
  const [discordName, setDiscordName] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [favoriteTeam, setFavoriteTeam] = useState("");
  const [driverBio, setDriverBio] = useState("");
  const [driverDOB, setDriverDOB] = useState(DateTime.local().toISODate());
  const [region, setRegion] = useState("");
  const [nationality, setNationality] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const driverUpdate = useSelector((state) => state.driverUpdate);
  const { loading, error, success } = driverUpdate;

  const userDriverDetails = useSelector((state) => state.userDriverDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    userDriver,
  } = userDriverDetails;

  const [driverSocials, setDriverSocials] = useState({
    website: userDriver?.driverSocials?.website || "",
    youtube: userDriver?.driverSocials?.youtube || "",
    twitch: userDriver?.driverSocials?.twitch || "",
    twitter: userDriver?.driverSocials?.twitter || "",
  });

  const handleSocialChange = (e) => {
    setDriverSocials({ ...driverSocials, [e.target.name]: e.target.value });
  };

  const favoriteTeams = [
    "Alfa Romeo",
    "AlphaTauri",
    "Alpine",
    "Aston Martin",
    "Ferrari",
    "Haas",
    "McLaren",
    "Mercedes",
    "Red Bull",
    "Williams",
    "",
  ];

  const regions = [
    "North America",
    "South America",
    "Europe",
    "Africa",
    "Asia",
    "Oceania",
  ];

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcOffset = DateTime.local().offset / 60;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (!userDriver) {
        dispatch(getUserDriverDetails(userInfo?._id));
      } else {
        setDriverId(userDriver._id);
        setDriverFirstName(userDriver.driverFirstName || "");
        setDriverLastName(userDriver.driverLastName || "");
        setDriverAbbr(userDriver.driverAbbr || "");
        setEaID(userDriver.eaID || "");
        setDiscordName(userDriver.discordName || "");
        setDriverNumber(userDriver.driverNumber || 1);
        setFavoriteTeam(userDriver.favoriteTeam || "");
        setDriverBio(userDriver.driverBio || "");
        setDriverDOB(userDriver.driverDOB || new Date());
        setRegion(userDriver.region || "");
        setNationality(userDriver.nationality || "");

        // Check if driver.driverSocials is not undefined, then set each field
        if (userDriver.driverSocials) {
          setDriverSocials((prevState) => ({
            ...prevState,
            website: userDriver.driverSocials.website || "",
            youtube: userDriver.driverSocials.youtube || "",
            twitch: userDriver.driverSocials.twitch || "",
            twitter: userDriver.driverSocials.twitter || "",
          }));
        }
      }
    }
  }, [navigate, userInfo, success, dispatch, userDriver, driverId]);

  const submitHandler = (e) => {
    e.preventDefault();

    setMessage(null); // Clear messages

    dispatch(verifyPassword(verifyPasswordState))
      .then((isValid) => {
        if (isValid) {
          if (driverId) {
            dispatch(
              updateDriver(driverId, {
                driverFirstName,
                driverLastName,
                driverAbbr,
                eaID,
                discordName,
                driverNumber,
                favoriteTeam,
                driverBio,
                driverDOB,
                region,
                nationality,
                driverSocials,
                timezone,
                utcOffset,
              })
            );
            setTimeout(() => {
              dispatch(clearDriverUpdateError());
              navigate("/profile/driver");
            }, 3000); // Redirect to profile after 3 seconds
          } else {
            console.error("driverId is undefined");
          }
        } else {
          setMessage("Incorect password, try again");
        }
      })
      .catch((error) => {
        setMessage("Incorect password, try again");
        console.error("Password verification error:", error);
      });
  };

  return (
    <MainScreen title="Update Driver Profile">
      <Row className="justify-content-center">
        <Col
          style={{
            top: "0",
            left: "0",
          }}
        >
          <ProfileNav />
          {loadingDetails && <Loading />}
          {errorDetails && (
            <ErrorMessage
              variant="danger"
              onClose={() => dispatch(clearDriverUpdateError())}
            >
              {errorDetails}
            </ErrorMessage>
          )}
        </Col>
      </Row>
      <div className="createContainer">
        <Form className="mb-3" onSubmit={submitHandler}>
          {loading && <Loading />}
          {error && (
            <ErrorMessage
              variant="danger"
              onClose={() => dispatch(clearDriverUpdateError())}
            >
              {error}
            </ErrorMessage>
          )}
          <Form.Group controlId="driverFirstName" className="py-2">
            <Form.Label>Driver First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Driver First Name"
              value={driverFirstName}
              onChange={(e) => setDriverFirstName(e.target.value)}
              minLength={1}
              maxLength={20}
              required
            />
          </Form.Group>

          <Form.Group controlId="driverLastName" className="py-2">
            <Form.Label>
              Driver Last Name {"("}Default Broadcast Name{")"}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Driver Last Name"
              value={driverLastName}
              onChange={(e) => setDriverLastName(e.target.value)}
              minLength={1}
              maxLength={20}
              required
            />
          </Form.Group>

          <Form.Group controlId="driverAbbr" className="py-2">
            <Form.Label>
              Driver Abbreviation {"("}3 Letters{")"}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter 3 Letter Abbr."
              value={driverAbbr}
              onChange={(e) => setDriverAbbr(e.target.value.toUpperCase())} // Convert to uppercase
              minLength={3}
              maxLength={3}
              pattern="[A-Z]*" // This will enforce that only uppercase letters are allowed
              required
            />
          </Form.Group>

          <Form.Group controlId="eaID" className="py-2">
            <Form.Label>EA ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter EA ID"
              value={eaID}
              onChange={(e) => setEaID(e.target.value)}
              minLength={3}
              maxLength={32}
            />
          </Form.Group>

          <Form.Group controlId="discordName" className="py-2">
            <Form.Label>Discord Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Discord Username"
              value={discordName}
              onChange={(e) => setDiscordName(e.target.value)}
              maxLength={32}
            />
          </Form.Group>

          <Form.Group controlId="driverNumber" className="py-2">
            <Form.Label>
              Preferred Driver Number {"("}1-99{")"}
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Preferred Driver Number"
              value={driverNumber}
              onChange={(e) => setDriverNumber(e.target.value)}
              minLength={1}
              maxLength={2}
              min={0}
              max={99}
              required
            />
          </Form.Group>

          <Form.Group controlId="favoriteTeam" className="py-2">
            <Form.Label>Favorite Team</Form.Label>
            <Form.Control
              as="select"
              value={favoriteTeam}
              onChange={(e) => setFavoriteTeam(e.target.value)}
            >
              <option value="">Select...</option>
              {favoriteTeams.map((team, index) => (
                <option key={index} value={team}>
                  {team}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="nationality" className="py-2">
            <Form.Label>Nationality</Form.Label>
            <Form.Control
              as="select"
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
            >
              <option value="">Select...</option>
              {Object.entries(NATIONALITIES).map(([code, country], index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="region" className="py-2">
            <Form.Label>Region</Form.Label>
            <Form.Control
              as="select"
              required
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            >
              <option value="">Select...</option>
              {regions.map((region, index) => (
                <option key={index} value={region}>
                  {region}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="driverDOB" className="py-2">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              type="date"
              value={driverDOB}
              max={DateTime.local().toISODate()}
              onChange={(e) => setDriverDOB(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="website" className="py-2">
            <Form.Label>Website</Form.Label>
            <Form.Control
              type="text"
              placeholder="Optional Website (Personal or Linktree)"
              name="website"
              value={driverSocials.website}
              onChange={handleSocialChange}
            />
          </Form.Group>

          <Form.Group controlId="youtube" className="py-2">
            <Form.Label>YouTube Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Optional YouTube @Username"
              name="youtube"
              value={driverSocials.youtube}
              onChange={handleSocialChange}
              pattern="^[^\.\/]{3,}$"
              title="Please enter only your YouTube username. Do not include any URLs or .com, .tv, .be etc."
            />
          </Form.Group>

          <Form.Group controlId="twitch" className="py-2">
            <Form.Label>Twitch Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Optional Twitch Username"
              name="twitch"
              value={driverSocials.twitch}
              onChange={handleSocialChange}
              pattern="^[^\.\/]{3,}$"
              title="Please enter only your Twitch username. Do not include any URLs or .com, .tv, .be etc."
            />
          </Form.Group>

          <Form.Group controlId="twitter" className="py-2">
            <Form.Label>Twitter Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Optional Twitter Username"
              name="twitter"
              value={driverSocials.twitter}
              onChange={handleSocialChange}
              pattern="^[^\.\/]{1,15}$"
              title="Please enter only your Twitter username. Do not include any URLs or .com, .tv, .be etc."
            />
          </Form.Group>

          <Form.Group controlId="driverBio" className="py-2">
            <Form.Label>Driver Bio</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Tell us about yourself, or don't, it's up to you!"
              value={driverBio}
              onChange={(e) => setDriverBio(e.target.value)}
              maxLength={3000}
            />
          </Form.Group>
          <Form.Group controlId="verifyPassword" className="py-2">
            <Form.Label>Verify Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password to verify"
              value={verifyPasswordState}
              onChange={(e) => setVerifyPassword(e.target.value)}
              required
            />
          </Form.Group>
          <ButtonGroup>
            <Button variant="success" type="submit">
              Update Driver Profile
            </Button>
            <Button
              variant="danger"
              onClick={() => navigate("/profile/driver")}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Form>
        {message && <ErrorMessage variant="danger">{message}</ErrorMessage>}
        {success && (
          <ErrorMessage variant="success">
            Driver has been updated successfully! Redirecting to Driver
            profile...
          </ErrorMessage>
        )}
      </div>
    </MainScreen>
  );
}

export default EditDriver;
