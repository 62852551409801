import {
  ADMIN_STATS_REQUEST,
  ADMIN_STATS_SUCCESS,
  ADMIN_STATS_FAIL,
  ADMIN_USERS_LIST_REQUEST,
  ADMIN_USERS_LIST_SUCCESS,
  ADMIN_USERS_LIST_FAIL,
  ADMIN_USER_DELETE_REQUEST,
  ADMIN_USER_DELETE_SUCCESS,
  ADMIN_USER_DELETE_FAIL,
  ADMIN_USER_UPDATE_ACCESS_REQUEST,
  ADMIN_USER_UPDATE_ACCESS_SUCCESS,
  ADMIN_USER_UPDATE_ACCESS_FAIL,
} from "../constants/adminConstants";

export const adminStatsReducer = (state = { stats: {} }, action) => {
  switch (action.type) {
    case ADMIN_STATS_REQUEST:
      return { loading: true };
    case ADMIN_STATS_SUCCESS:
      return { loading: false, stats: action.payload };
    case ADMIN_STATS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminUsersListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case ADMIN_USERS_LIST_REQUEST:
      return { loading: true };
    case ADMIN_USERS_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case ADMIN_USERS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminUserDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_USER_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminUserUpdateAccessReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case ADMIN_USER_UPDATE_ACCESS_REQUEST:
      return { loading: true };
    case ADMIN_USER_UPDATE_ACCESS_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case ADMIN_USER_UPDATE_ACCESS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
