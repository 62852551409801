import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import MainScreen from "../../Components/MainScreen";
import "./Admin.scss";
import gStyle from "../../SCSS/global.module.scss";
import AdminNav from "../../Components/AdminNav";

const AdminWrapper = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { userAccess, loading } = useAuth();
  const { isAdmin } = userAccess;

  if (!loading && !isAdmin) {
    return <Navigate to="/" />;
  }

  return (
    <MainScreen title="Admin Panel">
      <Container>
        <Row className="justify-content-center">
          <div className={`${gStyle.red} ${gStyle.redTs}`}>
            {userInfo?.username}, this is the Admin area... don't fuck anything
            up.
          </div>
          <Col
            style={{
              top: "0",
              left: "0",
            }}
          >
            <AdminNav />
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <main>
            <Outlet /> {/* Main content */}
          </main>
        </Row>
      </Container>
    </MainScreen>
  );
};

export default AdminWrapper;
