import React, { useEffect, useState } from "react";
import { Button, Col, Card, Row, ButtonGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails, resetUserDetails } from "../../actions/userActions";
import {
  sendFriendRequest,
  denyFriendRequest,
  resetFriendRequest,
  getProfileUserFriends,
  getLoggedInUserFriends,
  acceptFriendRequest,
  checkFriendRequestSent,
  checkFriendRequestReceived,
  getFriendshipId,
  getFriends,
} from "../../actions/friendshipActions";
import { getDriverDetails } from "../../actions/driverActions";
import ErrorMessage from "../../Components/ErrorMessage";
import Loading from "../../Components/Loading";
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import MainScreen from "../../Components/MainScreen";
import ProfileNav from "../../Components/ProfileNav";
import gStyle from "../../SCSS/global.module.scss";
import DriverSocialNav from "../../Components/DriverSocialNav";
import Driver from "../../Images/account_logos/racing_helmet.png";
import Flag from "react-world-flags";
import { NATIONALITIES } from "../../Components/Lists";
import WorldFlag from "../../Images/flags/worldflag.png";

const UserScreen = () => {
  // Initialize State
  const [driverFirstName, setDriverFirstName] = useState("");
  const [driverLastName, setDriverLastName] = useState("");
  const [driverAbbr, setDriverAbbr] = useState("");
  const [eaID, setEaID] = useState("");
  const [discordName, setDiscordName] = useState("");
  const [driverNumber, setDriverNumber] = useState(1);
  const [favoriteTeam, setFavoriteTeam] = useState("");
  const [website, setWebsite] = useState("");
  const [youtube, setYoutube] = useState("");
  const [twitch, setTwitch] = useState("");
  const [twitter, setTwitter] = useState("");
  const [driverBio, setDriverBio] = useState("");
  const [driverDOB, setDriverDOB] = useState(new Date());
  const [region, setRegion] = useState("");
  const [nationality, setNationality] = useState("");
  const [timezone, setTimezone] = useState("");

  const [websiteURL, setWebsiteURL] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  const [twitchURL, setTwitchURL] = useState("");
  const [twitterURL, setTwitterURL] = useState("");

  const { id: profileUserId } = useParams();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const profileUserFriends = useSelector((state) => state.profileUserFriends);
  const loggedInUserFriends = useSelector((state) => state.loggedInUserFriends);

  const [mutualFriends, setMutualFriends] = useState([]);
  const [friendshipStatus, setFriendshipStatus] = useState("");

  const friendsList = useSelector((state) => state.friendsList);

  const driverDetails = useSelector((state) => state.driverDetails);
  const { driver } = driverDetails;

  const [userDriverExists, setUserDriverExists] = useState(false);

  const navigate = useNavigate();

  const hasSentRequest = useSelector(
    (state) => state.checkFriendRequestSent.hasSentRequest
  );

  const hasReceivedRequest = useSelector(
    (state) => state.checkFriendRequestReceived.hasReceivedRequest
  );

  const flipObject = (object) => {
    return Object.keys(object).reduce((flippedObject, key) => {
      flippedObject[object[key]] = key;
      return flippedObject;
    }, {});
  };

  const REVERSED_NATIONALITIES = flipObject(NATIONALITIES);

  // calc age from DOB
  const getAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--; // Adjust age if the birth date has not occurred this year.
    }

    return age;
  };

  // check and format user URLs
  const checkUrlFormat = (url) => {
    let pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i" // fragment locator
    );
    return pattern.test(url);
  };

  const formatUrl = (url) => {
    if (!url) return "";
    if (url.startsWith("https://www.")) {
      return url;
    } else if (url.startsWith("www.")) {
      return "https://" + url;
    } else {
      return "https:" + url;
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserDetails(profileUserId));
      dispatch(getDriverDetails(profileUserId));
      dispatch(getProfileUserFriends(profileUserId));
      dispatch(getLoggedInUserFriends(userInfo?._id));
      dispatch(checkFriendRequestSent(userInfo?._id, profileUserId));
      dispatch(checkFriendRequestReceived(userInfo?._id, profileUserId));
      dispatch(getFriends(userInfo?._id));
    }
    return () => {
      dispatch(resetUserDetails());
      dispatch(resetFriendRequest());
    };
  }, [dispatch, profileUserId, userInfo]);

  useEffect(() => {
    if (driver && profileUserId && driver?.user === profileUserId) {
      setUserDriverExists(true);
    } else {
      setUserDriverExists(false);
    }

    if (driver) {
      setDriverFirstName(driver?.driverFirstName || "");
      setDriverLastName(driver?.driverLastName || "");
      setDriverAbbr(driver?.driverAbbr || "");
      setEaID(driver?.eaID || "");
      setDiscordName(driver?.discordName || "");
      setDriverNumber(driver?.driverNumber || 1);
      setFavoriteTeam(driver?.favoriteTeam || "");

      let socials = driver?.driverSocials || {};

      setWebsite(socials.website || "");
      setYoutube(socials.youtube || "");
      setTwitch(socials.twitch || "");
      setTwitter(socials.twitter || "");

      setDriverBio(driver?.driverBio || "");
      setDriverDOB(driver?.driverDOB || new Date());
      setRegion(driver?.region || "");
      setNationality(driver?.nationality || "");
      setTimezone(driver?.timezone || "");

      let siteUrl = website;
      if (siteUrl && siteUrl?.length >= 3) {
        if (!checkUrlFormat(siteUrl)) {
          siteUrl = formatUrl(siteUrl);
        }
      } else {
        siteUrl = "";
      }
      setWebsiteURL(siteUrl);

      let youtubeUsername = youtube;
      let ytUrl = "";
      if (youtubeUsername && youtubeUsername?.length >= 3) {
        if (!youtubeUsername.startsWith("@")) {
          youtubeUsername = "@" + youtubeUsername;
        }
        ytUrl = "https://www.youtube.com/" + youtubeUsername;
      } else {
        ytUrl = "";
      }
      setYoutubeURL(ytUrl);

      let twitchUsername = twitch;
      let twitchUrl = "";
      if (twitchUsername && twitchUsername?.length >= 3) {
        twitchUrl = "https://www.twitch.tv/" + twitchUsername;
      } else {
        twitchUrl = "";
      }
      setTwitchURL(twitchUrl);

      let twitterUsername = twitter;
      let twtUrl = "";
      if (twitterUsername && twitterUsername?.length >= 3) {
        twtUrl = "https://www.twitter.com/" + twitterUsername;
      } else {
        twtUrl = "";
      }
      setTwitterURL(twtUrl);
    }
  }, [
    dispatch,
    userInfo,
    driver,
    twitch,
    twitter,
    website,
    youtube,
    profileUserId,
  ]);

  useEffect(() => {
    if (
      !profileUserFriends.loading &&
      !loggedInUserFriends.loading &&
      profileUserFriends.friends?.length > 0
    ) {
      const profileUserFriendsSet = new Set(
        profileUserFriends.friends.map((friend) =>
          friend.user1._id === profileUserId
            ? friend.user2._id
            : friend.user1._id
        )
      );
      const mutuals = loggedInUserFriends.friends.filter((friend) =>
        profileUserFriendsSet.has(
          friend.user1._id === userInfo?._id
            ? friend.user2._id
            : friend.user1._id
        )
      );
      const sortedMutualFriends = mutuals.sort((a, b) =>
        a.user2.username.localeCompare(b.user2.username)
      );
      setMutualFriends(sortedMutualFriends);
    } else {
      setMutualFriends([]); // Reset mutualFriends when there are no profileUserFriends
    }

    const checkFriendship = friendsList.friends?.some(
      (friendship) =>
        (friendship.user1._id === userInfo?._id &&
          friendship.user2._id === profileUserId) ||
        (friendship.user1._id === profileUserId &&
          friendship.user2._id === userInfo?._id)
    );

    if (userInfo._id === profileUserId) {
      navigate("/profile/friends");
    } else if (checkFriendship) {
      // userId1 and userId2 are friends
      setFriendshipStatus("friends");
    } else if (hasReceivedRequest) {
      setFriendshipStatus("pendingRequestSent");
    } else if (hasSentRequest) {
      setFriendshipStatus("pendingRequestReceived");
    } else {
      setFriendshipStatus("");
    }
  }, [
    profileUserFriends,
    loggedInUserFriends,
    profileUserId,
    userInfo,
    hasSentRequest,
    hasReceivedRequest,
    friendsList,
    navigate,
  ]);

  const sendRequestHandler = async () => {
    try {
      await dispatch(sendFriendRequest(profileUserId));
      dispatch(checkFriendRequestSent(userInfo?._id, profileUserId));
      dispatch(checkFriendRequestReceived(userInfo?._id, profileUserId));
    } catch (error) {
      console.error(error);
    }
  };

  const returnHandler = () => {
    navigate("/profile/friends");
  };

  const acceptRequestHandler = async () => {
    try {
      const requestId = await getRequestId();
      await dispatch(acceptFriendRequest(requestId));
      dispatch(getFriends(userInfo?._id));
    } catch (error) {
      console.error(error);
    }
  };

  const denyRequestHandler = async () => {
    try {
      const requestId = await getRequestId();
      await dispatch(denyFriendRequest(requestId));
      dispatch(checkFriendRequestSent(userInfo._id, profileUserId));
      dispatch(checkFriendRequestReceived(userInfo._id, profileUserId));
    } catch (error) {
      console.error(error);
    }
  };

  const revokeRequestHandler = async () => {
    try {
      const requestId = await getRequestId2();
      await dispatch(denyFriendRequest(requestId));
      dispatch(checkFriendRequestSent(userInfo?._id, profileUserId));
      dispatch(checkFriendRequestReceived(userInfo?._id, profileUserId));
    } catch (error) {
      console.error(error);
    }
  };

  const unfriendHandler = async () => {
    try {
      const requestId = await getRequestId();
      await dispatch(denyFriendRequest(requestId));
      dispatch(getFriends(userInfo?._id));
    } catch (error) {
      console.error(error);
    }
  };

  const getRequestId = async () => {
    try {
      const friendshipId = await dispatch(
        await getFriendshipId(profileUserId, userInfo?._id)
      );
      return friendshipId;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getRequestId2 = async () => {
    try {
      const friendshipId = await dispatch(
        await getFriendshipId(userInfo?._id, profileUserId)
      );
      return friendshipId;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return (
    <MainScreen title={`${user?.username}'s Profile`}>
      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorMessage
          variant="danger"
          onClose={() => dispatch(resetUserDetails())}
        >
          {error}
        </ErrorMessage>
      ) : (
        <>
          <Row className="justify-content-center">
            <Col
              style={{
                top: "0",
                left: "0",
              }}
            >
              <ProfileNav />
            </Col>
          </Row>
          <Row className="justify-content-center mb-3">
            <Col
              className="right justify-content-center my-1"
              style={{
                minWidth: "300px",
              }}
            >
              <Card className="mb-3">
                <Card.Header
                  className={`${gStyle.cyan} ${gStyle.cyanTs} ${gStyle.smdText}`}
                >
                  Friendship Status
                </Card.Header>
                <Card.Body>
                  {friendshipStatus === "pendingRequestSent" && (
                    <div style={{ textAlign: "left" }}>
                      <p>Your Friend Request with {user.username} is pending</p>
                      <ButtonGroup className="d-flex w-100">
                        <Button
                          variant="danger"
                          className="rounded-button"
                          onClick={revokeRequestHandler}
                        >
                          Revoke Request
                        </Button>
                        <Button
                          className="rounded-button"
                          onClick={returnHandler}
                        >
                          Return
                        </Button>
                      </ButtonGroup>
                    </div>
                  )}
                  {friendshipStatus === "pendingRequestReceived" && (
                    <div style={{ textAlign: "left" }}>
                      <p>{user.username} would like to be your friend</p>
                      <ButtonGroup className="d-flex w-100">
                        <Button
                          variant="success"
                          className="rounded-button"
                          onClick={acceptRequestHandler}
                        >
                          Accept Request
                        </Button>
                        <Button variant="danger" onClick={denyRequestHandler}>
                          Deny
                        </Button>
                        <Button
                          className="rounded-button"
                          onClick={returnHandler}
                        >
                          Return
                        </Button>
                      </ButtonGroup>
                    </div>
                  )}
                  {friendshipStatus === "friends" && (
                    <div style={{ textAlign: "left" }}>
                      <p>You are friends with {user.username}</p>
                      <ButtonGroup className="d-flex w-100">
                        <Button
                          className="rounded-button"
                          variant="danger"
                          onClick={unfriendHandler}
                        >
                          Unfriend
                        </Button>
                        <Button
                          className="rounded-button"
                          onClick={returnHandler}
                        >
                          Return
                        </Button>
                      </ButtonGroup>
                    </div>
                  )}
                  {friendshipStatus === "" && (
                    <div style={{ textAlign: "left" }}>
                      <p>You are not yet friends with {user.username}</p>
                      <ButtonGroup className="d-flex w-100">
                        <Button
                          className="rounded-button"
                          variant="success"
                          onClick={sendRequestHandler}
                        >
                          Send Friend Request
                        </Button>
                        <Button
                          className="rounded-button"
                          onClick={returnHandler}
                        >
                          Return
                        </Button>
                      </ButtonGroup>
                    </div>
                  )}
                  <Card className="mt-5">
                    <Card.Header
                      className={`${gStyle.white} ${gStyle.whiteTs} ${gStyle.smdText}`}
                      style={{
                        background: "linear-gradient(90deg, #0085c2, #00afff)",
                        borderRadius: "10px 10px 0 0",
                      }}
                    >
                      Mutual Friends
                      {mutualFriends &&
                        mutualFriends?.length > 0 &&
                        ` (${mutualFriends?.length})`}
                    </Card.Header>
                    <Card.Body
                      className="gradient-background-list-content"
                      style={{
                        borderRadius: "0 0 10px 10px",
                      }}
                    >
                      {profileUserFriends.loading ||
                      loggedInUserFriends.loading ? (
                        <div>Loading...</div>
                      ) : profileUserFriends.error ||
                        loggedInUserFriends.error ? (
                        <div>Error loading friends</div>
                      ) : mutualFriends?.length > 0 ? (
                        mutualFriends.map((friend) => (
                          <div
                            key={friend._id}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "2px",
                            }}
                          >
                            <LinkContainer
                              to={`/user/${
                                friend.user1._id === userInfo?._id
                                  ? friend.user2._id
                                  : friend.user1._id
                              }`}
                            >
                              <Button variant="link">
                                <img
                                  src={
                                    friend.user1._id === userInfo?._id
                                      ? friend.user2.pic
                                      : friend.user1.pic
                                  }
                                  alt="Profile"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    marginRight: "10px",
                                  }}
                                />
                                {friend.user1._id === userInfo?._id
                                  ? friend.user2.username
                                  : friend.user1.username}
                              </Button>
                            </LinkContainer>
                          </div>
                        ))
                      ) : (
                        <div>No mutual friends</div>
                      )}
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Card>
            </Col>

            <Col
              className="my-1"
              style={{
                minWidth: "350px",
                top: "0",
                left: "0",
              }}
            >
              {userDriverExists ? (
                <Card>
                  <Card.Header
                    className={`${gStyle.magenta} ${gStyle.magentaTs} ${gStyle.smdText}`}
                  >
                    {user.username}
                  </Card.Header>
                  <Card.Body>
                    <div
                      style={{ marginBottom: "8px" }}
                      className={`detail_label ${gStyle.cyan} ${gStyle.cyanTs} ${gStyle.mdText} `}
                    >
                      <img
                        src={Driver}
                        alt={driverFirstName}
                        height="26"
                        style={{ paddingRight: "12px" }}
                      />
                      {driverFirstName}
                      <span> </span>
                      {driverLastName}
                    </div>
                    <div
                      style={{ marginBottom: "15px" }}
                      className={`detail_label ${gStyle.magenta} ${gStyle.magentaTs} ${gStyle.mdText}`}
                    >
                      {REVERSED_NATIONALITIES[nationality] ? (
                        <Flag
                          code={REVERSED_NATIONALITIES[nationality]}
                          height="26"
                          style={{ paddingRight: "20px" }}
                        />
                      ) : (
                        <img
                          src={WorldFlag}
                          alt="world flag"
                          height="26"
                          style={{ paddingRight: "20px" }}
                        />
                      )}
                      [{driverAbbr}]
                      <span style={{ paddingLeft: "20px" }}>
                        {driverNumber}
                      </span>
                    </div>
                    <div
                      style={{
                        width: "256px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        marginBottom: "15px",
                      }}
                    >
                      <img
                        src={user.pic}
                        alt={user.username}
                        style={{ borderRadius: "15px", maxWidth: "256px" }}
                      />
                      <DriverSocialNav
                        web={websiteURL}
                        youtube={youtubeURL}
                        twitch={twitchURL}
                        twitter={twitterURL}
                      />
                    </div>
                    <p>
                      EA ID:{" "}
                      <span className={`detail_label ${gStyle.smdText}`}>
                        {" "}
                        {eaID}
                      </span>
                    </p>
                    {discordName && discordName?.length > 1 && (
                      <p>
                        Discord:{" "}
                        <span className={`detail_label ${gStyle.smdText}`}>
                          {discordName}
                        </span>
                      </p>
                    )}
                    {favoriteTeam && favoriteTeam?.length > 1 && (
                      <p>
                        Favorite Team:{" "}
                        <span className={`detail_label ${gStyle.smdText}`}>
                          {favoriteTeam}
                        </span>
                      </p>
                    )}
                    {driverDOB && getAge(driverDOB) >= 14 && (
                      <p>
                        Driver Age:{" "}
                        <span className={`detail_label ${gStyle.smdText}`}>
                          {getAge(driverDOB)}
                        </span>
                      </p>
                    )}
                    {timezone && timezone?.length >= 1 && (
                      <p>
                        Timezone:{" "}
                        <span className={`detail_label ${gStyle.smdText}`}>
                          {timezone.replace(/_/g, " ").replace(/\//g, " / ")}
                        </span>
                      </p>
                    )}
                    {region && region?.length >= 1 && (
                      <p>
                        Region:{" "}
                        <span className={`detail_label ${gStyle.smdText}`}>
                          {region}
                        </span>
                      </p>
                    )}
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Header
                    className={`${gStyle.magenta} ${gStyle.magentaTs} ${gStyle.smdText}`}
                  >
                    {user.username}
                  </Card.Header>
                  <Card.Body>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p>{user.username} does not have a driver profile yet.</p>
                      <img
                        src={user.pic}
                        alt={user.username}
                        style={{ borderRadius: "15px", maxWidth: "256px" }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              )}
              {userDriverExists && (
                <Card className="mb-3">
                  <Card.Header
                    className={`${gStyle.cyan} ${gStyle.cyanTs} ${gStyle.smdText}`}
                  >
                    Driver Bio
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>{driverBio}</Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </>
      )}
    </MainScreen>
  );
};

export default UserScreen;
