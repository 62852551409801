export const SETUP_CREATE_REQUEST = "SETUP_CREATE_REQUEST";
export const SETUP_CREATE_SUCCESS = "SETUP_CREATE_SUCCESS";
export const SETUP_CREATE_FAIL = "SETUP_CREATE_FAIL";

export const SETUP_GET_REQUEST = "SETUP_GET_REQUEST";
export const SETUP_GET_SUCCESS = "SETUP_GET_SUCCESS";
export const SETUP_GET_FAIL = "SETUP_GET_FAIL";

export const SETUP_GET_ALL_REQUEST = "SETUP_GET_ALL_REQUEST";
export const SETUP_GET_ALL_SUCCESS = "SETUP_GET_ALL_SUCCESS";
export const SETUP_GET_ALL_FAIL = "SETUP_GET_ALL_FAIL";

export const SETUP_GET_USER_ALL_REQUEST = "SETUP_GET_USER_ALL_REQUEST";
export const SETUP_GET_USER_ALL_SUCCESS = "SETUP_GET_USER_ALL_SUCCESS";
export const SETUP_GET_USER_ALL_FAIL = "SETUP_GET_USER_ALL_FAIL";

export const SETUP_UPDATE_REQUEST = "SETUP_UPDATE_REQUEST";
export const SETUP_UPDATE_SUCCESS = "SETUP_UPDATE_SUCCESS";
export const SETUP_UPDATE_FAIL = "SETUP_UPDATE_FAIL";

export const SETUP_DELETE_REQUEST = "SETUP_DELETE_REQUEST";
export const SETUP_DELETE_SUCCESS = "SETUP_DELETE_SUCCESS";
export const SETUP_DELETE_FAIL = "SETUP_DELETE_FAIL";
