import {
  SETUP_CREATE_REQUEST,
  SETUP_CREATE_SUCCESS,
  SETUP_CREATE_FAIL,
  SETUP_GET_REQUEST,
  SETUP_GET_SUCCESS,
  SETUP_GET_FAIL,
  SETUP_GET_ALL_REQUEST,
  SETUP_GET_ALL_SUCCESS,
  SETUP_GET_ALL_FAIL,
  SETUP_GET_USER_ALL_REQUEST,
  SETUP_GET_USER_ALL_SUCCESS,
  SETUP_GET_USER_ALL_FAIL,
  SETUP_UPDATE_REQUEST,
  SETUP_UPDATE_SUCCESS,
  SETUP_UPDATE_FAIL,
  SETUP_DELETE_REQUEST,
  SETUP_DELETE_SUCCESS,
  SETUP_DELETE_FAIL,
} from "../constants/setupConstants";

export const setupCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SETUP_CREATE_REQUEST:
      return { loading: true };
    case SETUP_CREATE_SUCCESS:
      return { loading: false, success: true, setup: action.payload };
    case SETUP_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const setupGetReducer = (state = { setup: {} }, action) => {
  switch (action.type) {
    case SETUP_GET_REQUEST:
      return { ...state, loading: true };
    case SETUP_GET_SUCCESS:
      return { loading: false, setup: action.payload };
    case SETUP_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const setupGetAllReducer = (state = { setups: [] }, action) => {
  switch (action.type) {
    case SETUP_GET_ALL_REQUEST:
      return { ...state, loading: true };
    case SETUP_GET_ALL_SUCCESS:
      return { loading: false, setups: action.payload };
    case SETUP_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const setupGetUserAllReducer = (state = { setups: [] }, action) => {
  switch (action.type) {
    case SETUP_GET_USER_ALL_REQUEST:
      return { ...state, loading: true };
    case SETUP_GET_USER_ALL_SUCCESS:
      return { loading: false, setups: action.payload };
    case SETUP_GET_USER_ALL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const setupUpdateReducer = (state = { setup: {} }, action) => {
  switch (action.type) {
    case SETUP_UPDATE_REQUEST:
      return { loading: true };
    case SETUP_UPDATE_SUCCESS:
      return { loading: false, success: true, setup: action.payload };
    case SETUP_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const setupDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SETUP_DELETE_REQUEST:
      return { loading: true };
    case SETUP_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SETUP_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
