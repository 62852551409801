import axios from "axios";
import { API_BASE_URL } from "../config";
import {
  ERROR_LOG_CREATE_REQUEST,
  ERROR_LOG_CREATE_SUCCESS,
  ERROR_LOG_CREATE_FAIL,
  ERROR_LOG_DETAILS_REQUEST,
  ERROR_LOG_DETAILS_SUCCESS,
  ERROR_LOG_DETAILS_FAIL,
  ERROR_LOG_UPDATE_REQUEST,
  ERROR_LOG_UPDATE_SUCCESS,
  ERROR_LOG_UPDATE_FAIL,
  ERROR_LOG_DELETE_REQUEST,
  ERROR_LOG_DELETE_SUCCESS,
  ERROR_LOG_DELETE_FAIL,
  ERROR_LOG_LIST_REQUEST,
  ERROR_LOG_LIST_SUCCESS,
  ERROR_LOG_LIST_FAIL,
  ERROR_LOG_LIST_USER_REQUEST,
  ERROR_LOG_LIST_USER_SUCCESS,
  ERROR_LOG_LIST_USER_FAIL,
} from "../constants/errorLogConstants";

export const createErrorLog = (errorLog) => async (dispatch, getState) => {
  try {
    dispatch({ type: ERROR_LOG_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${API_BASE_URL}/errorLogs`,
      errorLog,
      config
    );

    dispatch({ type: ERROR_LOG_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ERROR_LOG_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getErrorLogDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ERROR_LOG_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${API_BASE_URL}/errorLogs/${id}`, config);

    dispatch({ type: ERROR_LOG_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ERROR_LOG_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateErrorLog = (errorLog) => async (dispatch, getState) => {
  try {
    dispatch({ type: ERROR_LOG_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${API_BASE_URL}/errorLogs/${errorLog._id}`,
      errorLog,
      config
    );

    dispatch({ type: ERROR_LOG_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ERROR_LOG_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteErrorLog = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ERROR_LOG_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${API_BASE_URL}/errorLogs/${id}`, config);

    dispatch({ type: ERROR_LOG_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: ERROR_LOG_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listErrorLogs = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ERROR_LOG_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${API_BASE_URL}/errorLogs`, config);

    dispatch({ type: ERROR_LOG_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ERROR_LOG_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listErrorLogsUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ERROR_LOG_LIST_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${API_BASE_URL}/errorLogs/user`, config);

    dispatch({ type: ERROR_LOG_LIST_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ERROR_LOG_LIST_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
