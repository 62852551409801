import React from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";

const Terms = () => {
  return (
    <Container>
      <Row>
        <div className="spacing">
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>Terms of Service</h2>
          <span className="important">Last Updated: February 21, 2024</span>
          <br />
          <br />
          <p>
            Welcome to Podium. These Terms of Service, including the End User
            License Agreement {`(`}EULA{`)`}, govern your use of the Podium
            services and software provided by Delta Zero, LLC.
          </p>
          <h6>1. Acceptance of Terms</h6>
          <ul>
            <li>
              By using Podium, you agree to these terms and our Privacy Policy.
            </li>
          </ul>
          <h6>2. License Grant</h6>
          <ul>
            <li>
              We grant you a non-exclusive, non-transferable license to use
              Podium software.
            </li>
          </ul>
          <h6>3. Use Restrictions</h6>
          <ul>
            <li>
              Prohibitions on modifying, copying, or reverse-engineering the
              software.
            </li>
          </ul>
          <h6>4. Termination</h6>
          <ul>
            <li>
              The license may terminate if you breach terms or end your contract
              period.
            </li>
          </ul>
          <h6>5. Disclaimers</h6>
          <ul>
            <li>Software provided "AS IS" without warranties of any kind.</li>
          </ul>
          <h6>6. Limitation of Liability</h6>
          <ul>
            <li>Exclusion of liability for indirect damages.</li>
          </ul>
          <h6>7. Software Ownership</h6>
          <ul>
            <li>
              Retention of all rights and intellectual property in the Software
              by Delta Zero, LLC.
            </li>
          </ul>
          <h6>8. Governing Law</h6>
          <ul>
            <li>
              These terms are governed by [applicable jurisdiction law] without
              regard to its conflict of laws principles.
            </li>
          </ul>
          <h6>9. Amendments</h6>
          <ul>
            <li>We reserve the right to amend these terms at any time.</li>
          </ul>
          <h6>10. Contact Information</h6>
          <ul>
            <li>
              For queries related to these terms, contact{" "}
              <a
                className={gStyle.cyan}
                style={{ textDecoration: "none" }}
                href="mailto:support@podiumtheory.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@podiumtheory.com
              </a>
              .
            </li>
          </ul>
          <br />
          <p>
            By installing or using the Podium software, you are confirming your
            acceptance of these terms and the accompanying{" "}
            <NavLink to="/about/privacy-policy">Privacy Policy</NavLink>.
          </p>
        </div>
      </Row>
    </Container>
  );
};

export default Terms;
