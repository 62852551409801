import React, { useEffect, useState } from "react";
import { Container, Row, Table, Button, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listAllUsers } from "../../../actions/adminActions";
import Loading from "../../../Components/Loading";
import ErrorMessage from "../../../Components/ErrorMessage";
import "../Admin.scss";

const AdminSubscribers = () => {
  const dispatch = useDispatch();
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const [filterSubscriptionActive, setFilterSubscriptionActive] = useState("");
  const [filterAccess, setFilterAccess] = useState("premium");

  const adminUsersList = useSelector((state) => state.adminUsersList);
  const { loading, error, users } = adminUsersList;

  const adminUserDelete = useSelector((state) => state.adminUserDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = adminUserDelete;

  const adminUserUpdateAccess = useSelector(
    (state) => state.adminUserUpdateAccess
  );
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = adminUserUpdateAccess;

  useEffect(() => {
    dispatch(listAllUsers());
  }, [dispatch, successDelete, successUpdate]);

  const sortByField = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const preFilteredUsers =
    users?.filter((user) => user.access === "premium") || [];

  const sortedAndFilteredUsers = preFilteredUsers
    .sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    })
    .filter((user) => {
      const matchesAccessFilter =
        filterAccess === "" || user.access === filterAccess;
      const matchesSubscriptionActiveFilter =
        filterSubscriptionActive === "" ||
        (filterSubscriptionActive === "true"
          ? user.subscriptionActive
          : !user.subscriptionActive);
      return matchesAccessFilter && matchesSubscriptionActiveFilter;
    });

  return (
    <Container>
      <Row>
        <h2>Premium Subscriber Management</h2>
        {loading || loadingDelete || loadingUpdate ? (
          <Loading />
        ) : error ? (
          <ErrorMessage variant="danger">{error}</ErrorMessage>
        ) : (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Filter by Auto Renew</Form.Label>
              <Form.Select
                value={filterSubscriptionActive}
                onChange={(e) => setFilterSubscriptionActive(e.target.value)}
              >
                <option value="">Both</option>
                <option value="true">Auto Renew</option>
                <option value="false">Expiring</option>
              </Form.Select>
            </Form.Group>
            <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
              <Table
                striped
                bordered
                hover
                responsive
                class="table table-fixed"
              >
                <thead className="bg-black">
                  <tr>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByField("username")}
                    >
                      User{" "}
                      {sortField === "username" &&
                        (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th>Driver Name</th>
                    <th>Discord Name</th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByField("subscriptionExpires")}
                    >
                      Subscription Date{" "}
                      {sortField === "subscriptionExpires" &&
                        (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByField("subscriptionActive")}
                    >
                      Auto Renew?{" "}
                      {sortField === "subscriptionActive" &&
                        (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedAndFilteredUsers.map((user) => (
                    <tr key={user._id}>
                      <td>
                        <LinkContainer
                          style={{ width: "100%" }}
                          to={`/user/${user._id}`}
                        >
                          <Button>{user.username}</Button>
                        </LinkContainer>
                      </td>
                      <td>
                        <img
                          src={user.pic || "default-profile.png"}
                          alt="Profile"
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "10px",
                          }}
                        />
                        {user.driver
                          ? `${user.driver.driverFirstName} ${user.driver.driverLastName}`
                          : "N/A"}
                      </td>
                      <td>{user.driver ? user.driver.discordName : "N/A"}</td>
                      <td>
                        {new Date(
                          user.subscriptionExpires
                        ).toLocaleDateString()}
                      </td>
                      <td>{user.subscriptionActive ? "True" : "False"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        {errorDelete && (
          <ErrorMessage variant="danger">{errorDelete}</ErrorMessage>
        )}
        {errorUpdate && (
          <ErrorMessage variant="danger">{errorUpdate}</ErrorMessage>
        )}
      </Row>
    </Container>
  );
};

export default AdminSubscribers;
