import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Table,
  Button,
  Form,
  ButtonGroup,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  listAllUsers,
  deleteUser,
  updateUserAccess,
} from "../../../actions/adminActions";
import Loading from "../../../Components/Loading";
import ErrorMessage from "../../../Components/ErrorMessage";
import Trash from "../../../Images/trash-white.svg";
import "../Admin.scss";

const AdminUserInfo = () => {
  const dispatch = useDispatch();
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterAccess, setFilterAccess] = useState("");
  const [editAccess, setEditAccess] = useState({});

  const adminUsersList = useSelector((state) => state.adminUsersList);
  const { loading, error, users } = adminUsersList;

  const adminUserDelete = useSelector((state) => state.adminUserDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = adminUserDelete;

  const adminUserUpdateAccess = useSelector(
    (state) => state.adminUserUpdateAccess
  );
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = adminUserUpdateAccess;

  useEffect(() => {
    dispatch(listAllUsers());
  }, [dispatch, successDelete, successUpdate]);

  const handleDelete = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteUser(userId));
    }
  };

  const handleSaveAccess = (userId) => {
    if (editAccess[userId]) {
      dispatch(updateUserAccess(userId, editAccess[userId]));
      setEditAccess({ ...editAccess, [userId]: undefined });
    }
  };

  const handleChangeAccess = (userId, access) => {
    setEditAccess({ ...editAccess, [userId]: access });
  };

  const sortByField = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const sortedUsers = [...(users || [])]
    .sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    })
    .filter(
      (user) =>
        filterAccess === "" ||
        user.access === filterAccess ||
        (filterAccess === "admin" && user.isAdmin)
    );

  return (
    <Container>
      <Row>
        <h2>User Management</h2>
        {loading || loadingDelete || loadingUpdate ? (
          <Loading />
        ) : error ? (
          <ErrorMessage variant="danger">{error}</ErrorMessage>
        ) : (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Filter by Access Level</Form.Label>
              <Form.Select
                value={filterAccess}
                onChange={(e) => setFilterAccess(e.target.value)}
              >
                <option value="">All Access Levels</option>
                <option value="none">None</option>
                <option value="beta">Beta</option>
                <option value="premium">Premium</option>
                <option value="shadow">Shadow</option>
                <option value="admin">Admin</option>
              </Form.Select>
            </Form.Group>
            <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
              <Table
                striped
                bordered
                hover
                responsive
                class="table table-fixed"
              >
                <thead className="bg-black">
                  <tr>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByField("username")}
                    >
                      User{" "}
                      {sortField === "username" &&
                        (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th>Driver Name</th>
                    <th>Discord Name</th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByField("createdAt")}
                    >
                      Created At{" "}
                      {sortField === "createdAt" &&
                        (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByField("access")}
                    >
                      Access Level{" "}
                      {sortField === "access" &&
                        (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedUsers.map((user) => (
                    <tr key={user._id}>
                      <td>
                        <LinkContainer
                          style={{ width: "100%" }}
                          to={`/user/${user._id}`}
                        >
                          <Button>{user.username}</Button>
                        </LinkContainer>
                      </td>
                      <td>
                        <img
                          src={user.pic || "default-profile.png"}
                          alt="Profile"
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "10px",
                          }}
                        />
                        {user.driver
                          ? `${user.driver.driverFirstName} ${user.driver.driverLastName}`
                          : "N/A"}
                      </td>
                      <td>{user.driver ? user.driver.discordName : "N/A"}</td>
                      <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                      <td>
                        {editAccess[user._id] !== undefined ? (
                          <>
                            <Form.Control
                              as="select"
                              value={editAccess[user._id]}
                              onChange={(e) =>
                                handleChangeAccess(user._id, e.target.value)
                              }
                            >
                              <option value="none">None</option>
                              <option value="beta">Beta</option>
                              <option value="premium">Premium</option>
                            </Form.Control>
                            <Button
                              variant="success"
                              onClick={() => handleSaveAccess(user._id)}
                            >
                              Save
                            </Button>
                          </>
                        ) : user.isAdmin ? (
                          "ADMIN"
                        ) : (
                          user.access
                        )}
                      </td>
                      <td>
                        <ButtonGroup>
                          {!editAccess[user._id] && (
                            <Button
                              onClick={() =>
                                setEditAccess({
                                  ...editAccess,
                                  [user._id]: user.access,
                                })
                              }
                            >
                              Edit Access
                            </Button>
                          )}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDelete(user._id)}
                          >
                            <img
                              src={Trash}
                              alt="Delete"
                              style={{ width: "20px", height: "20px" }}
                            />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        {errorDelete && (
          <ErrorMessage variant="danger">{errorDelete}</ErrorMessage>
        )}
        {errorUpdate && (
          <ErrorMessage variant="danger">{errorUpdate}</ErrorMessage>
        )}
      </Row>
    </Container>
  );
};

export default AdminUserInfo;
