import {
  LEAGUES_LIST_FAIL,
  LEAGUES_LIST_REQUEST,
  LEAGUES_LIST_SUCCESS,
  LEAGUES_CREATE_FAIL,
  LEAGUES_CREATE_REQUEST,
  LEAGUES_CREATE_SUCCESS,
  LEAGUES_UPDATE_FAIL,
  LEAGUES_UPDATE_REQUEST,
  LEAGUES_UPDATE_SUCCESS,
  LEAGUES_DELETE_FAIL,
  LEAGUES_DELETE_REQUEST,
  LEAGUES_DELETE_SUCCESS,
} from "../constants/leaguesConstants";

export const leagueListReducer = (state = { leagues: [] }, action) => {
  switch (action.type) {
    case LEAGUES_LIST_REQUEST:
      return { loading: true };
    case LEAGUES_LIST_SUCCESS:
      return { loading: false, leagues: action.payload };
    case LEAGUES_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const leagueCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAGUES_CREATE_REQUEST:
      return { loading: true };
    case LEAGUES_CREATE_SUCCESS:
      return { loading: false, success: true };
    case LEAGUES_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const leagueUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAGUES_UPDATE_REQUEST:
      return { loading: true };
    case LEAGUES_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case LEAGUES_UPDATE_FAIL:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const leagueDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAGUES_DELETE_REQUEST:
      return { loading: true };
    case LEAGUES_DELETE_SUCCESS:
      return { loading: false, success: true };
    case LEAGUES_DELETE_FAIL:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};
