import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Card,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { searchUsers, resetUserSearch } from "../../../actions/userActions";
import {
  searchDrivers,
  resetDriverSearch,
} from "../../../actions/driverActions";
import {
  sendFriendRequest,
  getOutgoingFriendRequests,
  getReceivedFriendRequests,
  denyFriendRequest,
  acceptFriendRequest,
  getFriends,
} from "../../../actions/friendshipActions";
import { useNavigate } from "react-router-dom";

import MainScreen from "../../../Components/MainScreen";
import Trash from "../../../Images/trash-white.svg";

import "./FriendshipScreen.scss";
import gStyle from "../../../SCSS/global.module.scss";
import ProfileNav from "../../../Components/ProfileNav";
import Loading from "../../../Components/Loading";
import { FaUserPlus } from "react-icons/fa6";

const FriendshipScreen = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userSearch = useSelector((state) => state.userSearch);
  const {
    loading: loadingSearch,
    error: errorSearch,
    users: searchResults,
  } = userSearch;

  const driverSearch = useSelector((state) => state.driverSearch);
  const {
    loading: loadingDriverSearch,
    error: errorDriverSearch,
    drivers: searchDriverResults,
  } = driverSearch;

  const friendsList = useSelector((state) => state.friendsList);
  const { loading: loadingFriends, error: errorFriends, friends } = friendsList;

  const friendRequestSend = useSelector((state) => state.friendRequestSend);
  const { success: successFriendRequest } = friendRequestSend;

  const outgoingFriendRequests = useSelector(
    (state) => state.outgoingFriendRequests
  );
  const {
    loading: loadingOutgoingRequests,
    error: errorOutgoingRequests,
    requests: outgoingRequests,
  } = outgoingFriendRequests;

  const receivedFriendRequests = useSelector(
    (state) => state.receivedFriendRequests
  );
  const {
    loading: loadingReceivedRequests,
    error: errorReceivedRequests,
    requests: receivedRequests,
  } = receivedFriendRequests;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      dispatch(searchUsers(searchTerm));
      dispatch(searchDrivers(searchTerm));
    } else {
      dispatch(resetUserSearch());
      dispatch(resetDriverSearch());
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(getOutgoingFriendRequests(userInfo?._id));
      dispatch(getReceivedFriendRequests(userInfo?._id));
    }

    return () => {
      dispatch(resetUserSearch());
      dispatch(resetDriverSearch());
    };
  }, [dispatch, userInfo, successFriendRequest]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getFriends(userInfo?._id));
    }
  }, [dispatch, userInfo]);

  const sendRequestHandler = async (userId) => {
    try {
      await dispatch(sendFriendRequest(userId));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MainScreen title="Your Friends">
      <Container>
        <Row className="justify-content-center">
          <Col
            style={{
              top: "0",
              left: "0",
            }}
          >
            <ProfileNav />
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col
            className="my-1"
            style={{
              minWidth: "350px",
              top: "0",
              left: "0",
            }}
          >
            <Card className="mb-3">
              <Card.Header
                className={`${gStyle.cyan} ${gStyle.cyanTs} ${gStyle.smdText}`}
              >
                Add Friends
              </Card.Header>
              <Card.Body>
                <Form
                  onSubmit={submitHandler}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    style={{
                      flex: "1",
                      height: "40px",
                    }}
                  />
                  <Button
                    type="submit"
                    style={{ marginLeft: "10px" }}
                    className="rounded-button"
                  >
                    Search
                  </Button>
                </Form>
                {loadingSearch && <Loading size={30} />}
                {errorSearch && <div>{errorSearch}</div>}
                {searchResults?.length > 0 && (
                  <ListGroup
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      backgroundColor: "#353535",
                      borderRadius: "10px",
                    }}
                  >
                    {searchResults
                      .filter((user) => user._id !== userInfo?._id)
                      .sort((a, b) => a.username.localeCompare(b.username))
                      .map((user) => (
                        <div
                          key={user._id}
                          className="d-flex align-items-center m-2"
                        >
                          <span style={{ flex: 1 }}>{user.username}</span>
                          <ButtonGroup>
                            <Button
                              variant="secondary"
                              size="sm"
                              className="rounded-button"
                              onClick={() => navigate(`/user/${user._id}`)}
                            >
                              Visit Profile
                            </Button>
                            <Button
                              variant="success"
                              size="sm"
                              className="rounded-button"
                              onClick={() => sendRequestHandler(user._id)}
                            >
                              <FaUserPlus />
                            </Button>
                          </ButtonGroup>
                        </div>
                      ))}
                  </ListGroup>
                )}
                {loadingDriverSearch && <Loading size={30} />}
                {errorDriverSearch && <div>{errorDriverSearch}</div>}
                {searchDriverResults?.length > 0 && (
                  <ListGroup
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      backgroundColor: "#353535",
                      borderRadius: "10px",
                    }}
                  >
                    {searchDriverResults
                      .filter((driver) => driver.user !== userInfo?._id)
                      .sort((a, b) =>
                        (
                          a.driverFirstName +
                          " " +
                          a.driverLastName
                        ).localeCompare(
                          b.driverFirstName + " " + b.driverLastName
                        )
                      )
                      .map((driver) => (
                        <div
                          key={driver.user}
                          className="d-flex align-items-center m-2"
                        >
                          <span style={{ flex: 1 }}>
                            {driver.driverFirstName} {driver.driverLastName}
                          </span>
                          <ButtonGroup>
                            <Button
                              variant="secondary"
                              size="sm"
                              className="rounded-button"
                              onClick={() => navigate(`/user/${driver.user}`)}
                            >
                              Visit Profile
                            </Button>
                            <Button
                              variant="success"
                              size="sm"
                              className="rounded-button"
                              onClick={() => sendRequestHandler(driver.user)}
                            >
                              <FaUserPlus />
                            </Button>
                          </ButtonGroup>
                        </div>
                      ))}
                  </ListGroup>
                )}
              </Card.Body>
            </Card>
            <Card className="mb-2">
              <Card.Header
                className={`${gStyle.mclaren} ${gStyle.mclarenTs} ${gStyle.smdText}`}
              >
                Incoming Requests
              </Card.Header>
              <Card.Body>
                {loadingReceivedRequests ? (
                  <Loading size={30} />
                ) : errorReceivedRequests ? (
                  <div>{errorReceivedRequests}</div>
                ) : receivedRequests?.length > 0 ? (
                  receivedRequests.map((request) => (
                    <div
                      key={request._id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <LinkContainer to={`/user/${request.user1._id}`}>
                        <Button variant="link">
                          <img
                            src={request.user1.pic}
                            alt="Profile"
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "10px",
                            }}
                          />
                          {request.user1.username}
                        </Button>
                      </LinkContainer>
                      <ButtonGroup>
                        <Button
                          variant="success"
                          size="sm"
                          className="rounded-button ml-2"
                          onClick={() =>
                            dispatch(acceptFriendRequest(request._id))
                          }
                        >
                          Accept
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          className="rounded-button-image"
                          onClick={() =>
                            dispatch(denyFriendRequest(request._id))
                          }
                        >
                          <img src={Trash} alt="Trash" />
                        </Button>
                      </ButtonGroup>
                    </div>
                  ))
                ) : (
                  <div>No incoming friend requests</div>
                )}
              </Card.Body>
            </Card>
            <Card>
              <Card.Header
                className={`${gStyle.magenta} ${gStyle.magentaTs} ${gStyle.smdText}`}
              >
                Outgoing Requests
              </Card.Header>
              <Card.Body>
                {loadingOutgoingRequests ? (
                  <Loading size={30} />
                ) : errorOutgoingRequests ? (
                  <div>{errorOutgoingRequests}</div>
                ) : outgoingRequests?.length > 0 ? (
                  outgoingRequests.map((request) => (
                    <div
                      key={request._id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <LinkContainer to={`/user/${request.user2._id}`}>
                        <Button variant="link">
                          <img
                            src={request.user2.pic}
                            alt="Profile"
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "10px",
                            }}
                          />
                          {request.user2.username}
                        </Button>
                      </LinkContainer>

                      <Button
                        variant="danger"
                        className="rounded-button"
                        size="sm"
                        onClick={() => dispatch(denyFriendRequest(request._id))}
                      >
                        Revoke
                      </Button>
                    </div>
                  ))
                ) : (
                  <div>No outgoing friend requests</div>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="right justify-content-center my-1"
            style={{
              minWidth: "300px",
            }}
          >
            <Card>
              <Card.Header
                className={`${gStyle.white} ${gStyle.whiteTs} ${gStyle.smdText}`}
                style={{
                  background: "linear-gradient(90deg, #0085c2, #00afff)",
                  borderRadius: "10px 10px 0 0",
                }}
              >
                Friends List
                {friends && friends.length > 0 && ` (${friends.length})`}
              </Card.Header>
              <Card.Body
                className="gradient-background-list-content"
                style={{
                  borderRadius: "0 0 10px 10px",
                }}
              >
                {loadingFriends ? (
                  <Loading size={30} />
                ) : errorFriends ? (
                  <div>{errorFriends}</div>
                ) : friends?.length > 0 ? (
                  friends
                    .sort((a, b) =>
                      a.user2.username.localeCompare(b.user2.username)
                    )
                    .map((friend) => (
                      <div
                        key={friend._id}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "2px",
                        }}
                      >
                        {/* Show the username of the friend. You need to check if friend.user1 or friend.user2 is the current user and display the other one */}
                        <LinkContainer
                          to={`/user/${
                            friend.user1._id === userInfo?._id
                              ? friend.user2._id
                              : friend.user1._id
                          }`}
                        >
                          <Button variant="link">
                            {/* Check if user1 or user2 is the friend and display their profile picture */}
                            <img
                              src={
                                friend.user1._id === userInfo?._id
                                  ? friend.user2.pic
                                  : friend.user1.pic
                              }
                              alt="Profile"
                              style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "10px",
                              }}
                            />
                            {friend.user1._id === userInfo?._id
                              ? friend.user2.username
                              : friend.user1.username}
                          </Button>
                        </LinkContainer>
                      </div>
                    ))
                ) : (
                  <div>No added friends yet</div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </MainScreen>
  );
};

export default FriendshipScreen;
