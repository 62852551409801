import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import {
  login,
  verifyTwoFA,
  checkTwoFAStatus,
  clearLoginAttempt,
} from "../../actions/userActions";
import MainScreen from "../../Components/MainScreen";
import Loading from "../../Components/Loading";
import ErrorMessage from "../../Components/ErrorMessage";
import "./LoginScreen.scss";
import gStyle from "../../SCSS/global.module.scss";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [twoFACode, setTwoFACode] = useState("");
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo, twoFARequired, tempUserId } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate("/profile");
    }
  }, [userInfo, navigate]);

  // 2FA modal visibility logic
  useEffect(() => {
    if (twoFARequired) {
      setShowModal(true);
    }
  }, [twoFARequired]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
    dispatch(checkTwoFAStatus());
  };

  const handleTwoFASubmit = (e) => {
    e.preventDefault();
    setShowModal(false);
    dispatch(verifyTwoFA(twoFACode, tempUserId));
    dispatch(checkTwoFAStatus());
  };

  const handleModalClose = () => {
    setShowModal(false);
    dispatch(clearLoginAttempt());
  };

  return (
    <MainScreen title="Login">
      <div className="loginContainer">
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {loading && <Loading />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="formBasicEmail" className="py-2">
            <Form.Label className={`${gStyle.wh} ${gStyle.whTs}`}>
              Email
            </Form.Label>
            <Form.Control
              type="email"
              value={email}
              placeholder="Enter Email"
              autoComplete="user-email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword" className="py-2">
            <Form.Label className={`${gStyle.wh} ${gStyle.whTs}`}>
              Password
            </Form.Label>
            <Form.Control
              type="password"
              value={password}
              placeholder="Password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="success" type="submit" size="md" className="my-2">
            Submit
          </Button>
        </Form>
        <Row className="pt-3">
          <Col>
            <p className={`${gStyle.wh}`}>
              No account yet?{" "}
              <Link to="/register" className={`register ${gStyle.cyan}`}>
                Register Here
              </Link>
            </p>
          </Col>
        </Row>
        <Row className="py-1">
          <Col>
            <p className={`${gStyle.wh}`}>
              Forgot your password?{" "}
              <Link
                to="/request-password-reset"
                className={`register ${gStyle.cyan}`}
              >
                Reset Password
              </Link>
            </p>
          </Col>
        </Row>

        {twoFARequired && showModal && (
          <Modal
            show={showModal}
            onHide={handleModalClose}
            dialogClassName="modal-custom-position"
          >
            <Modal.Header closeButton>
              <Modal.Title>Two-Factor Authentication</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Please enter the 2FA code from your authentication app.</p>
              <Form onSubmit={handleTwoFASubmit}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={twoFACode}
                    onChange={(e) => setTwoFACode(e.target.value)}
                    placeholder="Enter 2FA Code"
                  />
                </Form.Group>
                <Button className="mt-3" variant="info" type="submit">
                  Verify
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </MainScreen>
  );
};

export default LoginScreen;
