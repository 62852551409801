export const ERROR_LOG_CREATE_REQUEST = "ERROR_LOG_CREATE_REQUEST";
export const ERROR_LOG_CREATE_SUCCESS = "ERROR_LOG_CREATE_SUCCESS";
export const ERROR_LOG_CREATE_FAIL = "ERROR_LOG_CREATE_FAIL";

export const ERROR_LOG_DETAILS_REQUEST = "ERROR_LOG_DETAILS_REQUEST";
export const ERROR_LOG_DETAILS_SUCCESS = "ERROR_LOG_DETAILS_SUCCESS";
export const ERROR_LOG_DETAILS_FAIL = "ERROR_LOG_DETAILS_FAIL";

export const ERROR_LOG_UPDATE_REQUEST = "ERROR_LOG_UPDATE_REQUEST";
export const ERROR_LOG_UPDATE_SUCCESS = "ERROR_LOG_UPDATE_SUCCESS";
export const ERROR_LOG_UPDATE_FAIL = "ERROR_LOG_UPDATE_FAIL";

export const ERROR_LOG_DELETE_REQUEST = "ERROR_LOG_DELETE_REQUEST";
export const ERROR_LOG_DELETE_SUCCESS = "ERROR_LOG_DELETE_SUCCESS";
export const ERROR_LOG_DELETE_FAIL = "ERROR_LOG_DELETE_FAIL";

export const ERROR_LOG_LIST_REQUEST = "ERROR_LOG_LIST_REQUEST";
export const ERROR_LOG_LIST_SUCCESS = "ERROR_LOG_LIST_SUCCESS";
export const ERROR_LOG_LIST_FAIL = "ERROR_LOG_LIST_FAIL";

export const ERROR_LOG_LIST_USER_REQUEST = "ERROR_LOG_LIST_USER_REQUEST";
export const ERROR_LOG_LIST_USER_SUCCESS = "ERROR_LOG_LIST_USER_SUCCESS";
export const ERROR_LOG_LIST_USER_FAIL = "ERROR_LOG_LIST_USER_FAIL";
