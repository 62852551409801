import {
  DRIVER_CREATE_REQUEST,
  DRIVER_CREATE_SUCCESS,
  DRIVER_CREATE_FAIL,
  DRIVER_CREATE_RESET,
  DRIVER_UPDATE_REQUEST,
  DRIVER_UPDATE_SUCCESS,
  DRIVER_UPDATE_FAIL,
  DRIVER_UPDATE_RESET,
  DRIVER_DETAILS_REQUEST,
  DRIVER_DETAILS_SUCCESS,
  DRIVER_DETAILS_FAIL,
  DRIVER_DETAILS_RESET,
  DRIVER_SEARCH_REQUEST,
  DRIVER_SEARCH_SUCCESS,
  DRIVER_SEARCH_FAIL,
  DRIVER_SEARCH_RESET,
} from "../constants/driverConstants";
import { USER_LOGOUT } from "../constants/userConstants";

export const driverCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DRIVER_CREATE_REQUEST:
      return { loading: true };
    case DRIVER_CREATE_SUCCESS:
      return { loading: false, success: true, driver: action.payload };
    case DRIVER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case DRIVER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const driverUpdateReducer = (state = { driver: {} }, action) => {
  switch (action.type) {
    case DRIVER_UPDATE_REQUEST:
      return { loading: true };
    case DRIVER_UPDATE_SUCCESS:
      return { loading: false, success: true, driver: action.payload };
    case DRIVER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case DRIVER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const driverDetailsReducer = (state = { driver: {} }, action) => {
  switch (action.type) {
    case DRIVER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case DRIVER_DETAILS_SUCCESS:
      return { loading: false, driver: action.payload };
    case DRIVER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case DRIVER_DETAILS_RESET:
    case USER_LOGOUT:
      return { driver: {} };
    default:
      return state;
  }
};

export const driverSearchReducer = (state = { drivers: [] }, action) => {
  switch (action.type) {
    case DRIVER_SEARCH_REQUEST:
      return { loading: true, drivers: [] };
    case DRIVER_SEARCH_SUCCESS:
      return { loading: false, drivers: action.payload };
    case DRIVER_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    case DRIVER_SEARCH_RESET:
      return { drivers: [] };
    default:
      return state;
  }
};
