import {
  DRIVER_CREATE_REQUEST,
  DRIVER_CREATE_SUCCESS,
  DRIVER_CREATE_FAIL,
  DRIVER_CREATE_RESET,
  DRIVER_UPDATE_REQUEST,
  DRIVER_UPDATE_SUCCESS,
  DRIVER_UPDATE_FAIL,
  DRIVER_UPDATE_RESET,
  DRIVER_DETAILS_REQUEST,
  DRIVER_DETAILS_SUCCESS,
  DRIVER_DETAILS_FAIL,
  DRIVER_DETAILS_RESET,
  DRIVER_SEARCH_REQUEST,
  DRIVER_SEARCH_SUCCESS,
  DRIVER_SEARCH_FAIL,
  DRIVER_SEARCH_RESET,
} from "../constants/driverConstants";
import axios from "axios";
import { API_BASE_URL } from "../config";

export const createDriver = (driverData) => async (dispatch, getState) => {
  try {
    dispatch({ type: DRIVER_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${API_BASE_URL}/drivers`,
      driverData,
      config
    );

    dispatch({ type: DRIVER_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DRIVER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateDriver =
  (driverId, driverData) => async (dispatch, getState) => {
    try {
      dispatch({ type: DRIVER_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${API_BASE_URL}/drivers/${driverId}`,
        driverData,
        config
      );

      dispatch({ type: DRIVER_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DRIVER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getDriverDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DRIVER_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${API_BASE_URL}/drivers/${id}`, config);

    dispatch({
      type: DRIVER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DRIVER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetDriverDetails = () => (dispatch) => {
  dispatch({ type: DRIVER_DETAILS_RESET });
};

export const searchDrivers = (searchTerm) => async (dispatch) => {
  try {
    dispatch({ type: DRIVER_SEARCH_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${API_BASE_URL}/drivers/search/${searchTerm}`,
      config
    );

    dispatch({ type: DRIVER_SEARCH_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DRIVER_SEARCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetDriverSearch = () => (dispatch) => {
  dispatch({ type: DRIVER_SEARCH_RESET });
};

export const clearDriverCreateError = () => (dispatch) => {
  dispatch({ type: DRIVER_CREATE_RESET });
};

export const clearDriverUpdateError = () => (dispatch) => {
  dispatch({ type: DRIVER_UPDATE_RESET });
};
