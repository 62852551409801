import React from "react";
import { Container, Row, Accordion } from "react-bootstrap";
import versionData from "./Podium_Release_Notes.json";
import { marked } from "marked";
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";

const ReleaseNotes = () => {
  return (
    <Container>
      <Row>
        <div className="spacing">
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>Release Notes</h2>
          <Accordion className="faq-accordion">
            {versionData.release_notes.map((versionData, index) => (
              <Accordion.Item eventKey={String(index)} key={index}>
                <Accordion.Header>{versionData.version}</Accordion.Header>
                <Accordion.Body>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: marked.parse(versionData.notes),
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Row>
    </Container>
  );
};

export default ReleaseNotes;
