import axios from "axios";
import {
  FRIEND_REQUEST_SEND_REQUEST,
  FRIEND_REQUEST_SEND_SUCCESS,
  FRIEND_REQUEST_SEND_FAIL,
  FRIEND_REQUEST_ACCEPT_REQUEST,
  FRIEND_REQUEST_ACCEPT_SUCCESS,
  FRIEND_REQUEST_ACCEPT_FAIL,
  FRIEND_REQUEST_DENY_REQUEST,
  FRIEND_REQUEST_DENY_SUCCESS,
  FRIEND_REQUEST_DENY_FAIL,
  FRIEND_REQUEST_RESET,
  FRIEND_REQUESTS_OUTGOING_REQUEST,
  FRIEND_REQUESTS_OUTGOING_SUCCESS,
  FRIEND_REQUESTS_OUTGOING_FAIL,
  FRIEND_REQUEST_RECEIVED_REQUEST,
  FRIEND_REQUEST_RECEIVED_SUCCESS,
  FRIEND_REQUEST_RECEIVED_FAIL,
  FRIENDS_REQUEST,
  FRIENDS_SUCCESS,
  FRIENDS_FAIL,
  LOGGED_IN_USER_FRIENDS_REQUEST,
  LOGGED_IN_USER_FRIENDS_SUCCESS,
  LOGGED_IN_USER_FRIENDS_FAIL,
  PROFILE_USER_FRIENDS_REQUEST,
  PROFILE_USER_FRIENDS_SUCCESS,
  PROFILE_USER_FRIENDS_FAIL,
  FRIEND_NOTES_REQUEST,
  FRIEND_NOTES_SUCCESS,
  FRIEND_NOTES_FAIL,
  UPDATE_FRIEND_NOTES_REQUEST,
  UPDATE_FRIEND_NOTES_SUCCESS,
  UPDATE_FRIEND_NOTES_FAIL,
  CHECK_FRIEND_REQUEST_SENT_REQUEST,
  CHECK_FRIEND_REQUEST_SENT_SUCCESS,
  CHECK_FRIEND_REQUEST_SENT_FAIL,
  CHECK_FRIEND_REQUEST_RECEIVED_REQUEST,
  CHECK_FRIEND_REQUEST_RECEIVED_SUCCESS,
  CHECK_FRIEND_REQUEST_RECEIVED_FAIL,
  FRIENDSHIP_ID_REQUEST,
  FRIENDSHIP_ID_SUCCESS,
  FRIENDSHIP_ID_FAIL,
} from "../constants/friendshipConstants";

import { API_BASE_URL } from "../config";

// Action for sending a friend request
export const sendFriendRequest = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FRIEND_REQUEST_SEND_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.post(
      `${API_BASE_URL}/friendship/request`,
      {
        requestedId: userId, // Pass the requestedId in the request body
        requesterId: userInfo._id, // Pass the requesterId in the request body
      },
      config
    );

    dispatch({ type: FRIEND_REQUEST_SEND_SUCCESS });
  } catch (error) {
    dispatch({
      type: FRIEND_REQUEST_SEND_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Action for accepting a friend request
export const acceptFriendRequest =
  (requestId) => async (dispatch, getState) => {
    try {
      dispatch({ type: FRIEND_REQUEST_ACCEPT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(
        `${API_BASE_URL}/friendship/accept`,
        { friendRequestId: requestId },
        config
      );

      dispatch({ type: FRIEND_REQUEST_ACCEPT_SUCCESS });

      // Refresh outgoingFriendRequests
      dispatch(getFriends(userInfo._id));
      dispatch(getReceivedFriendRequests(userInfo._id));
    } catch (error) {
      dispatch({
        type: FRIEND_REQUEST_ACCEPT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Action for denying a friend request
export const denyFriendRequest =
  (friendshipId) => async (dispatch, getState) => {
    try {
      dispatch({ type: FRIEND_REQUEST_DENY_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(`${API_BASE_URL}/friendship/remove/`, {
        data: { friendshipId },
        headers: config.headers,
      });

      dispatch({ type: FRIEND_REQUEST_DENY_SUCCESS });

      // Refresh outgoingFriendRequests
      dispatch(getFriends(userInfo._id));
      dispatch(getOutgoingFriendRequests(userInfo._id));
      dispatch(getReceivedFriendRequests(userInfo._id));
    } catch (error) {
      dispatch({
        type: FRIEND_REQUEST_DENY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getOutgoingFriendRequests =
  (userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: FRIEND_REQUESTS_OUTGOING_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${API_BASE_URL}/friendship/requests/${userId}`,
        config
      );

      // Filter the requests to only include the ones made by the current user
      const outgoingRequests = data.filter(
        (request) =>
          request.user1._id === userId && request.status === "pending"
      );

      dispatch({
        type: FRIEND_REQUESTS_OUTGOING_SUCCESS,
        payload: outgoingRequests,
      });
    } catch (error) {
      dispatch({
        type: FRIEND_REQUESTS_OUTGOING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getReceivedFriendRequests =
  (userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: FRIEND_REQUEST_RECEIVED_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${API_BASE_URL}/friendship/requests/${userId}`,
        config
      );

      // Filter the requests to only include the ones received by the current user
      const receivedRequests = data.filter(
        (request) =>
          request.user2._id === userId && request.status === "pending"
      );

      dispatch({
        type: FRIEND_REQUEST_RECEIVED_SUCCESS,
        payload: receivedRequests,
      });
    } catch (error) {
      dispatch({
        type: FRIEND_REQUEST_RECEIVED_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getFriends = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FRIENDS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_BASE_URL}/friendship/friends/${userId}`,
      config
    );

    dispatch({ type: FRIENDS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FRIENDS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetFriendRequest = () => (dispatch) => {
  dispatch({ type: FRIEND_REQUEST_RESET });
};

// Fetch friends of logged-in user
export const getLoggedInUserFriends =
  (userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: LOGGED_IN_USER_FRIENDS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${API_BASE_URL}/friendship/friends/${userId}`,
        config
      );

      dispatch({ type: LOGGED_IN_USER_FRIENDS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: LOGGED_IN_USER_FRIENDS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Fetch friends of the user whose profile is being viewed
export const getProfileUserFriends = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROFILE_USER_FRIENDS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_BASE_URL}/friendship/friends/${userId}`,
      config
    );

    dispatch({ type: PROFILE_USER_FRIENDS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PROFILE_USER_FRIENDS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Action for getting a friend's notes
export const getFriendNotes = (friendId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FRIEND_NOTES_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_BASE_URL}/friendship/notes/${friendId}`,
      config
    );

    dispatch({ type: FRIEND_NOTES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FRIEND_NOTES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Action for updating a friend's notes
export const updateFriendNotes =
  (friendId, notes) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_FRIEND_NOTES_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.put(
        `${API_BASE_URL}/friendship/notes/${friendId}`,
        { notes },
        config
      );

      dispatch({ type: UPDATE_FRIEND_NOTES_SUCCESS });
    } catch (error) {
      dispatch({
        type: UPDATE_FRIEND_NOTES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const checkFriendRequestSent =
  (currentUserId, otherUserId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CHECK_FRIEND_REQUEST_SENT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${API_BASE_URL}/friendship/requests/${currentUserId}`,
        config
      );

      // Check if the friend request exists where user1._id === otherUserId && user2._id === currentUserId
      const hasSentRequest = data.some(
        (request) =>
          request.user1._id === otherUserId &&
          request.user2._id === currentUserId &&
          request.status === "pending"
      );

      dispatch({
        type: CHECK_FRIEND_REQUEST_SENT_SUCCESS,
        payload: hasSentRequest,
      });
    } catch (error) {
      dispatch({
        type: CHECK_FRIEND_REQUEST_SENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const checkFriendRequestReceived =
  (currentUserId, otherUserId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CHECK_FRIEND_REQUEST_RECEIVED_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${API_BASE_URL}/friendship/requests/${currentUserId}`,
        config
      );

      // Check if the friend request exists where user2._id === otherUserId && user1._id === currentUserId
      const hasReceivedRequest = data.some(
        (request) =>
          request.user1._id === currentUserId &&
          request.user2._id === otherUserId &&
          request.status === "pending"
      );

      dispatch({
        type: CHECK_FRIEND_REQUEST_RECEIVED_SUCCESS,
        payload: hasReceivedRequest,
      });
    } catch (error) {
      dispatch({
        type: CHECK_FRIEND_REQUEST_RECEIVED_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getFriendshipId =
  (user1Id, user2Id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FRIENDSHIP_ID_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Fetch the friend requests for user1
      const { data: outgoingRequests } = await axios.get(
        `${API_BASE_URL}/friendship/requests/${user1Id}`,
        config
      );

      // Fetch the friend requests for user2
      const { data: receivedRequests } = await axios.get(
        `${API_BASE_URL}/friendship/requests/${user2Id}`,
        config
      );

      // Fetch the friends for user1
      const { data: user1Friends } = await axios.get(
        `${API_BASE_URL}/friendship/friends/${user1Id}`,
        config
      );

      // Find the matching friendship ID in outgoing requests
      const friendship = outgoingRequests.find(
        (request) =>
          request.user1._id.toString() === user1Id.toString() &&
          request.user2._id.toString() === user2Id.toString() &&
          (request.status === "friends" || request.status === "pending")
      );

      // If not found, check in received requests
      if (!friendship) {
        const receivedFriendship = receivedRequests.find(
          (request) =>
            request.user1._id.toString() === user1Id.toString() &&
            request.user2._id.toString() === user2Id.toString() &&
            (request.status === "friends" || request.status === "pending")
        );
        if (receivedFriendship) {
          dispatch({
            type: FRIENDSHIP_ID_SUCCESS,
            payload: receivedFriendship._id,
          });
          return receivedFriendship._id; // Return the received friendship ID
        }
      }

      // If not found, check in friends
      if (!friendship) {
        const friendshipInFriends = user1Friends.find(
          (friend) =>
            (friend.user1._id.toString() === user1Id.toString() &&
              friend.user2._id.toString() === user2Id.toString()) ||
            (friend.user1._id.toString() === user2Id.toString() &&
              friend.user2._id.toString() === user1Id.toString())
        );
        if (friendshipInFriends) {
          dispatch({
            type: FRIENDSHIP_ID_SUCCESS,
            payload: friendshipInFriends._id,
          });
          return friendshipInFriends._id; // Return the friendship ID from friends
        }
      }

      if (friendship) {
        dispatch({ type: FRIENDSHIP_ID_SUCCESS, payload: friendship._id });
        return friendship._id; // Return the friendship ID
      } else {
        dispatch({ type: FRIENDSHIP_ID_FAIL });
        return null; // No matching friendship found
      }
    } catch (error) {
      dispatch({ type: FRIENDSHIP_ID_FAIL });
      console.error(error);
      return null; // Handle error
    }
  };
