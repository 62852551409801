import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./bootstrap-vapor.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
// index.js or any other root file executed on application start
import axios from "axios";
import ScrollToTop from "./util/ScrollToTop";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // If a 401 response is received, it means the token has expired or is invalid. Log out the user in this case.
      localStorage.removeItem("userInfo");
      window.location.reload(); // Or redirect to '/login' depending on your app's structure
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
