import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainScreen from "../../../Components/MainScreen";
import "./DriverScreen.scss";
import gStyle from "../../../SCSS/global.module.scss";
import ProfileNav from "../../../Components/ProfileNav";
import { getUserDriverDetails } from "../../../actions/userActions";
import WorldFlag from "../../../Images/flags/worldflag.png";
import DriverSocialNav from "../../../Components/DriverSocialNav";
import Driver from "../../../Images/account_logos/racing_helmet.png";
import Flag from "react-world-flags";
import { NATIONALITIES } from "../../../Components/Lists";

const DriverScreen = () => {
  // Initialize State
  const [driverFirstName, setDriverFirstName] = useState("");
  const [driverLastName, setDriverLastName] = useState("");
  const [driverAbbr, setDriverAbbr] = useState("");
  const [eaID, setEaID] = useState("");
  const [discordName, setDiscordName] = useState("");
  const [driverNumber, setDriverNumber] = useState(1);
  const [favoriteTeam, setFavoriteTeam] = useState("");
  const [website, setWebsite] = useState("");
  const [youtube, setYoutube] = useState("");
  const [twitch, setTwitch] = useState("");
  const [twitter, setTwitter] = useState("");
  const [driverBio, setDriverBio] = useState("");
  const [driverDOB, setDriverDOB] = useState(new Date());
  const [region, setRegion] = useState("");
  const [nationality, setNationality] = useState("");
  const [timezone, setTimezone] = useState("");

  const [websiteURL, setWebsiteURL] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  const [twitchURL, setTwitchURL] = useState("");
  const [twitterURL, setTwitterURL] = useState("");

  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDriverDetails = useSelector((state) => state.userDriverDetails);
  const { userDriver } = userDriverDetails;

  const flipObject = (object) => {
    return Object.keys(object).reduce((flippedObject, key) => {
      flippedObject[object[key]] = key;
      return flippedObject;
    }, {});
  };

  const REVERSED_NATIONALITIES = flipObject(NATIONALITIES);

  // calc age from DOB
  const getAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--; // Adjust age if the birth date has not occurred this year.
    }

    return age;
  };

  // check and format user URLs
  const checkUrlFormat = (url) => {
    let pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i" // fragment locator
    );
    return pattern.test(url);
  };

  const formatUrl = (url) => {
    if (!url) return "";
    if (url.startsWith("https://www.")) {
      return url;
    } else if (url.startsWith("www.")) {
      return "https://" + url;
    } else if (url.startsWith("https://")) {
      return url;
    } else {
      return "https://" + url;
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserDriverDetails(userInfo?._id));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (userInfo && !userDriver) {
      navigate("/profile/driver/create");
    } else if (userDriver.user === userInfo?._id) {
      setDriverFirstName(userDriver.driverFirstName || "");
      setDriverLastName(userDriver.driverLastName || "");
      setDriverAbbr(userDriver.driverAbbr || "");
      setEaID(userDriver.eaID || "");
      setDiscordName(userDriver.discordName || "");
      setDriverNumber(userDriver.driverNumber || 1);
      setFavoriteTeam(userDriver.favoriteTeam || "");

      let socials = userDriver.driverSocials || {};

      setWebsite(socials.website || "");
      setYoutube(socials.youtube || "");
      setTwitch(socials.twitch || "");
      setTwitter(socials.twitter || "");

      setDriverBio(userDriver.driverBio || "");
      setDriverDOB(userDriver.driverDOB || new Date());
      setRegion(userDriver.region || "");
      setNationality(userDriver.nationality || "");
      setTimezone(userDriver.timezone || "");

      let siteUrl = website;
      if (siteUrl && siteUrl.length >= 3) {
        if (!checkUrlFormat(siteUrl)) {
          siteUrl = formatUrl(siteUrl);
        }
      } else {
        siteUrl = "";
      }
      setWebsiteURL(siteUrl);

      let youtubeUsername = youtube;
      let ytUrl = "";
      if (youtubeUsername && youtubeUsername.length >= 3) {
        if (!youtubeUsername.startsWith("@")) {
          youtubeUsername = "@" + youtubeUsername;
        }
        ytUrl = "https://www.youtube.com/" + youtubeUsername;
      } else {
        ytUrl = "";
      }
      setYoutubeURL(ytUrl);

      let twitchUsername = twitch;
      let twitchUrl = "";
      if (twitchUsername && twitchUsername.length >= 3) {
        twitchUrl = "https://www.twitch.tv/" + twitchUsername;
      } else {
        twitchUrl = "";
      }
      setTwitchURL(twitchUrl);

      let twitterUsername = twitter;
      let twtUrl = "";
      if (twitterUsername && twitterUsername.length >= 3) {
        twtUrl = "https://www.twitter.com/" + twitterUsername;
      } else {
        twtUrl = "";
      }
      setTwitterURL(twtUrl);
    }
  }, [
    dispatch,
    userInfo,
    userDriver,
    twitch,
    twitter,
    website,
    youtube,
    navigate,
  ]);

  return (
    <MainScreen title="Your Driver Profile">
      <Container>
        <Row className="justify-content-center">
          <Col
            style={{
              top: "0",
              left: "0",
            }}
          >
            <ProfileNav />
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col
            className="my-1"
            style={{
              minWidth: "350px",
              top: "0",
              left: "0",
            }}
          >
            <Card>
              <Card.Body>
                <div
                  style={{ marginBottom: "8px" }}
                  className={`detail_label ${gStyle.cyan} ${gStyle.cyanTs} ${gStyle.mdText} `}
                >
                  <img
                    src={Driver}
                    alt={driverFirstName}
                    height="26"
                    style={{ paddingRight: "12px" }}
                  />
                  {driverFirstName}
                  <span> </span>
                  {driverLastName}
                </div>
                <div
                  style={{ marginBottom: "15px" }}
                  className={`detail_label ${gStyle.magenta} ${gStyle.magentaTs} ${gStyle.mdText}`}
                >
                  {REVERSED_NATIONALITIES[nationality] ? (
                    <Flag
                      code={REVERSED_NATIONALITIES[nationality]}
                      height="26"
                      style={{ paddingRight: "20px" }}
                    />
                  ) : (
                    <img
                      src={WorldFlag}
                      alt="world flag"
                      height="26"
                      style={{ paddingRight: "20px" }}
                    />
                  )}
                  [{driverAbbr}]
                  <span style={{ paddingLeft: "20px" }}>{driverNumber}</span>
                </div>
                <p>
                  EA ID:{" "}
                  <span className={`detail_label ${gStyle.smdText}`}>
                    {" "}
                    {eaID}
                  </span>
                </p>
                {discordName && discordName.length > 1 && (
                  <p>
                    Discord:{" "}
                    <span className={`detail_label ${gStyle.smdText}`}>
                      {discordName}
                    </span>
                  </p>
                )}
                {favoriteTeam && favoriteTeam.length > 1 && (
                  <p>
                    Favorite Team:{" "}
                    <span className={`detail_label ${gStyle.smdText}`}>
                      {favoriteTeam}
                    </span>
                  </p>
                )}
                {driverDOB && getAge(driverDOB) >= 14 && (
                  <p>
                    Driver Age:{" "}
                    <span className={`detail_label ${gStyle.smdText}`}>
                      {getAge(driverDOB)}
                    </span>
                  </p>
                )}
                {timezone && timezone.length >= 1 && (
                  <p>
                    Timezone:{" "}
                    <span className={`detail_label ${gStyle.smdText}`}>
                      {timezone.replace(/_/g, " ").replace(/\//g, " / ")}
                    </span>
                  </p>
                )}
                {region && region.length >= 1 && (
                  <p>
                    Region:{" "}
                    <span className={`detail_label ${gStyle.smdText}`}>
                      {region}
                    </span>
                  </p>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="right justify-content-center my-2"
            style={{
              minWidth: "300px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={userInfo?.pic}
                alt={userInfo?.username}
                style={{ borderRadius: "15px", maxWidth: "256px" }}
              />
              <DriverSocialNav
                web={websiteURL}
                youtube={youtubeURL}
                twitch={twitchURL}
                twitter={twitterURL}
              />
              <LinkContainer
                to="/profile/Driver/edit"
                style={{ width: "100%", maxWidth: "256px", marginTop: "10px" }}
              >
                <Button
                  className={`edit_profile ${gStyle.magentaBG}`}
                  style={{ width: "100%" }}
                >
                  Edit Driver Profile
                </Button>
              </LinkContainer>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col
            style={{
              top: "0",
              left: "0",
            }}
          >
            <Card className="mb-3">
              <Card.Header
                className={`${gStyle.cyan} ${gStyle.cyanTs} ${gStyle.smdText}`}
              >
                Driver Bio
              </Card.Header>
              <Card.Body>
                <Card.Text>{driverBio}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </MainScreen>
  );
};

export default DriverScreen;
