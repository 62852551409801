import React, { useEffect, useState } from "react";
import {
  Container,
  Offcanvas,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";
import useAuth from "../../hooks/useAuth";

import "./Header.scss";

import { Logo } from "../logo";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { userAccess } = useAuth();
  const { isAdmin, loading } = userAccess;
  const [showNav, setShowNav] = useState(false);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleNavClick = (path) => {
    navigate(path);
    setShowNav(false);
  };

  useEffect(() => {}, [userInfo]);

  return (
    <Navbar
      className="fixed-top py-2 navbar-dark bg-dark"
      bg="primary"
      expand="md"
      variant="dark"
    >
      <Container className="lg" expand="md">
        <Logo />
        <LinkContainer to="/">
          <Navbar.Brand className="me-auto brand-text">
            Podium Theory
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-md"
          onClick={() => setShowNav(true)}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-md"
          placement="end"
          show={showNav}
          onHide={() => setShowNav(false)}
        >
          <Offcanvas.Header
            closeButton
            closeVariant="white"
            className="darkPurple"
          >
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
              PodiumTheory.com
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 py-1">
              <NavDropdown
                title="About"
                id="offcanvasNavbarDropdown-expand-md"
                style={{
                  marginRight: "5px",
                  marginBotton: "3px",
                }}
              >
                <NavDropdown.Item
                  className="my-1"
                  onClick={() => handleNavClick("/about/podium-for-f1")}
                >
                  Podium for F1
                </NavDropdown.Item>

                {showNav && (
                  <>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/screenshots")}
                    >
                      Podium Screenshots
                    </NavDropdown.Item>
                  </>
                )}
                <NavDropdown.Divider />

                <NavDropdown.Item
                  className="my-1"
                  onClick={() => handleNavClick("/about/faq")}
                >
                  {showNav ? "Frequently Asked Questions" : "Knowledge Base"}
                </NavDropdown.Item>
                {showNav && (
                  <>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/known-issues")}
                    >
                      Known Issues
                    </NavDropdown.Item>
                    {/* 
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/latest-news")}
                    >
                      Latest News
                    </NavDropdown.Item>
                    */}
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/how-to-guides")}
                    >
                      How to Guides
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/video-tutorials")}
                    >
                      Video Tutorials
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/premium-subs")}
                    >
                      Premium Subscriptions
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/release-notes")}
                    >
                      Release Notes
                    </NavDropdown.Item>
                  </>
                )}

                <NavDropdown.Divider />
                <NavDropdown.Item
                  className="my-1"
                  onClick={() => handleNavClick("/about/privacy-policy")}
                >
                  {showNav ? "Privacy Policy" : "Privacy & Terms"}
                </NavDropdown.Item>
                {showNav && (
                  <>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/terms-of-service")}
                    >
                      Terms of Service
                    </NavDropdown.Item>
                  </>
                )}
              </NavDropdown>
              <NavDropdown
                title="Podium"
                id="offcanvasNavbarDropdown-expand-md"
                style={{
                  marginRight: "5px",
                  marginBotton: "3px",
                }}
              >
                <NavDropdown.Item
                  className="my-1"
                  onClick={() => handleNavClick("/league-manager")}
                >
                  League Manager
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="my-1"
                  onClick={() => handleNavClick("/setup-archiver")}
                >
                  Setup Archiver
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  className="my-1"
                  onClick={() => handleNavClick("/support")}
                >
                  Resources & Support
                </NavDropdown.Item>
              </NavDropdown>

              {userInfo ? (
                <NavDropdown
                  title={`${userInfo?.username}`}
                  id="offcanvasNavbarDropdown-expand-md"
                  className="custom-dropdown"
                  style={{
                    marginRight: "5px",
                    marginBotton: "3px",
                  }}
                >
                  <NavDropdown.Item
                    className="my-1"
                    onClick={() => handleNavClick("/profile")}
                  >
                    View Profile
                  </NavDropdown.Item>
                  {!loading && isAdmin && (
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/admin/info")}
                    >
                      Admin Panel
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <>
                  <NavItem
                    className="my-2"
                    style={{ fontWeight: "bold" }}
                    onClick={() => handleNavClick("/login")}
                  >
                    Login
                  </NavItem>
                  {showNav && (
                    <NavItem
                      className="my-2"
                      onClick={() => handleNavClick("/register")}
                    >
                      Register
                    </NavItem>
                  )}
                </>
              )}
              <span style={{ margin: "5px" }}></span>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Header;
