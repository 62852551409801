import React from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";

const PodiumForF1 = () => {
  return (
    <Container>
      <Row>
        <div>
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>
            Podium for F1 Sim Racing
          </h2>
          <h5>Elevate Your Racing Expereince</h5>
          <p>
            Discover <b>Podium</b>, the future of F1 sim racing. Our platform
            transforms your racing with intuitive tools and advanced features
            for:
          </p>
          <ul>
            <li>
              <b>Drivers:</b> Sharpen your skills and strategy.
            </li>
            <li>
              <b>Engineers:</b> Dive deep into data analysis.
            </li>
            <li>
              <b>League Managers:</b> Streamline event organization.
            </li>
            <li>
              <b>Streamers:</b> Enjoy enhanced broadcasting experiences.
            </li>
          </ul>
          <p>
            Explore how Podium can empower every aspect of your F1 sim racing
            journey.
          </p>
          <NavLink to="/about/screenshots" style={{ marginLeft: "15px" }}>
            View Podium Screenshots
          </NavLink>
          <br />
          <br />
          <br />
          <h5>Available Tiers</h5>
          <p>Choose your path in Podium:</p>
          <ul>
            <li>
              <b>Free:</b> Jumpstart your racing journey with essential tools.
            </li>
            <li>
              <b>Premium:</b> Elevate your experience with advanced features.
            </li>
            <li>
              <b>Esports:</b> Compete at the highest level with bespoke
              solutions.
            </li>
          </ul>
          <NavLink to="/about/premium-subs" style={{ marginLeft: "15px" }}>
            Explore Tiers and Features
          </NavLink>
          <br />
          <br />
          <br />
          <h5>Beta Access</h5>
          <p>Be part of our journey:</p>
          <p>
            Podium is in active development. Early access users can explore many
            features, with more on the way.
          </p>
          <NavLink to="/login" style={{ marginLeft: "15px" }}>
            Login to Sign Up for Beta
          </NavLink>
          <br />
          <br />
          <p className="important">
            Expect frequent updates and occassional downtime as we continue to
            develop and add new features.
          </p>
        </div>
      </Row>
    </Container>
  );
};

export default PodiumForF1;
