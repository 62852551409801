import {
  BETA_APPLY_REQUEST,
  BETA_APPLY_SUCCESS,
  BETA_APPLY_FAIL,
  BETA_STATUS_REQUEST,
  BETA_STATUS_SUCCESS,
  BETA_STATUS_FAIL,
  BETA_REVIEW_REQUEST,
  BETA_REVIEW_SUCCESS,
  BETA_REVIEW_FAIL,
  BETA_LIST_REQUEST,
  BETA_LIST_SUCCESS,
  BETA_LIST_FAIL,
  BETA_DELETE_REQUEST,
  BETA_DELETE_SUCCESS,
  BETA_DELETE_FAIL,
} from "../constants/betaConstants";

export const betaApplyReducer = (state = {}, action) => {
  switch (action.type) {
    case BETA_APPLY_REQUEST:
      return { loading: true };
    case BETA_APPLY_SUCCESS:
      return { loading: false, success: true };
    case BETA_APPLY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const betaStatusReducer = (state = { status: "" }, action) => {
  switch (action.type) {
    case BETA_STATUS_REQUEST:
      return { ...state, loading: true };
    case BETA_STATUS_SUCCESS:
      return { loading: false, status: action.payload };
    case BETA_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const betaReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case BETA_REVIEW_REQUEST:
      return { loading: true };
    case BETA_REVIEW_SUCCESS:
      return { loading: false, success: true, reviewInfo: action.payload };
    case BETA_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const betaListReducer = (state = { applications: [] }, action) => {
  switch (action.type) {
    case BETA_LIST_REQUEST:
      return { loading: true };
    case BETA_LIST_SUCCESS:
      return { loading: false, applications: action.payload };
    case BETA_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const betaDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BETA_DELETE_REQUEST:
      return { loading: true };
    case BETA_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BETA_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
