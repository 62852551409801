export const BETA_APPLY_REQUEST = "BETA_APPLY_REQUEST";
export const BETA_APPLY_SUCCESS = "BETA_APPLY_SUCCESS";
export const BETA_APPLY_FAIL = "BETA_APPLY_FAIL";

export const BETA_STATUS_REQUEST = "BETA_STATUS_REQUEST";
export const BETA_STATUS_SUCCESS = "BETA_STATUS_SUCCESS";
export const BETA_STATUS_FAIL = "BETA_STATUS_FAIL";

export const BETA_REVIEW_REQUEST = "BETA_REVIEW_REQUEST";
export const BETA_REVIEW_SUCCESS = "BETA_REVIEW_SUCCESS";
export const BETA_REVIEW_FAIL = "BETA_REVIEW_FAIL";

export const BETA_LIST_REQUEST = "BETA_LIST_REQUEST";
export const BETA_LIST_SUCCESS = "BETA_LIST_SUCCESS";
export const BETA_LIST_FAIL = "BETA_LIST_FAIL";

export const BETA_DELETE_REQUEST = "BETA_DELETE_REQUEST";
export const BETA_DELETE_SUCCESS = "BETA_DELETE_SUCCESS";
export const BETA_DELETE_FAIL = "BETA_DELETE_FAIL";
