import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REFRESH_REQUEST,
  USER_REFRESH_SUCCESS,
  USER_REFRESH_FAIL,
  CLEAR_LOGIN_ATTEMPT_REQUEST,
  CLEAR_LOGIN_ATTEMPT_SUCCESS,
  CLEAR_LOGIN_ATTEMPT_FAIL,
  VERIFY_PASSWORD_REQUEST,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_FAIL,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_RESET,
  USER_SEARCH_REQUEST,
  USER_SEARCH_SUCCESS,
  USER_SEARCH_FAIL,
  USER_SEARCH_RESET,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_DRIVER_DETAILS_REQUEST,
  USER_DRIVER_DETAILS_SUCCESS,
  USER_DRIVER_DETAILS_FAIL,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_TWOFA_REQUIRED,
  USER_TWOFA_VERIFY_REQUEST,
  USER_TWOFA_VERIFY_FAIL,
  USER_TWOFA_VERIFY_SUCCESS,
  USER_TWOFA_QRCODE_REQUEST,
  USER_TWOFA_QRCODE_SUCCESS,
  USER_TWOFA_QRCODE_FAIL,
  USER_TWOFA_STATUS_REQUEST,
  USER_TWOFA_STATUS_SUCCESS,
  USER_TWOFA_STATUS_FAIL,
  USER_TWOFA_CONFIRM_REQUEST,
  USER_TWOFA_CONFIRM_SUCCESS,
  USER_TWOFA_CONFIRM_FAILED,
  USER_TWOFA_CONFIRM_FAIL,
  USER_ENABLE_2FA_REQUEST,
  USER_ENABLE_2FA_SUCCESS,
  USER_ENABLE_2FA_FAIL,
  USER_DISABLE_2FA_REQUEST,
  USER_DISABLE_2FA_SUCCESS,
  USER_DISABLE_2FA_FAIL,
} from "../constants/userConstants";

// Combined reducer managing the state for user login and 2FA verification.
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { ...state, loading: true };
    case USER_LOGIN_SUCCESS:
      return { ...state, loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload };
    case USER_REFRESH_REQUEST:
      return { ...state, loading: true };
    case USER_REFRESH_SUCCESS:
      return { ...state, loading: false, userInfo: action.payload };
    case USER_REFRESH_FAIL:
      return { ...state, loading: false, error: action.payload };
    case USER_TWOFA_REQUIRED: // State update when 2FA is required
      return {
        ...state,
        loading: false,
        twoFARequired: true,
        tempUserId: action.payload.tempUserId,
      };
    case USER_TWOFA_VERIFY_REQUEST:
      return { ...state, loading: true };
    case USER_TWOFA_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        twoFARequired: false,
        userInfo: action.payload,
      };
    case USER_TWOFA_VERIFY_FAIL:
      return { ...state, loading: false, error: action.payload };
    case USER_LOGOUT:
      return {}; // might want to consider a more detailed state reset
    default:
      return state;
  }
};

export const verifyPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_PASSWORD_REQUEST:
      return { loading: true };
    case VERIFY_PASSWORD_SUCCESS:
      return { loading: false, success: true, isValid: action.payload };
    case VERIFY_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const clearLoginAttemptReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_LOGIN_ATTEMPT_REQUEST:
      return { loading: true };
    case CLEAR_LOGIN_ATTEMPT_SUCCESS:
      return { loading: false, success: true, message: action.payload.message };
    case CLEAR_LOGIN_ATTEMPT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userSearchReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_SEARCH_REQUEST:
      return { loading: true, users: [] };
    case USER_SEARCH_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    case USER_SEARCH_RESET:
      return {};
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };
    default:
      return state;
  }
};

export const userDriverDetailsReducer = (
  state = { userDriver: {} },
  action
) => {
  switch (action.type) {
    case USER_DRIVER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DRIVER_DETAILS_SUCCESS:
      return { loading: false, userDriver: action.payload };
    case USER_DRIVER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userPasswordResetReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_SUCCESS:
      return { loading: false, success: true };
    case USER_PASSWORD_RESET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST:
      return { loading: true };
    case USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userTwoFAQrCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_TWOFA_QRCODE_REQUEST:
      return { loading: true };
    case USER_TWOFA_QRCODE_SUCCESS:
      return { loading: false, qrCodeImageUrl: action.payload };
    case USER_TWOFA_QRCODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const twoFAStatusReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_TWOFA_STATUS_REQUEST:
      return { loading: true };
    case USER_TWOFA_STATUS_SUCCESS:
      return { loading: false, statusInfo: action.payload };
    case USER_TWOFA_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userTwoFAConfirmReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_TWOFA_CONFIRM_REQUEST:
      return { loading: true };
    case USER_TWOFA_CONFIRM_SUCCESS:
      // Assuming the payload is now a boolean indicating whether the token is valid
      return { loading: false, success: true, confirmToken: action.payload };
    case USER_TWOFA_CONFIRM_FAILED:
      // Assuming the payload is now a boolean indicating whether the token is valid
      return { loading: false, success: false, confirmToken: action.payload };
    case USER_TWOFA_CONFIRM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userTwoFAToggleReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ENABLE_2FA_REQUEST:
    case USER_DISABLE_2FA_REQUEST:
      return { loading: true };
    case USER_ENABLE_2FA_SUCCESS:
    case USER_DISABLE_2FA_SUCCESS:
      return { loading: false, message: action.payload };
    case USER_ENABLE_2FA_FAIL:
    case USER_DISABLE_2FA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
