import React from "react";
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import PodiumTheoryLogoImg from "../../Images/podium_logos/Podium-the-cat-inverse.svg";

const LogoImg = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-right: 10px;
  width: 44px;
  height: 44px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;

export function Logo(props) {
  return (
    <LinkContainer to="/">
      <LogoImg>
        <img src={PodiumTheoryLogoImg} alt="Podium Theory" />
      </LogoImg>
    </LinkContainer>
  );
}
