export const FRIEND_REQUEST_SEND_REQUEST = "FRIEND_REQUEST_SEND_REQUEST";
export const FRIEND_REQUEST_SEND_SUCCESS = "FRIEND_REQUEST_SEND_SUCCESS";
export const FRIEND_REQUEST_SEND_FAIL = "FRIEND_REQUEST_SEND_FAIL";

export const FRIEND_REQUEST_ACCEPT_REQUEST = "FRIEND_REQUEST_ACCEPT_REQUEST";
export const FRIEND_REQUEST_ACCEPT_SUCCESS = "FRIEND_REQUEST_ACCEPT_SUCCESS";
export const FRIEND_REQUEST_ACCEPT_FAIL = "FRIEND_REQUEST_ACCEPT_FAIL";

export const FRIEND_REQUEST_DENY_REQUEST = "FRIEND_REQUEST_DENY_REQUEST";
export const FRIEND_REQUEST_DENY_SUCCESS = "FRIEND_REQUEST_DENY_SUCCESS";
export const FRIEND_REQUEST_DENY_FAIL = "FRIEND_REQUEST_DENY_FAIL";

export const FRIEND_REQUESTS_OUTGOING_REQUEST =
  "FRIEND_REQUESTS_OUTGOING_REQUEST";
export const FRIEND_REQUESTS_OUTGOING_SUCCESS =
  "FRIEND_REQUESTS_OUTGOING_SUCCESS";
export const FRIEND_REQUESTS_OUTGOING_FAIL = "FRIEND_REQUESTS_OUTGOING_FAIL";

export const FRIEND_REQUEST_RECEIVED_REQUEST =
  "FRIEND_REQUEST_RECEIVED_REQUEST";
export const FRIEND_REQUEST_RECEIVED_SUCCESS =
  "FRIEND_REQUEST_RECEIVED_SUCCESS";
export const FRIEND_REQUEST_RECEIVED_FAIL = "FRIEND_REQUEST_RECEIVED_FAIL";

export const FRIENDS_REQUEST = "FRIENDS_REQUEST";
export const FRIENDS_SUCCESS = "FRIENDS_SUCCESS";
export const FRIENDS_FAIL = "FRIENDS_FAIL";

export const LOGGED_IN_USER_FRIENDS_REQUEST = "LOGGED_IN_USER_FRIENDS_REQUEST";
export const LOGGED_IN_USER_FRIENDS_SUCCESS = "LOGGED_IN_USER_FRIENDS_SUCCESS";
export const LOGGED_IN_USER_FRIENDS_FAIL = "LOGGED_IN_USER_FRIENDS_FAIL";

export const PROFILE_USER_FRIENDS_REQUEST = "PROFILE_USER_FRIENDS_REQUEST";
export const PROFILE_USER_FRIENDS_SUCCESS = "PROFILE_USER_FRIENDS_SUCCESS";
export const PROFILE_USER_FRIENDS_FAIL = "PROFILE_USER_FRIENDS_FAIL";

export const FRIEND_NOTES_REQUEST = "FRIEND_NOTES_REQUEST";
export const FRIEND_NOTES_SUCCESS = "FRIEND_NOTES_SUCCESS";
export const FRIEND_NOTES_FAIL = "FRIEND_NOTES_FAIL";
export const FRIEND_NOTES_RESET = "FRIEND_NOTES_RESET";

export const UPDATE_FRIEND_NOTES_REQUEST = "UPDATE_FRIEND_NOTES_REQUEST";
export const UPDATE_FRIEND_NOTES_SUCCESS = "UPDATE_FRIEND_NOTES_SUCCESS";
export const UPDATE_FRIEND_NOTES_FAIL = "UPDATE_FRIEND_NOTES_FAIL";
export const UPDATE_FRIEND_NOTES_RESET = "UPDATE_FRIEND_NOTES_RESET";

export const FRIEND_REQUEST_RESET = "FRIEND_REQUEST_RESET";

export const CHECK_FRIEND_REQUEST_SENT_REQUEST =
  "CHECK_FRIEND_REQUEST_SENT_REQUEST";
export const CHECK_FRIEND_REQUEST_SENT_SUCCESS =
  "CHECK_FRIEND_REQUEST_SENT_SUCCESS";
export const CHECK_FRIEND_REQUEST_SENT_FAIL = "CHECK_FRIEND_REQUEST_SENT_FAIL";

export const CHECK_FRIEND_REQUEST_RECEIVED_REQUEST =
  "CHECK_FRIEND_REQUEST_RECEIVED_REQUEST";
export const CHECK_FRIEND_REQUEST_RECEIVED_SUCCESS =
  "CHECK_FRIEND_REQUEST_RECEIVED_SUCCESS";
export const CHECK_FRIEND_REQUEST_RECEIVED_FAIL =
  "CHECK_FRIEND_REQUEST_RECEIVED_FAIL";

export const FRIENDSHIP_ID_REQUEST = "FRIENDSHIP_ID_REQUEST";
export const FRIENDSHIP_ID_SUCCESS = "FRIENDSHIP_ID_SUCCESS";
export const FRIENDSHIP_ID_FAIL = "FRIENDSHIP_ID_FAIL";
