import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REFRESH_REQUEST,
  USER_REFRESH_SUCCESS,
  USER_REFRESH_FAIL,
  VERIFY_PASSWORD_REQUEST,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_FAIL,
  CLEAR_LOGIN_ATTEMPT_REQUEST,
  CLEAR_LOGIN_ATTEMPT_SUCCESS,
  CLEAR_LOGIN_ATTEMPT_FAIL,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_RESET,
  USER_SEARCH_REQUEST,
  USER_SEARCH_SUCCESS,
  USER_SEARCH_FAIL,
  USER_SEARCH_RESET,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_DRIVER_DETAILS_REQUEST,
  USER_DRIVER_DETAILS_SUCCESS,
  USER_DRIVER_DETAILS_FAIL,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_TWOFA_REQUIRED,
  USER_TWOFA_VERIFY_REQUEST,
  USER_TWOFA_VERIFY_FAIL,
  USER_TWOFA_VERIFY_SUCCESS,
  USER_TWOFA_QRCODE_REQUEST,
  USER_TWOFA_QRCODE_SUCCESS,
  USER_TWOFA_QRCODE_FAIL,
  USER_TWOFA_STATUS_REQUEST,
  USER_TWOFA_STATUS_SUCCESS,
  USER_TWOFA_STATUS_FAIL,
  USER_TWOFA_CONFIRM_REQUEST,
  USER_TWOFA_CONFIRM_SUCCESS,
  USER_TWOFA_CONFIRM_FAILED,
  USER_TWOFA_CONFIRM_FAIL,
  USER_ENABLE_2FA_REQUEST,
  USER_ENABLE_2FA_SUCCESS,
  USER_ENABLE_2FA_FAIL,
  USER_DISABLE_2FA_REQUEST,
  USER_DISABLE_2FA_SUCCESS,
  USER_DISABLE_2FA_FAIL,
} from "../constants/userConstants";
import axios from "axios";
import { API_BASE_URL } from "../config";
//const API_BASE_URL = "https://dzp-api.onrender.com/api";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // First, attempt to log the user in
    const { data } = await axios.post(
      `${API_BASE_URL}/users/login/email`,
      { email, password },
      config
    );

    if (data.twoFARequired) {
      // If 2FA is required, we don't want to complete the login process yet.
      // Dispatch an action indicating that 2FA verification is needed.
      dispatch({
        type: USER_TWOFA_REQUIRED,
        payload: { tempUserId: data.tempUserId }, // Save the tempUserId for the 2FA step
      });
    } else {
      // If 2FA isn't required, the user is logged in, and we can save their info.
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const refreshUserInfo = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_REFRESH_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`, // Use the existing token from login state
      },
    };

    const { data } = await axios.get(
      `${API_BASE_URL}/users/verify-profile`,
      config
    ); // Assuming this is your route to get user info

    dispatch({ type: USER_REFRESH_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data)); // Update the local storage as well
  } catch (error) {
    dispatch({
      type: USER_REFRESH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const verifyTwoFA = (twoFAToken, tempUserId) => async (dispatch) => {
  try {
    dispatch({ type: USER_TWOFA_VERIFY_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Send the 2FA token and the tempUserId to the server for verification
    const { data } = await axios.post(
      `${API_BASE_URL}/users/login/2fa`,
      { token: twoFAToken, tempUserId },
      config
    );

    // If verification is successful, the user is now considered logged in.
    // Here we're dispatching a specific action for successful 2FA verification.
    dispatch({ type: USER_TWOFA_VERIFY_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_TWOFA_VERIFY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const verifyPassword = (password) => async (dispatch, getState) => {
  try {
    dispatch({ type: VERIFY_PASSWORD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${API_BASE_URL}/users/verify-password`,
      { password },
      config
    );

    dispatch({
      type: VERIFY_PASSWORD_SUCCESS,
      payload: data.isValid,
    });
    console.log("Password is correct");

    return data.isValid;
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: VERIFY_PASSWORD_FAIL,
      payload: errorMessage,
    });
    console.log("Password is incorrect");

    return Promise.reject(errorMessage);
  }
};

export const clearLoginAttempt = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_LOGIN_ATTEMPT_REQUEST });

    const { data } = await axios.post(
      `${API_BASE_URL}/users/login/clear-attempt`
    );

    dispatch({
      type: CLEAR_LOGIN_ATTEMPT_SUCCESS,
      payload: data,
    });

    dispatch({ type: USER_LOGOUT });
  } catch (error) {
    dispatch({
      type: CLEAR_LOGIN_ATTEMPT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("twoFAStatus");
  dispatch({ type: USER_LOGOUT });
};

export const refreshToken = (userInfo) => async (dispatch) => {
  try {
    if (!userInfo?.token) {
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      throw new Error("No token found");
    }

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${API_BASE_URL}/users/refresh`, config);

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    localStorage.removeItem("userInfo");
    dispatch({ type: USER_LOGOUT });
  }
};

export const register =
  (username, email, password, pic) => async (dispatch) => {
    try {
      dispatch({ type: USER_REGISTER_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_BASE_URL}/users`,
        { username, pic, email, password },
        config
      );

      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });

      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${API_BASE_URL}/users/profile`,
      user,
      config
    );

    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchUsers = (username) => async (dispatch) => {
  try {
    dispatch({ type: USER_SEARCH_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${API_BASE_URL}/users/search/${username}`,
      config
    );

    dispatch({ type: USER_SEARCH_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_SEARCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${API_BASE_URL}/users/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserDriverDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DRIVER_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_BASE_URL}/users/driver/${id}`,
      config
    );

    dispatch({
      type: USER_DRIVER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DRIVER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetUserDetails = () => (dispatch) => {
  dispatch({ type: USER_DETAILS_RESET });
};

export const resetUserSearch = () => (dispatch) => {
  dispatch({ type: USER_SEARCH_RESET });
};

export const initiatePasswordReset = (email) => async (dispatch) => {
  try {
    dispatch({ type: USER_PASSWORD_RESET_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post(
      `${API_BASE_URL}/users/request-password-reset`,
      { email },
      config
    );

    dispatch({
      type: USER_PASSWORD_RESET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_RESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetUserPassword = (token, newPassword) => async (dispatch) => {
  try {
    dispatch({ type: USER_RESET_PASSWORD_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Send the token and new password to your backend
    const { data } = await axios.post(
      `${API_BASE_URL}/users/reset-password`,
      { token, newPassword },
      config
    );

    dispatch({
      type: USER_RESET_PASSWORD_SUCCESS,
      payload: data, // if your backend returns some data upon success
    });
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const generateTwoFAQrCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_TWOFA_QRCODE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_BASE_URL}/users/2fa/generate`,
      config
    );

    dispatch({
      type: USER_TWOFA_QRCODE_SUCCESS,
      payload: data.qrCodeImageUrl,
    });
  } catch (error) {
    dispatch({
      type: USER_TWOFA_QRCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const checkTwoFAStatus = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_TWOFA_STATUS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_BASE_URL}/users/2fa/status`,
      config
    );

    dispatch({
      type: USER_TWOFA_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_TWOFA_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const confirmTwoFA = (token, userId) => async (dispatch) => {
  try {
    dispatch({
      type: USER_TWOFA_CONFIRM_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`${API_BASE_URL}/users/2fa/verify`, {
      params: { token, userId },
      ...config,
    });

    // Check the content of the response
    if (data.tokenIsValid) {
      dispatch({
        type: USER_TWOFA_CONFIRM_SUCCESS,
        payload: data,
      });
    } else {
      // If the token is not valid, dispatch the failure action instead
      dispatch({
        type: USER_TWOFA_CONFIRM_FAILED,
        payload: data,
      });
    }
  } catch (error) {
    // If an actual error occurs (like a network error or a non-2xx status code),
    // the catch block will handle it
    dispatch({
      type: USER_TWOFA_CONFIRM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data
          : error.message,
    });
  }
};

export const enableTwoFA = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_ENABLE_2FA_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState(); // get user info from state

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`, // assuming token is stored here
      },
    };

    const { data } = await axios.post(
      `${API_BASE_URL}/users/2fa/enable`,
      {},
      config
    );

    dispatch({ type: USER_ENABLE_2FA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_ENABLE_2FA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const disableTwoFA = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DISABLE_2FA_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState(); // get user info from state

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`, // assuming token is stored here
      },
    };

    const { data } = await axios.post(
      `${API_BASE_URL}/users/2fa/disable`,
      {},
      config
    );

    dispatch({ type: USER_DISABLE_2FA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_DISABLE_2FA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetUpdateProfile = () => (dispatch) => {
  dispatch({ type: USER_UPDATE_RESET });
};
