import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_BASE_URL } from "../config";

const useAuth = () => {
  const [userAccess, setUserAccess] = useState({
    isAdmin: false,
    isDeltaZero: false,
    isShadow: false,
    isBeta: false,
    isPremium: false,
  });
  const [loading, setLoading] = useState(true);

  const { userInfo } = useSelector((state) => state.userLogin);

  const fetchUserAccess = useCallback(async () => {
    if (!userInfo?.token) return;

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${API_BASE_URL}/users/verify-profile`,
        config
      );

      const newUserAccess = {
        isAdmin: data.isAdmin,
        isDeltaZero: data.access === "deltazero",
        isShadow: ["shadow", "deltazero"].includes(data.access),
        isBeta: ["beta", "deltazero"].includes(data.access),
        isPremium: ["premium", "deltazero"].includes(data.access),
      };

      setUserAccess(newUserAccess);
    } catch (error) {
      console.error("Failed to fetch user access", error);
    } finally {
      setLoading(false);
    }
  }, [userInfo?.token]);

  useEffect(() => {
    setLoading(true);
    fetchUserAccess();
  }, [fetchUserAccess]);

  return { userAccess, loading };
};

export default useAuth;
