import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import MainScreen from "../../../Components/MainScreen";
import { Col, Card, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Grid1 from "../../../Images/setup_archiver/sa_1_1.png";
import Grid2 from "../../../Images/setup_archiver/sa_1_2.png";
import Grid3 from "../../../Images/setup_archiver/sa_2_1.png";
import Grid4 from "../../../Images/setup_archiver/sa_2_2.png";

import "./SetupArchiver.scss";
import gStyle from "../../../SCSS/global.module.scss";

const SetupArchiverHome = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // check user status
  const isLoggedIn = !!userInfo;
  const hasPremiumAccess = !!userInfo?.isAdmin; // add premium access check here
  const isAvailable =
    userInfo && (userInfo.isAdmin || userInfo.access !== "none"); // only true if user has any access other than 'none'

  return (
    <Fragment>
      <MainScreen
        title="Setup Archiver"
        className={`setupArchiverBG ${gStyle.trackImageBG}`}
      >
        <Card className="mb-1 mt-1">
          <Card.Body>
            <Row>
              <Col md={6}>
                <NavLink
                  to={isAvailable ? "/setup-archiver/search-setups" : "#"}
                  className="grid-item"
                  style={{ backgroundImage: `url(${Grid1})` }} // Set the background image
                >
                  <div className="grid-text">
                    <span className="default-text">Search Setups</span>{" "}
                    {/* Default text */}
                    {!isAvailable && (
                      <span className="unavailable">Coming Soon</span>
                    )}
                    {isAvailable && (
                      <span className="full-access">Search Setups</span>
                    )}
                  </div>
                </NavLink>
              </Col>
              <Col md={6}>
                <NavLink
                  to={
                    isAvailable
                      ? isLoggedIn
                        ? "/setup-archiver/create-setups"
                        : "/login"
                      : "#"
                  }
                  className="grid-item"
                  style={{ backgroundImage: `url(${Grid2})` }} // Set the background image
                >
                  <div className="grid-text">
                    <span className="default-text">Create Setups</span>{" "}
                    {/* Default text */}
                    {!isLoggedIn && isAvailable && (
                      <span className="login-required">Login to Use</span>
                    )}
                    {!isAvailable && (
                      <span className="unavailable">Coming Soon</span>
                    )}
                    {isLoggedIn && isAvailable && (
                      <span className="full-access">Create Setups</span>
                    )}
                  </div>
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <NavLink
                  to={
                    isAvailable
                      ? isLoggedIn
                        ? "/setup-archiver/your-setups"
                        : "/login"
                      : "#"
                  }
                  className="grid-item"
                  style={{ backgroundImage: `url(${Grid3})` }} // Set the background image
                >
                  <div className="grid-text">
                    <span className="default-text">Your Setups</span>{" "}
                    {/* Default text */}
                    {!isLoggedIn && isAvailable && (
                      <span className="login-required">Login to Use</span>
                    )}
                    {!isAvailable && (
                      <span className="unavailable">Coming Soon</span>
                    )}
                    {isLoggedIn && isAvailable && (
                      <span className="full-access">Your Setups</span>
                    )}
                  </div>
                </NavLink>
              </Col>
              <Col md={6}>
                <NavLink
                  to={
                    isAvailable
                      ? isLoggedIn
                        ? hasPremiumAccess
                          ? "/setup-archiver/esports-setups"
                          : "/about/premium-subs"
                        : "/login"
                      : "#"
                  }
                  className="grid-item"
                  style={{ backgroundImage: `url(${Grid4})` }} // Set the background image
                >
                  <div className="grid-text">
                    <span className="default-text">Esports Setups</span>{" "}
                    {/* Default text */}
                    {!isAvailable && (
                      <span className="unavailable">Coming Soon</span>
                    )}
                    {!isLoggedIn && isAvailable && (
                      <span className="login-required">Login to Use</span>
                    )}
                    {isLoggedIn && !hasPremiumAccess && isAvailable && (
                      <span className="premium-only">Premium Only</span>
                    )}
                    {isLoggedIn && hasPremiumAccess && isAvailable && (
                      <span className="full-access">Esports Setups</span>
                    )}
                  </div>
                </NavLink>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <div className="spacing-bottom" />
      </MainScreen>
    </Fragment>
  );
};

export default SetupArchiverHome;
