import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function requireAuth(WrappedComponent) {
  return (props) => {
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const navigate = useNavigate();

    useEffect(() => {
      if (!userInfo) {
        navigate("/login");
      }
    }, [userInfo, navigate]);

    return <WrappedComponent {...props} />;
  };
}
