import React, { useEffect } from "react";
import { Container, Row, Button, Table, ButtonGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  listAllBetaApplications,
  reviewBetaApplication,
  deleteBetaApplication,
} from "../../../actions/betaActions";
import Loading from "../../../Components/Loading";
import ErrorMessage from "../../../Components/ErrorMessage";
import Trash from "../../../Images/trash-white.svg";
import "../Admin.scss";
import gStyle from "../../../SCSS/global.module.scss";

const AdminBeta = () => {
  const dispatch = useDispatch();
  const betaList = useSelector((state) => state.betaList);
  const { loading, error, applications = [] } = betaList; // Ensure applications is an array

  const betaReview = useSelector((state) => state.betaReview);
  const {
    loading: reviewLoading,
    error: reviewError,
    success: reviewSuccess,
  } = betaReview;

  const betaDelete = useSelector((state) => state.betaDelete);
  const { success: deleteSuccess } = betaDelete;

  const handleDelete = (appId) => {
    if (window.confirm("Are you sure you want to delete this application?")) {
      dispatch(deleteBetaApplication(appId));
    }
  };

  useEffect(() => {
    dispatch(listAllBetaApplications());
  }, [dispatch, reviewSuccess, deleteSuccess]);

  const handleReview = (appId, newStatus) => {
    dispatch(reviewBetaApplication(appId, newStatus));
  };

  // Filter applications based on their status
  const pendingApplications = applications.filter(
    (app) => app.status === "pending"
  );
  const acceptedApplications = applications.filter(
    (app) => app.status === "accepted"
  );
  const deniedApplications = applications.filter(
    (app) => app.status === "denied"
  );

  // Function to render table rows
  const renderTableRows = (apps) =>
    apps.map((app) => (
      <tr key={app._id}>
        <td>
          <LinkContainer style={{ width: "100%" }} to={`/user/${app.user._id}`}>
            <Button>{app.user.username}</Button>
          </LinkContainer>
        </td>
        <td>{app.user.email}</td>
        <td>{app.status}</td>
        <td>{new Date(app.appliedAt).toLocaleDateString()}</td>
        <td>
          {app.reviewedAt
            ? new Date(app.reviewedAt).toLocaleDateString()
            : "N/A"}
        </td>
        <td>
          <ButtonGroup>
            {app.status === "pending" && (
              <>
                <Button
                  variant="success"
                  onClick={() => handleReview(app._id, "accepted")}
                >
                  Accept
                </Button>
                <Button
                  variant="warning"
                  onClick={() => handleReview(app._id, "denied")}
                >
                  Deny
                </Button>
              </>
            )}
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleDelete(app._id)}
            >
              <img
                src={Trash}
                alt="Trash"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    ));

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorMessage variant="danger">{error}</ErrorMessage>
      ) : (
        <>
          <Row className="justify-content-center mb-3">
            <h2>Pending Applications</h2>
            <Table striped bordered hover responsive className="mt-3">
              <thead className="bg-black">
                <tr>
                  <th>User</th>
                  <th>User Email</th>
                  <th>Status</th>
                  <th>Applied At</th>
                  <th>Reviewed At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{renderTableRows(pendingApplications)}</tbody>
            </Table>
          </Row>
          <Row className="justify-content-center mb-3">
            <h2>Denied Applications</h2>
            <Table striped bordered hover responsive className="mt-3">
              <thead className="bg-danger">
                <tr>
                  <th>User</th>
                  <th>User Email</th>
                  <th>Status</th>
                  <th>Applied At</th>
                  <th>Reviewed At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{renderTableRows(deniedApplications)}</tbody>
            </Table>
            <div className={`${gStyle.red} ${gStyle.redTs}`}>
              Denied Applicants can not reapply until you delete their
              application
            </div>
          </Row>
          <Row className="justify-content-center mb-3">
            <h2>Accepted Applications</h2>
            <Table striped bordered hover responsive className="mt-3">
              <thead className="bg-success">
                <tr>
                  <th>User</th>
                  <th>User Email</th>
                  <th>Status</th>
                  <th>Applied At</th>
                  <th>Reviewed At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{renderTableRows(acceptedApplications)}</tbody>
            </Table>
            <div className={`${gStyle.red} ${gStyle.redTs}`}>
              Deleting an accepted application will not remove beta access from
              the user
            </div>
          </Row>
        </>
      )}
      {reviewLoading && <Loading />}
      {reviewError && (
        <ErrorMessage variant="danger">{reviewError}</ErrorMessage>
      )}
    </Container>
  );
};

export default AdminBeta;
