import React from "react";
import { Col } from "react-bootstrap";
import "./DriverSocialNav.scss";
import { FaYoutube, FaTwitch, FaTwitter } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";

const DriverSocialNav = ({ web, youtube, twitch, twitter }) => {
  const links = [
    { href: web, logo: TbWorld, alt: "Web", isReactIcon: true },
    { href: youtube, logo: FaYoutube, alt: "Youtube", isReactIcon: true },
    { href: twitch, logo: FaTwitch, alt: "Twitch", isReactIcon: true },
    { href: twitter, logo: FaTwitter, alt: "Twitter", isReactIcon: true },
  ].filter((link) => link.href && link.href.length >= 4);

  return (
    <Col
      className="my-1 d-flex justify-content-center"
      style={{
        paddingTop: "10px",
      }}
    >
      {links.map(({ href, logo, alt, isReactIcon }, index) => (
        <a
          key={index}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="logo-link mx-3"
        >
          {isReactIcon ? (
            React.createElement(logo, { className: "logo-img", size: "20px" })
          ) : (
            <img src={logo} alt={alt} height="20" className="logo-img" />
          )}
        </a>
      ))}
    </Col>
  );
};

export default DriverSocialNav;
