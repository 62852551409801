export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REFRESH_REQUEST = "USER_REFRESH_REQUEST";
export const USER_REFRESH_SUCCESS = "USER_REFRESH_SUCCESS";
export const USER_REFRESH_FAIL = "USER_REFRESH_FAIL";

export const VERIFY_PASSWORD_REQUEST = "VERIFY_PASSWORD_REQUEST";
export const VERIFY_PASSWORD_SUCCESS = "VERIFY_PASSWORD_SUCCESS";
export const VERIFY_PASSWORD_FAIL = "VERIFY_PASSWORD_FAIL";

export const CLEAR_LOGIN_ATTEMPT_REQUEST = "CLEAR_LOGIN_ATTEMPT_REQUEST";
export const CLEAR_LOGIN_ATTEMPT_SUCCESS = "CLEAR_LOGIN_ATTEMPT_SUCCESS";
export const CLEAR_LOGIN_ATTEMPT_FAIL = "CLEAR_LOGIN_ATTEMPT_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_SEARCH_REQUEST = "USER_SEARCH_REQUEST";
export const USER_SEARCH_SUCCESS = "USER_SEARCH_SUCCESS";
export const USER_SEARCH_FAIL = "USER_SEARCH_FAIL";
export const USER_SEARCH_RESET = "USER_SEARCH_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_DRIVER_DETAILS_REQUEST = "USER_DRIVER_DETAILS_REQUEST";
export const USER_DRIVER_DETAILS_SUCCESS = "USER_DRIVER_DETAILS_SUCCESS";
export const USER_DRIVER_DETAILS_FAIL = "USER_DRIVER_DETAILS_FAIL";

export const USER_PASSWORD_RESET_REQUEST = "USER_PASSWORD_RESET_REQUEST";
export const USER_PASSWORD_RESET_SUCCESS = "USER_PASSWORD_RESET_SUCCESS";
export const USER_PASSWORD_RESET_FAIL = "USER_PASSWORD_RESET_FAIL";

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";

export const USER_TWOFA_REQUIRED = "USER_TWOFA_REQUIRED";
export const USER_TWOFA_VERIFY_REQUEST = "USER_TWOFA_VERIFY_REQUEST";
export const USER_TWOFA_VERIFY_FAIL = "USER_TWOFA_VERIFY_FAIL";
export const USER_TWOFA_VERIFY_SUCCESS = "USER_TWOFA_VERIFY_SUCCESS";

export const USER_TWOFA_QRCODE_REQUEST = "USER_TWOFA_QRCODE_REQUEST";
export const USER_TWOFA_QRCODE_SUCCESS = "USER_TWOFA_QRCODE_SUCCESS";
export const USER_TWOFA_QRCODE_FAIL = "USER_TWOFA_QRCODE_FAIL";

export const USER_TWOFA_STATUS_REQUEST = "USER_TWOFA_STATUS_REQUEST";
export const USER_TWOFA_STATUS_SUCCESS = "USER_TWOFA_STATUS_SUCCESS";
export const USER_TWOFA_STATUS_FAIL = "USER_TWOFA_STATUS_FAIL";

export const USER_TWOFA_CONFIRM_REQUEST = "USER_TWOFA_CONFIRM_REQUEST";
export const USER_TWOFA_CONFIRM_SUCCESS = "USER_TWOFA_CONFIRM_SUCCESS";
export const USER_TWOFA_CONFIRM_FAILED = "USER_TWOFA_CONFIRM_FAILED";
export const USER_TWOFA_CONFIRM_FAIL = "USER_TWOFA_CONFIRM_FAIL";

export const USER_ENABLE_2FA_REQUEST = "USER_ENABLE_2FA_REQUEST";
export const USER_ENABLE_2FA_SUCCESS = "USER_ENABLE_2FA_SUCCESS";
export const USER_ENABLE_2FA_FAIL = "USER_ENABLE_2FA_FAIL";

export const USER_DISABLE_2FA_REQUEST = "USER_DISABLE_2FA_REQUEST";
export const USER_DISABLE_2FA_SUCCESS = "USER_DISABLE_2FA_SUCCESS";
export const USER_DISABLE_2FA_FAIL = "USER_DISABLE_2FA_FAIL";
